import {
  SMARTER_SEARCH_CLUSTERS,
  type SmarterSearchCluster,
  type ZoneFeatures,
} from 'src/config/types';
import { isATargetDevice } from 'src/modules/app-install-banner';
import type {
  ChaliceStore,
  TypedAction,
  TypedThunkAction,
} from 'src/store/reducer';

import {
  toggleExperiment,
  type TSeekExperiments,
} from '../experiments/experimentHelpers';

const UPDATE_FEATURE_FLAGS = 'UPDATE_FEATURE_FLAGS';

export const updateFeatureFlags =
  (
    cookies: Record<string, string> = {},
    experiments: TSeekExperiments,
    {
      NORTHSTAR_SEO_TAGS,
      SHOW_FLOATING_SAVE_SEARCH,
      AUTO_SELECT_SPLIT_VIEW_FIRST_JOB,
      SHOW_MARKETING_AD_SPLIT_VIEW,
      BEHAVIOURAL_CUES_ENABLED,
      LOGGED_OUT_RECS,
      REMOTE_SEARCH_FILTER,
      REMOTE_SEARCH_FILTER_NEW_LABEL,
      DYNAMIC_PILLS,
      SECONDARY_FILTERS,
      ENABLE_V5_JOB_SEARCH,
      KEYWORD_AUTOSUGGEST_V2,
      NEW_JOB_CARD_DENSITY,
      ENABLE_VERIFIED_HIRER_BADGE,
      SERP_JOBCARD_INFO_DENSITY_1,
    }: ZoneFeatures,
  ): TypedThunkAction =>
  (dispatch) => {
    const secondaryFilters =
      Boolean(cookies.SECONDARY_FILTERS || SECONDARY_FILTERS) ||
      toggleExperiment({
        experimentId: 'secondaryFilters',
        experiments,
        cookieValue: cookies.SECONDARY_FILTERS,
      });

    // Extra safe guard to ensure that dynamic pills can only be enabled if secondary filters is enabled.
    // You cannot enable dynamic pills if secondary filters is disabled.
    const dynamicPills =
      secondaryFilters &&
      (Boolean(cookies.DYNAMIC_PILLS || DYNAMIC_PILLS) ||
        toggleExperiment({
          experimentId: 'dynamicPills',
          experiments,
          cookieValue: cookies.DYNAMIC_PILLS,
        }));

    dispatch({
      type: UPDATE_FEATURE_FLAGS,
      payload: {
        dynamicFlags: {
          behaviouralCues: Boolean(
            cookies.BEHAVIOURAL_CUES_ENABLED || BEHAVIOURAL_CUES_ENABLED,
          ),
          branchBannerPreview: Boolean(cookies.BRANCH_BANNER_PREVIEW),
          isBranchEnabledFlag:
            Boolean(cookies.ENABLE_BRANCH_BANNER) || isATargetDevice(),
          retireDisplayAds: cookies.RETIRE_DISPLAY_ADS,
          smarterSearchLaunchCluster: cookies.SMARTER_SEARCH_LAUNCH_CLUSTER,
          seoMetaTags: cookies.SEO_META_TAGS || Boolean(NORTHSTAR_SEO_TAGS),
          showFloatingSaveSearch: Boolean(
            cookies.SHOW_FLOATING_SAVE_SEARCH || SHOW_FLOATING_SAVE_SEARCH,
          ),
          autoSelectSplitViewFirstJob:
            Boolean(cookies.AUTO_SELECT_SPLIT_VIEW_FIRST_JOB) ||
            AUTO_SELECT_SPLIT_VIEW_FIRST_JOB,
          showMarketingAdSplitView: Boolean(
            cookies.SHOW_MARKETING_AD_SPLIT_VIEW ||
              SHOW_MARKETING_AD_SPLIT_VIEW,
          ),
          loggedOutRecs:
            Boolean(cookies.LOGGED_OUT_RECS || LOGGED_OUT_RECS) ||
            toggleExperiment({
              experimentId: 'loggedOutRecs',
              experiments,
              cookieValue: cookies.LOGGED_OUT_RECS,
            }),
          remoteSearchFilter:
            Boolean(cookies.REMOTE_SEARCH_FILTER || REMOTE_SEARCH_FILTER) ||
            toggleExperiment({
              experimentId: 'remoteSearchFilter',
              experiments,
              cookieValue: cookies.REMOTE_SEARCH_FILTER,
            }),
          remoteSearchFilterNewLabel: Boolean(REMOTE_SEARCH_FILTER_NEW_LABEL),
          dynamicPills,
          secondaryFilters,
          v5JobSearch: Boolean(
            cookies.ENABLE_V5_JOB_SEARCH || ENABLE_V5_JOB_SEARCH,
          ),
          keywordAutosuggestV2: Boolean(
            cookies.KEYWORD_AUTOSUGGEST_V2 || KEYWORD_AUTOSUGGEST_V2,
          ),
          hirerVerifiedBadge: Boolean(
            cookies.ENABLE_VERIFIED_HIRER_BADGE || ENABLE_VERIFIED_HIRER_BADGE,
          ),
          serpJobCardInfoDensity1: Boolean(
            cookies.SERP_JOBCARD_INFO_DENSITY_1 ||
              SERP_JOBCARD_INFO_DENSITY_1 ||
              toggleExperiment({
                experimentId: 'serpJobCardInfoDensity1',
                experiments,
                cookieValue: cookies.SERP_JOBCARD_INFO_DENSITY_1,
              }),
          ),
          newJobCardDensity: Boolean(
            cookies.NEW_JOB_CARD_DENSITY || NEW_JOB_CARD_DENSITY,
          ),
          hideCompanyLogo: Boolean(cookies.HIDE_COMPANY_LOGO),
        },
      },
    });
  };

export interface FeatureFlagsState {
  behaviouralCues: boolean;
  branchBannerPreview: boolean;
  isBranchEnabledFlag: boolean;
  retireDisplayAds: boolean;
  smarterSearchLaunchCluster: SmarterSearchCluster;
  seoMetaTags: boolean;
  showHomepageBanner: boolean;
  showFloatingSaveSearch: boolean;
  autoSelectSplitViewFirstJob: boolean;
  showMarketingAdSplitView: boolean;
  loggedOutRecs: boolean;
  remoteSearchFilter: boolean;
  remoteSearchFilterNewLabel: boolean;
  dynamicPills: boolean;
  secondaryFilters: boolean;
  v5JobSearch: boolean;
  keywordAutosuggestV2: boolean;
  hirerVerifiedBadge: boolean;
  serpJobCardInfoDensity1: boolean;
  newJobCardDensity: boolean;
  hideCompanyLogo: boolean;
}

export const initialState: FeatureFlagsState = {
  behaviouralCues: false,
  branchBannerPreview: false,
  isBranchEnabledFlag: false,
  retireDisplayAds: false,
  smarterSearchLaunchCluster: SMARTER_SEARCH_CLUSTERS[0],
  seoMetaTags: false,
  showHomepageBanner: false,
  showFloatingSaveSearch: true,
  autoSelectSplitViewFirstJob: true,
  showMarketingAdSplitView: false,
  loggedOutRecs: false,
  remoteSearchFilter: false,
  remoteSearchFilterNewLabel: false,
  dynamicPills: false,
  secondaryFilters: false,
  v5JobSearch: false,
  keywordAutosuggestV2: false,
  hirerVerifiedBadge: false,
  serpJobCardInfoDensity1: false,
  newJobCardDensity: false,
  hideCompanyLogo: false,
};

export type TFeatureFlagNames = keyof FeatureFlagsState;

export interface Action {
  type: typeof UPDATE_FEATURE_FLAGS;
  payload: {
    dynamicFlags: Record<string, any>;
  };
}

export default function reducer(
  state = initialState,
  action: TypedAction,
): FeatureFlagsState {
  switch (action.type) {
    case UPDATE_FEATURE_FLAGS: {
      const { dynamicFlags } = action.payload;
      return {
        ...state,
        ...dynamicFlags,
      };
    }

    default:
      return state;
  }
}

export const AUTO_SELECT_SPLIT_VIEW_FIRST_JOB = 'autoSelectSplitViewFirstJob';
export const SHOW_FLOATING_SAVE_SEARCH_FEATURE: TFeatureFlagNames =
  'showFloatingSaveSearch';

export const SHOW_MARKETING_AD_SPLIT_VIEW_FEATURE: TFeatureFlagNames =
  'showMarketingAdSplitView';

export const LOGGED_OUT_RECS: TFeatureFlagNames = 'loggedOutRecs';

export const SERP_JOBCARD_INFO_DENSITY_1: TFeatureFlagNames =
  'serpJobCardInfoDensity1';

// defaults to 'default' cluster if no cookie
export const selectSmarterSearchCluster = (
  state: ChaliceStore,
): SmarterSearchCluster =>
  state.featureFlags?.smarterSearchLaunchCluster ?? SMARTER_SEARCH_CLUSTERS[0];

export const selectFeatureFlag =
  (flagName: TFeatureFlagNames) => (state: ChaliceStore) =>
    Boolean(state.featureFlags[flagName]);
