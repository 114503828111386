import { useCallback, useEffect, useState } from 'react';

import { useAppConfig } from 'src/config/appConfig';
import { logger } from 'src/modules/logger';
import {
  homePageRegex,
  jobDetailsPageRegex,
  searchResultsPageRegex,
} from 'src/modules/routes-regexp';
import { useSelector } from 'src/store/react';
import { selectPathname, selectPrevPathname } from 'src/store/selectors';
import { getParam } from 'src/utils/urlParams';

import { useAnalyticsFacade } from '../AnalyticsFacade';
import { getCurrentDevice } from '../chalice-user-agent/device-detect';

const logError = (err: Error) => {
  if (err) {
    logger.error({ err }, 'BranchIO:');
  }
};

type BranchEvent = 'SERP_TO_JDV' | 'SERP' | 'JDV' | 'HOME' | 'OTHER';

interface BranchBannerData {
  banner_id: string;
  journey_link_data: {
    view_name: string;
  };
}

interface BranchInitOptions {
  no_journeys?: boolean;
}

export interface BranchBannerProps {
  shouldShowBranchInterstitial: boolean;
  preview: boolean;
}

const useEvent = (
  shouldShowBranchInterstitial: BranchBannerProps['shouldShowBranchInterstitial'],
  isInitialized: boolean,
) => {
  const pathname = useSelector(selectPathname);
  const prevPathname = useSelector(selectPrevPathname);
  const [event, setEvent] = useState<BranchEvent>('OTHER');
  useEffect(() => {
    // Make sure the branch is initialized first
    // This will prevent a race condition since we should fire `OTHER` first when calling `branch.ini()`. This is to prevent the branch banner from showing up on Homepage immediately instead of waiting for the delay
    // Logic flow: React init, event = 'OTHER' -> branch.init() -> event = (new value) -> (delay if needed) -> branch.track(), to show branch banner
    if (isInitialized && shouldShowBranchInterstitial) {
      if (
        searchResultsPageRegex.test(prevPathname!) &&
        jobDetailsPageRegex.test(pathname)
      ) {
        setEvent('SERP_TO_JDV');
      } else if (searchResultsPageRegex.test(pathname)) {
        setEvent('SERP');
      } else if (jobDetailsPageRegex.test(pathname)) {
        setEvent('JDV');
      } else if (homePageRegex.test(pathname)) {
        setEvent('HOME');
      }
    }
  }, [isInitialized, pathname, prevPathname, shouldShowBranchInterstitial]);
  return event;
};

const showBranch = ({
  branch,
  shouldShowBranchInterstitial,
  preview,
  event,
}: {
  branch: Record<string, any>;
  shouldShowBranchInterstitial: boolean;
  preview: boolean;
  event: BranchEvent;
}) => {
  if (shouldShowBranchInterstitial) {
    branch.track('pageview', { preview });
    branch.logEvent(event, {}, logError);
  } else {
    branch.closeJourney();
  }
};

const useBranchBanner = ({
  shouldShowBranchInterstitial,
  preview,
}: BranchBannerProps) => {
  const analyticsFacade = useAnalyticsFacade();
  const [isInitialized, setHasInitialized] = useState(false);
  const event = useEvent(shouldShowBranchInterstitial, isInitialized);
  const { BRANCH_IO_KEY } = useAppConfig();

  const branchListener = useCallback(
    (eventName: string, data: BranchBannerData) => {
      const type = data?.journey_link_data?.view_name;

      if (eventName === 'didShowJourney') {
        analyticsFacade.branchBannerImpression(type);
      }
      if (eventName === 'didClickJourneyCTA') {
        analyticsFacade.branchBannerCTAPressed(type);

        // TODO: FIXME
        // super ugly hack to ensure that, if the user clicks the 'back' button,
        // branch does not incorrectly display the interstitial
        // since stale metadata is sent to the Branch API before
        // React has finished rendering the DOM with correct metadata
        document
          ?.querySelector('meta[name="branch:deeplink:previewName"]')
          ?.setAttribute('content', 'OTHER');
      }

      if (
        eventName === 'didClickJourneyContinue' ||
        eventName === 'didClickJourneyClose'
      ) {
        analyticsFacade.branchBannerDismissPressed(type);
      }
    },
    [analyticsFacade],
  );

  const getBranch = () => {
    const { branch } = window;
    if (!branch) {
      logger.error('BranchIO is not loaded');
      return;
    }
    return branch;
  };

  // Init the Branch.io
  useEffect(() => {
    const branch = getBranch();
    if (!branch) {
      return;
    }

    if (!isInitialized) {
      try {
        // @ashin: Targeting android app users in a webView so we don't have to
        // show them the branch banner, as they are already in the app!
        const urlParamInjectedByAndroidApp = 'seekAndroidVersion';
        const isAndriodWebView = Boolean(
          getParam(urlParamInjectedByAndroidApp),
        );
        const branchOptions: BranchInitOptions = {};
        if (isAndriodWebView) {
          branchOptions.no_journeys = true;
        }

        branch.init(BRANCH_IO_KEY, branchOptions, (err: Error) => {
          if (err) {
            throw err;
          }

          setHasInitialized(true);
        });
      } catch (err: any) {
        logError(err);
      }
    }
  }, [BRANCH_IO_KEY, isInitialized]);

  // Add listener
  useEffect(() => {
    const branch = getBranch();
    if (!branch) {
      return;
    }

    if (isInitialized) {
      branch.addListener(branchListener);
    }
  }, [branchListener, isInitialized]);

  // Trigger the show Branch Banner when initialized
  useEffect(() => {
    const branch = getBranch();
    if (!branch) {
      return;
    }

    if (isInitialized) {
      showBranch({
        branch,
        shouldShowBranchInterstitial,
        preview,
        event,
      });

      if (event === 'HOME' && getCurrentDevice() === 'Desktop') {
        return () => {
          // Assume that the "event" has been changed (aka path changed), so we will need to close the journey to prevent the branch banner from showing up on the next page
          branch.closeJourney();
        };
      }
    }
  }, [event, isInitialized, preview, shouldShowBranchInterstitial]);

  return {
    metadata: [
      {
        name: 'preview',
        content: 'true',
      },
      {
        name: 'previewName',
        content: event,
      },
    ],
  };
};

export default useBranchBanner;
