import type {
  SalaryType as SalaryFrequencyType,
  SearchParams,
} from '@seek/chalice-types';
import { Box, HiddenVisually, Text } from 'braid-design-system';
import { useEffect, useState } from 'react';

import { Link } from 'src/components/NavLink/NavLink';
import { useSecondaryFilterContext } from 'src/components/Search/SecondaryFilter/context/SecondaryFilterContext';
import type { SalaryRange_SearchSalaryRange } from 'src/config/types';

import * as styles from './SalaryType.css';

export const SalaryType = ({
  updateDefaultSalaryActiveField,
  salaryType,
  defaultFrequency,
  searchQueryWithoutSalary,
  salaryData,
}: {
  updateDefaultSalaryActiveField: () => void;
  salaryType: SalaryFrequencyType;
  defaultFrequency: SalaryFrequencyType;
  searchQueryWithoutSalary: Omit<SearchParams, 'salarytype' | 'salaryrange'>;
  salaryData: SalaryRange_SearchSalaryRange[];
}) => {
  const { isOpen, isOpenSearch, setFilterSelection, searchQuery, ROBOTS_TAG } =
    useSecondaryFilterContext();

  const initialSelectedSalaryType = searchQuery?.salarytype || defaultFrequency;

  const [selectedSalaryType, setSelectedSalaryType] = useState(
    initialSelectedSalaryType,
  );

  const salaryTypes = salaryData.map(
    ({ frequency: { type, label, isDefault } }) => {
      const isActive = type === salaryType;
      return {
        label,
        value: type as SalaryFrequencyType,
        isActive,
        refineParams:
          !isActive && !isDefault
            ? {
                ...searchQueryWithoutSalary,
                salarytype: type as SalaryFrequencyType,
              }
            : searchQueryWithoutSalary,
      };
    },
  );

  useEffect(() => {
    if (isOpenSearch) {
      setSelectedSalaryType(defaultFrequency);
    }

    if (!isOpen) {
      setSelectedSalaryType(initialSelectedSalaryType);
    }
  }, [defaultFrequency, isOpen, isOpenSearch, initialSelectedSalaryType]);

  return (
    <Box data={{ automation: 'salaryType' }} component="section">
      <HiddenVisually>
        <h1>{'Salary type'}</h1>
      </HiddenVisually>
      <Box
        component="ul"
        role="radiogroup"
        display="flex"
        className={styles.scrollable}
      >
        {salaryTypes?.map(({ isActive, value, label, refineParams }) => (
          <Box
            component="li"
            className={{
              [styles.item]: true,
              [styles.active]: selectedSalaryType === value,
            }}
            key={value}
            role="presentation"
            data-automation={`refineSalaryType${value}${
              selectedSalaryType === value ? '-active' : ''
            }`}
          >
            <Text
              tone={selectedSalaryType === value ? 'formAccent' : 'secondary'}
            >
              <Link
                rel={ROBOTS_TAG}
                role="radio"
                aria-label={`Salary type, ${label}`}
                aria-checked={selectedSalaryType === value}
                location={
                  isActive
                    ? {
                        pathname: '/jobs',
                        query: searchQuery,
                        hash: '#',
                      }
                    : {
                        pathname: '/jobs',
                        query: refineParams,
                      }
                }
                onClick={() => {
                  setSelectedSalaryType(value);
                  setFilterSelection((current) => {
                    delete current.salaryrange;
                    return {
                      ...current,
                      salarytype: value as SearchParams['salarytype'],
                    };
                  });

                  updateDefaultSalaryActiveField();
                }}
                disabledLinkNavigate
              >
                {label}
              </Link>
            </Text>
          </Box>
        ))}
      </Box>
    </Box>
  );
};
