export const getSearchParams = (source?: string | Record<string, string>) => {
  const searchParams =
    typeof window === 'undefined' ? '' : window.location.search;
  return new URLSearchParams(source || searchParams);
};

export const getParam = (param: string | string[], url?: string) => {
  const params = getSearchParams(url);
  if (Array.isArray(param)) {
    return params.get(param.find((p) => params.get(p)) || '') || '';
  }
  return params.get(param) || '';
};
