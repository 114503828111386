import { useTranslations } from '@vocab/react';

import { useAppConfig } from 'src/config/appConfig';

import translations from './.vocab';

export const useMonthAndYear = (): { month: string; year: string } => {
  const { locale } = useAppConfig();
  const { t } = useTranslations(translations);
  const months = [
    t('jan-title'),
    t('feb-title'),
    t('mar-title'),
    t('apr-title'),
    t('may-title'),
    t('jun-title'),
    t('jul-title'),
    t('aug-title'),
    t('sep-title'),
    t('oct-title'),
    t('nov-title'),
    t('dec-title'),
  ];
  const date = new Date();

  const year =
    new Intl.DateTimeFormat(locale)
      .formatToParts(date)
      .find(({ type }) => type === 'year')?.value ||
    date.getFullYear().toString();

  const month = months[date.getMonth()];
  return {
    month,
    year,
  };
};
