/* eslint-disable no-console */
import { raygunIsEnabled } from 'src/config';

export interface RayGunError {
  error?: Error;
  req?: Record<string, any>;
  customData?: Record<string, any>;
}

const dummyLogger = ({ error }: RayGunError) => {
  console.error("Raygun disabled, but here's your error", error);
};

let logger = dummyLogger;

export const logErrorToRaygun = ({ error }: RayGunError) => {
  logger({ error });
};

export const setupLogger = (logProvider: (error: RayGunError) => void) => {
  if (raygunIsEnabled) {
    logger = logProvider;
  }
};
