import { isProduction } from '..';
import type { AppConfig } from '../types';

export default {
  zone: 'asia-5',
  defaultLocale: 'en-MY',
  availableLocales: ['en-MY'],
  timedBanners: {
    launchBanner: {
      displayDates: {
        startDate: '2024-09-27T11:00:00.000Z', // 27 September 2024, 19:00:00 Local Time
        endDate: '2024-10-11T16:00:00.000Z', // 12 October 2024, 00:00:00 Local Time
      },
      bannerUrl: {
        en: 'https://help.my.jobstreet.com/s/article/Why-has-the-website-address-changed-MY',
      },
      oldUrl: 'jobstreet.com.my',
      newUrl: 'my.jobstreet.com',
    },
  },
  zoneFeatures: {
    NORTHSTAR_SEO_TAGS: true,
    NUDGE_ENABLED: false,
    SEARCH_PAGE_SIZE: 30,
    HOMEPAGE_BANNER_TYPE: 'GENERIC_ASIA_BANNER',
    SHOW_FLOATING_SAVE_SEARCH: false,
    AUTO_SELECT_SPLIT_VIEW_FIRST_JOB: false,
    SHOW_MARKETING_AD_SPLIT_VIEW: false,
    LMIS_ENABLED: true,
    BEHAVIOURAL_CUES_ENABLED: false,
    LOGGED_OUT_RECS: false,
    REMOTE_SEARCH_FILTER: true,
    REMOTE_SEARCH_FILTER_NEW_LABEL: true,
    DYNAMIC_PILLS: false,
    SECONDARY_FILTERS: false,
    ENABLE_V5_JOB_SEARCH: false,
    KEYWORD_AUTOSUGGEST_V2: true,
    NEW_JOB_CARD_DENSITY: false,
    ENABLE_VERIFIED_HIRER_BADGE: false,
    SERP_JOBCARD_INFO_DENSITY_1: false,
  },
  BRANCH_IO_KEY: isProduction
    ? 'key_live_keVW4JKlS0jBJLPfSRNUJnhhtElmK541'
    : 'key_test_igQ77GMmQ1itGVIiMGXzojmavAcg9pio',
} as AppConfig;
