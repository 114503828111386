import { getLanguageFromLocale } from '@seek/melways-sites';

import { useAppConfig } from 'src/config/appConfig';
import { classifications } from 'src/config/classifications';
import { logger } from 'src/modules/logger';

const DISPLAYED_KEYS = 1;

export const useClassifications = ({
  classification,
  subclassification,
  classRemainderFn,
  commaFormattedClassification,
}: {
  classification?: string;
  subclassification?: string;
  classRemainderFn?: (classificationCount: number) => string;
  commaFormattedClassification?: boolean;
}): string => {
  const { zone, defaultLocale, language } = useAppConfig();
  const fallbackLanguageCode = getLanguageFromLocale(defaultLocale);
  const classificationIds = classification ? classification.split(',') : [];
  const subClassificationIds = subclassification
    ? subclassification.split(',')
    : [];

  const firstClassificationId = Number(classificationIds[0]);
  const classificationCache =
    classifications[zone][language] ??
    classifications[zone][fallbackLanguageCode];

  if (!classificationCache) {
    logger.error(
      { zone, language },
      'Undefined language in classification cache',
    );
  }

  const parentClassification = classificationCache?.find(
    ({ id }) => id === firstClassificationId,
  );

  const parentClassifications = classificationIds.map((class_id) =>
    classificationCache?.find(({ id }) => id === Number(class_id)),
  );

  // Get a list of classifications without a subclassification selected
  const selectedParentClassifications = parentClassifications.filter(
    (parentClass) =>
      !parentClass?.subClassifications.find(({ id }) =>
        subClassificationIds.includes(id.toString()),
      ),
  );

  const classificationDesc = parentClassification?.description || '';
  const subClassificationDesc = parentClassification?.subClassifications.find(
    ({ id }) => subClassificationIds.includes(id.toString()),
  )?.description;

  const classificationCount = subClassificationIds
    ? subClassificationIds.length +
      selectedParentClassifications.length -
      DISPLAYED_KEYS
    : classificationIds.length - DISPLAYED_KEYS;

  const classRemainder =
    classificationCount > 0 && classRemainderFn
      ? classRemainderFn(classificationCount)
      : '';

  if (commaFormattedClassification) {
    return `${classificationDesc}, ${subClassificationDesc}`;
  }

  return `${
    subClassificationDesc ? subClassificationDesc : classificationDesc
  }${classRemainder}`;
};
