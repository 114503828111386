import { useMutation } from '@apollo/client';
import { useTranslations } from '@vocab/react';
import { useCallback, useMemo } from 'react';

import { useAppConfig } from 'src/config/appConfig';
import { getJobStatusFlags } from 'src/hooks/useSaveJob/utils/jobStatus';
import useSignInRedirect from 'src/hooks/useSaveJob/utils/useSignInRedirect';
import { useSolHash } from 'src/hooks/useSolHash';
import { getTimezone } from 'src/hooks/useTimezone';
import {
  isBrowserAnalyticsFacade,
  useAnalyticsFacade,
} from 'src/modules/AnalyticsFacade';
import {
  CREATE_SAVED_JOB,
  DELETE_SAVED_JOB,
} from 'src/modules/graphql/mutations';
import type {
  CreateSavedJob,
  CreateSavedJobVariables,
  DeleteSavedJob,
  DeleteSavedJobVariables,
} from 'src/modules/graphql/mutations/types';
import {
  GET_JOB_DETAILS_PERSONALISED,
  SEARCH_SAVED_AND_APPLIED_JOBS,
} from 'src/modules/graphql/queries';
import type {
  GetJobDetailsPersonalised,
  SearchSavedAndAppliedJobs,
} from 'src/modules/graphql/queries/types';
import { useSelector } from 'src/store/react';
import {
  selectAuthenticated,
  selectLocation,
  selectResultsJobIds,
  selectSelectedJobId,
  selectSessionId,
} from 'src/store/selectors';
import { updateA11yAnnouncement } from 'src/utils/a11y/announce';
import { getViewJobOriginRef } from 'src/utils/eventCapture/eventCaptureUtils';

import translations from './.vocab';

export interface UseToggleSaveJobProps {
  jobId: string;
  view: 'jobDetails' | 'serp';
  linkPosition: 'job details' | 'listing';
  jobTracking?: string;
  solMetadataReference?: string;
  savedJobsData?: SearchSavedAndAppliedJobs | GetJobDetailsPersonalised;
  forceSave?: boolean;
}

const isSearchSavedAndAppliedJobsData = (
  data: SearchSavedAndAppliedJobs | GetJobDetailsPersonalised | undefined,
): data is SearchSavedAndAppliedJobs =>
  Boolean(data && data.hasOwnProperty('viewer'));

export const useToggleSavedJob = ({
  jobId,
  view,
  linkPosition,
  solMetadataReference,
  jobTracking,
  savedJobsData,
  forceSave,
}: UseToggleSaveJobProps) => {
  const { t } = useTranslations(translations);
  const analyticsFacade = useAnalyticsFacade();
  const isAuthenticated = useSelector(selectAuthenticated);
  const sessionId = useSelector(selectSessionId);
  const currentLocation = useSelector(selectLocation);
  const { zone, locale, language: languageCode } = useAppConfig();
  const selectedJobId = useSelector(selectSelectedJobId);
  const jobIds = useSelector(selectResultsJobIds);
  const timezone = getTimezone();

  const postSignInLocation = {
    ...currentLocation,
    query: {
      ...(currentLocation.query || {}),
      savejob: jobId,
      jobId: selectedJobId,
    },
  };
  const redirectToSignIn = useSignInRedirect(postSignInLocation)[1];
  const [, solMetadata] = useSolHash({
    id: jobId,
    isLinkOut: false,
    solMetadataReference,
  });

  const { isApplied, isSaved } = useMemo(() => {
    const isSearchSavedAndAppliedType =
      isSearchSavedAndAppliedJobsData(savedJobsData);

    if (isSearchSavedAndAppliedType) {
      return getJobStatusFlags({
        jobId,
        savedAndAppliedJobs: savedJobsData,
      });
    }

    return {
      isApplied: Boolean(
        savedJobsData?.jobDetails?.personalised?.appliedDateTime
          ?.shortAbsoluteLabel,
      ),
      isSaved: Boolean(savedJobsData?.jobDetails?.personalised?.isSaved),
    };
  }, [jobId, savedJobsData]);

  const [createSavedJob] = useMutation<CreateSavedJob, CreateSavedJobVariables>(
    CREATE_SAVED_JOB,
    {
      onCompleted(data) {
        if (data.createSavedJob2.__typename === 'CreateSavedJobSuccess') {
          updateA11yAnnouncement(t('Job saved'));
        } else {
          updateA11yAnnouncement(data.createSavedJob2.errors[0].message);
        }
      },
      update: (cache) => {
        const searchSavedAndAppliedJobsCachedData =
          cache.readQuery<SearchSavedAndAppliedJobs>({
            query: SEARCH_SAVED_AND_APPLIED_JOBS,
            variables: { jobIds },
          });

        if (searchSavedAndAppliedJobsCachedData) {
          setTimeout(() => {
            cache.writeQuery({
              query: SEARCH_SAVED_AND_APPLIED_JOBS,
              variables: { jobIds },
              data: {
                viewer: {
                  __typename: 'Candidate',
                  searchAppliedJobs:
                    searchSavedAndAppliedJobsCachedData?.viewer
                      ?.searchAppliedJobs,
                  searchSavedJobs: [
                    ...(searchSavedAndAppliedJobsCachedData?.viewer
                      ?.searchSavedJobs ?? []),
                    {
                      __typename: 'SearchSavedJobPartial',
                      jobId,
                    },
                  ],
                },
              },
            });
          }, 0);
        }

        const jobDetailsPersonalisedCachedData =
          cache.readQuery<GetJobDetailsPersonalised>({
            query: GET_JOB_DETAILS_PERSONALISED,
            variables: {
              id: jobId,
              languageCode,
              locale,
              timezone,
              zone,
            },
          });

        if (jobDetailsPersonalisedCachedData) {
          cache.writeQuery({
            query: GET_JOB_DETAILS_PERSONALISED,
            variables: {
              id: jobId,
              languageCode,
              locale,
              timezone,
              zone,
            },
            data: {
              jobDetails: {
                personalised: {
                  ...jobDetailsPersonalisedCachedData?.jobDetails?.personalised,
                  isSaved: true,
                },
                __typename: 'JobDetails',
              },
            },
          });
        }
      },
    },
  );

  const [deleteSavedJob] = useMutation<DeleteSavedJob, DeleteSavedJobVariables>(
    DELETE_SAVED_JOB,
    {
      onCompleted(data) {
        if (data.deleteSavedJob2.__typename === 'DeleteSavedJobSuccess') {
          updateA11yAnnouncement(t('Job unsaved'));
        } else {
          updateA11yAnnouncement(data.deleteSavedJob2.errors[0].message);
        }
      },
      update: (cache) => {
        const searchSavedAndAppliedJobsCachedData =
          cache.readQuery<SearchSavedAndAppliedJobs>({
            query: SEARCH_SAVED_AND_APPLIED_JOBS,
            variables: { jobIds },
          });

        if (searchSavedAndAppliedJobsCachedData) {
          cache.writeQuery({
            query: SEARCH_SAVED_AND_APPLIED_JOBS,
            variables: { jobIds },
            data: {
              viewer: {
                __typename: 'Candidate',
                searchAppliedJobs:
                  searchSavedAndAppliedJobsCachedData?.viewer
                    ?.searchAppliedJobs,
                searchSavedJobs:
                  searchSavedAndAppliedJobsCachedData?.viewer?.searchSavedJobs.filter(
                    (searchSavedJob) => searchSavedJob.jobId !== jobId,
                  ),
              },
            },
          });
        }

        const jobDetailsPersonalisedCachedData =
          cache.readQuery<GetJobDetailsPersonalised>({
            query: GET_JOB_DETAILS_PERSONALISED,
            variables: {
              id: jobId,
              languageCode,
              locale,
              timezone,
              zone,
            },
          });

        if (jobDetailsPersonalisedCachedData) {
          cache.writeQuery({
            query: GET_JOB_DETAILS_PERSONALISED,
            variables: {
              id: jobId,
              languageCode,
              locale,
              timezone,
              zone,
            },
            data: {
              jobDetails: {
                personalised: {
                  ...jobDetailsPersonalisedCachedData?.jobDetails?.personalised,
                  isSaved: false,
                },
                __typename: 'JobDetails',
              },
            },
          });
        }
      },
    },
  );

  // this function is used by the save button on the job details page and job card
  const toggleSavedJob = useCallback(async () => {
    const viewJobOriginRef = getViewJobOriginRef();
    if (!isAuthenticated) {
      analyticsFacade.saveJobClicked({
        jobId,
        linkPosition,
        jobViewOriginQuery: viewJobOriginRef,
      });
      redirectToSignIn();
      return;
    }

    if (isSaved && !forceSave) {
      await deleteSavedJob({
        variables: {
          input: {
            id: `${jobId}`,
            eventCaptureData: {
              channel: 'web',
              view,
              serpTracking: jobTracking,
              eventCaptureSessionId: sessionId ?? '',
            },
          },
          locale,
        },
      });

      if (isBrowserAnalyticsFacade(analyticsFacade)) {
        analyticsFacade.saveJobRemoved(
          { solMetadata },
          { jobId, linkPosition },
        );
      }
    } else {
      await createSavedJob({
        variables: {
          input: {
            id: `${jobId}`,
            zone,
            eventCaptureData: {
              channel: 'web',
              view,
              serpTracking: jobTracking,
              eventCaptureSessionId: sessionId ?? '',
            },
          },
          locale,
        },
      });

      if (isBrowserAnalyticsFacade(analyticsFacade)) {
        analyticsFacade.saveJobCreated(
          { solMetadata },
          { jobId, linkPosition },
        );
      }
    }
  }, [
    view,
    isAuthenticated,
    isSaved,
    analyticsFacade,
    jobId,
    linkPosition,
    redirectToSignIn,
    deleteSavedJob,
    jobTracking,
    sessionId,
    locale,
    solMetadata,
    createSavedJob,
    zone,
    forceSave,
  ]);

  return { toggleSavedJob, isApplied, isSaved };
};
