import type { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import type { JobTag } from '@seek/chalice-types';
import {
  mapJobTagsToBadges,
  type JobActionOrigin,
} from '@seek/seek-jobs-analytics';
import { addTags, getJobDetailsMetadata, type SolMetadata } from '@seek/sol-js';
import { parse } from 'query-string';

import {
  type AnalyticsFacade,
  type BrowserAnalyticsFacade,
  isBrowserAnalyticsFacade,
  isUniversalAnalyticsFacade,
} from 'src/modules/AnalyticsFacade';
import type {
  JobDetails,
  PersonalisedJobDetails,
} from 'src/store/jobdetails/jobdetails';
import type { ChaliceStore } from 'src/store/reducer';
import type { RedialLocals } from 'src/types/RedialLocals';

import { selectExperimentsAsSolTags } from '../store/experiments/experimentHelpers';
import {
  getTestScope,
  isTestAgent,
} from '../utils/productionTesting/productionTesting';

interface OptionsType {
  jobDetails: JobDetails | null;
  correlationId: string;
  getSolMetadata: () => SolMetadata;
  jobTags?: JobTag[];
  jobViewOrigin?: string;
  jobViewType: 'standalone' | 'split' | 'preview';
  jobListingType?: string;
  jobActionOrigin?: string;
  jobListingPosition?: number;
  omnitureTracking?: string;
}

export const createGetSolMetadataForJDP =
  (options: {
    experiments: ChaliceStore['experiments'];
    hash: ChaliceStore['location']['hash'];
    jobId?: number | string;
    query: RedialLocals['query'];
  }) =>
  (): SolMetadata => {
    if (!options.jobId) {
      return {};
    }

    const lowercaseKeys = (record: Record<string, any>) =>
      Object.entries(record).reduce<Record<string, any>>(
        (memo, [key, value]) => ({
          ...memo,
          [key.toLowerCase()]: value,
        }),
        {},
      );
    const hash = (options.hash ?? '').replace('#', '');
    const hashValues = lowercaseKeys(parse(hash));
    const queryValues = lowercaseKeys(options.query || {});
    const baseData = getJobDetailsMetadata({
      jobId: options.jobId.toString(),
      searchRequestToken:
        queryValues.searchrequesttoken ?? hashValues.searchRequestToken,
      token: queryValues.token ?? hashValues.token,
      key: queryValues.sol ?? hashValues.sol,
    });
    const experimentTags = selectExperimentsAsSolTags({
      experiments: options.experiments,
    });
    const taggedData = addTags(baseData, experimentTags);
    return taggedData;
  };

export type HubbleJDVEventTriggerTag =
  | 'default'
  | 'auto_display'
  | 'pre_selected';

export const getIsAddSalaryPrefNudgeShown = ({
  isAuthenticated,
  isSalaryLabel,
  salaryMatch,
}: {
  isAuthenticated?: boolean;
  isSalaryLabel?: boolean;
  salaryMatch?: NonNullable<PersonalisedJobDetails>['salaryMatch'];
}) =>
  (!isAuthenticated && !isSalaryLabel) ||
  salaryMatch?.__typename === 'JobProfileMissingSalaryPreference';

export const getIsSalaryMatch = (
  salaryMatch?: NonNullable<PersonalisedJobDetails>['salaryMatch'],
) => salaryMatch?.__typename === 'JobProfileSalaryMatch';

export function trackJobDetailsLoaded(
  analyticsFacade: AnalyticsFacade,
  options: OptionsType,
  state: ChaliceStore,
  apolloClient: ApolloClient<NormalizedCacheObject>,
  eventTrigger?: HubbleJDVEventTriggerTag,
) {
  const isAuthenticated = Boolean(state.user?.authenticated);
  const { jobDetails, jobViewOrigin, jobActionOrigin } = options;
  const solMetadata = options.getSolMetadata();

  const isAddSalaryPrefNudgeShown = getIsAddSalaryPrefNudgeShown({
    isAuthenticated,
    isSalaryLabel: Boolean(state.jobdetails.result?.job.salary?.label),
    salaryMatch: state?.jobdetails?.personalised?.salaryMatch,
  });

  const isSalaryMatch = getIsSalaryMatch(
    state?.jobdetails?.personalised?.salaryMatch,
  );

  /**
   * Only applicable if user is in splitView since currently
   * there is no way to track jobTags if user is in standalone
   * job details page.
   */
  const jobBadges = mapJobTagsToBadges(options?.jobTags);

  const universalAnalyticsPayload: Parameters<
    (typeof analyticsFacade)['jobDetailsLoaded']
  >[0] = {
    brand: state.appConfig.brand,
    isApplied: Boolean(
      state?.jobdetails?.personalised?.appliedDateTime?.shortAbsoluteLabel,
    ),
    isAddSalaryPrefNudgeShown,
    isHirerVerified:
      Boolean(state?.jobdetails?.result?.job?.isVerified) ?? false,
    isSalaryMatch,
    isStrongApplicantBadgeShown: Boolean(
      state?.jobdetails?.personalised?.topApplicantBadge,
    ),
    flightId: solMetadata?.tags?.mordor__flights ?? '',
    jobActionOrigin: jobActionOrigin as JobActionOrigin,
    jobBadges,
    jobListingPosition: options.jobListingPosition,
    jobListingType: options.jobListingType,
    jobViewOriginQuery: jobViewOrigin,
    jobViewType: options.jobViewType,
    ...(jobDetails?.hasOwnProperty('sourcr') && {
      opportunityType: 'recruiter',
    }),
    searchRemoteOptions: state.search.query?.workarrangement,
  };

  if (jobDetails) {
    if (isUniversalAnalyticsFacade(analyticsFacade)) {
      analyticsFacade.jobDetailsLoaded(universalAnalyticsPayload);
    } else if (isBrowserAnalyticsFacade(analyticsFacade)) {
      const SOL_EVENT_TRIGGER_TAG = 'sol:eventTrigger';
      solMetadata.tags = {
        ...solMetadata.tags,
        [SOL_EVENT_TRIGGER_TAG]: eventTrigger ?? 'default',
      };

      const hubblePayload: Parameters<
        InstanceType<typeof BrowserAnalyticsFacade>['jobDetailsLoaded']
      >[1] = {
        // Application tips is not currently implemented in Chalice
        applicationTipsType: 'NotShown',
        isSalaryMatch,
        isAddSalaryPrefNudgeShown,
        jobBadges,
        jobId: jobDetails?.job?.id,
        linkout: jobDetails?.job?.isLinkOut,
        solMetadata,
        topApplicantBadgeShown: Boolean(
          state?.jobdetails?.personalised?.topApplicantBadge,
        ),
      };

      const testHeaders = state.user.testHeaders;
      const isTestUser = isTestAgent(testHeaders);

      const tags = isTestUser
        ? {
            testRecord: isTestUser,
            testScope: getTestScope(testHeaders),
          }
        : {};

      const eventCapturePayload: Parameters<
        InstanceType<typeof BrowserAnalyticsFacade>['jobDetailsLoaded']
      >[2] = {
        candidateId: state.user.seekerId,
        omnitureTracking: options.omnitureTracking,
        zone: state.appConfig.zone,
        jobDetailsViewedCorrelationId: options.correlationId,
        tags,
      };

      analyticsFacade.jobDetailsLoaded(
        universalAnalyticsPayload,
        hubblePayload,
        eventCapturePayload,
        apolloClient,
      );
    }
  }
}
