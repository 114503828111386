import type { ChaliceStore, TypedAction } from '../reducer';

export const PAGE_LOADED = 'PAGE_LOADED';

export interface UiState {
  isPageLoaded: boolean;
}

export interface PageLoadedAction {
  type: typeof PAGE_LOADED;
  payload: {
    isPageLoaded: boolean;
  };
}

export type Action = PageLoadedAction;

export const initialState: UiState = {
  isPageLoaded: false,
};

export default function reducer(
  state: UiState = initialState,
  action: TypedAction,
): UiState {
  switch (action.type) {
    case PAGE_LOADED: {
      const { isPageLoaded } = action.payload;
      return {
        ...state,
        isPageLoaded,
      };
    }

    default:
      return state;
  }
}

export const pageLoaded = (isPageLoaded = false): TypedAction => ({
  type: PAGE_LOADED,
  payload: {
    isPageLoaded,
  },
});

export const selectIsPageLoaded = (state: ChaliceStore) =>
  state.ui.isPageLoaded;
