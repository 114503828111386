import type { Zone } from '@seek/audience-zones';
import type {
  Brand,
  CountrySiteName,
  Language,
  Locale,
} from '@seek/melways-sites';

import type { ZoneFeatures } from 'src/config/types';
import { toLocale } from 'src/modules/locale-utils';
import type { Country } from 'src/types/globals';

export const SET_LANGUAGE = 'SET_LANGUAGE';

export interface AppConfigState {
  brand: Brand;
  site: CountrySiteName;
  language: Language;
  locale: Locale;
  country: Country;
  zoneFeatures: ZoneFeatures;
  zone: Zone;
}

export const initialState: AppConfigState = {
  brand: 'seek',
  site: 'candidate-seek-au',
  language: 'en',
  country: 'AU',
  zone: 'anz-1',
  zoneFeatures: {},
  locale: 'en-AU',
};

export type AppConfigAction = SetLanguageAction;

export interface SetLanguageAction {
  type: typeof SET_LANGUAGE;
  payload: {
    language: Language;
  };
}

export const setLanguage = (language: Language): SetLanguageAction => ({
  type: SET_LANGUAGE,
  payload: { language },
});

export default function reducer(
  state: AppConfigState = initialState,
  action: AppConfigAction,
): AppConfigState {
  switch (action.type) {
    case SET_LANGUAGE: {
      const { language } = action.payload;
      const { country } = state;

      return {
        ...state,
        language,
        locale: toLocale(language, country),
      };
    }

    default:
      return state;
  }
}
