import type { Country } from '@seek/melways-sites';
import { useCallback } from 'react';

import { useAppConfig } from 'src/config/appConfig';
import { useSelector } from 'src/store/react';
import {
  selectFeatureFlag,
  selectIsCompanySearch,
  selectPageNumber,
  selectPathname,
  selectResultsCount,
  selectResultsLocation,
  selectResultsTitle,
  selectSearchParams,
} from 'src/store/selectors';

import { generateMetaDescription, generateMetaTitle } from '../utils';

import { useSearchResultsHeadDescription } from './description';
import generateOldAnZTitle from './seek-jobs-seo/get-search-results-head-title';
import { useSearchResultsHeadTitle } from './title';

export function useSearchResultsHead() {
  const companySearch = useSelector(selectIsCompanySearch);
  const { country, locale } = useAppConfig();
  const isSeoMetaTagsOn = useSelector(selectFeatureFlag('seoMetaTags'));
  const page = useSelector(selectPageNumber)?.toString() || '1';
  const pagePathName = useSelector(selectPathname);
  const resultsLocation = useSelector(selectResultsLocation);
  const searchParams = useSelector(selectSearchParams);
  const title = useSelector(selectResultsTitle);
  const jobCount = useSelector(selectResultsCount)?.toString() || '';

  const isCompanySearch = Boolean(companySearch);
  const seoPageDescription = useSearchResultsHeadDescription({
    isCompanySearch,
  });

  const unifiedTitle = useSearchResultsHeadTitle({
    page,
    fallBackTitle: title,
    isCompanySearch,
  });

  const oldAnzTitle = useCallback(
    () =>
      generateOldAnZTitle({
        page,
        fallBackTitle: title,
        isCompanySearch,
        searchParams,
        location: resultsLocation,
        country: (country.toLowerCase() as Country) || 'au',
        pageLocale: locale,
        pagePathName,
        jobCount,
        shortLocationName: resultsLocation?.shortLocationName,
        locale,
      }),
    [
      page,
      title,
      isCompanySearch,
      searchParams,
      resultsLocation,
      country,
      locale,
      pagePathName,
      jobCount,
    ],
  );

  const seoPageTitle = isSeoMetaTagsOn ? unifiedTitle : oldAnzTitle();

  const titleMeta = generateMetaTitle(seoPageTitle);
  const descriptionMeta = generateMetaDescription(seoPageDescription);

  const meta = [...titleMeta, ...descriptionMeta];

  return {
    title: seoPageTitle,
    meta,
  };
}
