import { assignInlineVars } from '@vanilla-extract/dynamic';
import { useTranslations } from '@vocab/react';
import { Box, HiddenVisually, IconChevron, Text } from 'braid-design-system';
import {
  useCallback,
  useRef,
  useState,
  type FormEvent,
  type MouseEvent,
} from 'react';

import { useDateListedField } from 'src/components/Search/RefineBar/DateListed/useDateListedField';
import { useSalaryTypeField } from 'src/components/Search/RefineBar/SalaryType/useSalaryTypeField';
import { useDispatch, useSelector } from 'src/store/react';
import { selectFeatureFlag, selectRefinement } from 'src/store/selectors';

import translations from './.vocab';
import MoreOptions from './MoreOptions/MoreOptions';
import { Status } from './Status/Status';
import { useWorkArrangementsField } from './WorkArrangements/useWorkArrangementsField';
import { useWorkTypesField } from './WorkTypes/useWorkTypesField';

import * as styles from './RefineBar.css';

interface RefineBarProps {
  expanded: boolean;
}

export interface RefineField {
  id: string;
  ariaLabel: string;
  filterComponent: () => JSX.Element;
  label?: JSX.Element | string;
  summarySentence?: string;
  status?: { label: string };
  isSelected: boolean;
}

export default function RefineBar(props: RefineBarProps) {
  const { expanded } = props;
  const { t } = useTranslations(translations);
  const dispatch = useDispatch();
  const [panelPos, setPanelPos] = useState(0);
  const [activeFieldId, setActiveFieldId] = useState<string | null>(null);
  const containerRef = useRef<HTMLElement>(null);

  const updateActiveField = useCallback(
    (fieldId: string, { left, width }: DOMRect) => {
      const panelMaxWidth = 400; /* contentWidth.small */
      const viewportWidth = document.documentElement.clientWidth;
      const containerOffset =
        containerRef.current?.getBoundingClientRect().left || 0;
      const targetLeftPos = left - containerOffset;
      const exceedsViewportWidth = left + panelMaxWidth > viewportWidth;

      setPanelPos(
        exceedsViewportWidth
          ? targetLeftPos - (panelMaxWidth - width)
          : targetLeftPos,
      );
      setActiveFieldId(fieldId);
    },
    [],
  );

  const handleSelection = useCallback(
    (type: Parameters<typeof selectRefinement>[0]) => {
      if (type !== 'WorkType' && type !== 'WorkArrangement') {
        setActiveFieldId(null);
        return;
      }

      const action = selectRefinement(type);
      dispatch(action);
    },
    [dispatch, setActiveFieldId],
  );

  const remoteSearchFilterFeatureToggle = useSelector(
    selectFeatureFlag('remoteSearchFilter'),
  );

  const workArrangementsField = useWorkArrangementsField((fieldId: string) =>
    handleSelection(fieldId),
  );

  const fields: RefineField[] = [
    useWorkTypesField((fieldId: string) => handleSelection(fieldId)),
    ...(remoteSearchFilterFeatureToggle ? [workArrangementsField] : []),
    ...useSalaryTypeField((fieldId: string) => handleSelection(fieldId)),
    useDateListedField((fieldId: string) => handleSelection(fieldId)),
  ];

  const isAnyFieldActive = fields.some(({ id }) => id === activeFieldId);
  const fieldName = 'RefineBar__Toggle';

  return (
    <Box>
      <Box
        display={{
          mobile: 'none',
          tablet: expanded ? 'none' : 'flex',
        }}
        justifyContent="flexEnd"
        alignItems="center"
        paddingY={styles.summaryLabelVerticalPadding}
        aria-hidden={expanded}
      >
        <MoreOptions />
      </Box>

      <Box
        component="aside"
        role="navigation"
        aria-hidden={!expanded}
        aria-labelledby="refine-your-search"
        className={{
          [styles.root]: true,
          [styles.rootIsExpanded]: expanded,
        }}
        position="relative"
        data-automation="refineBar"
        style={assignInlineVars({
          [styles.panelPosLeft]: `${panelPos}px`,
        })}
      >
        <HiddenVisually>
          <h1 id="refine-your-search">{t('Refine your search')}</h1>
        </HiddenVisually>

        <Box
          className={{
            [styles.summary]: true,
            [styles.summaryIsExpanded]: expanded,
          }}
        >
          <Box
            ref={containerRef}
            position="relative"
            display="flex"
            alignItems="center"
          >
            {fields.map((field, index) => {
              const isActiveOrSelected =
                activeFieldId === field.id || field.isSelected;
              return (
                <Box position="relative" key={field.id}>
                  <Box
                    component="input"
                    id={`RefineBar--${field.id}`}
                    className={styles.toggle}
                    name={fieldName}
                    type="radio"
                    role="button"
                    aria-expanded={expanded}
                    aria-label={t('refine by {field}', {
                      field: field.ariaLabel,
                    })}
                    checked={activeFieldId === field.id}
                    onChange={(event: FormEvent<HTMLInputElement>) => {
                      updateActiveField(
                        field.id,
                        event.currentTarget.getBoundingClientRect(),
                      );
                    }}
                  />

                  <Box
                    component="label"
                    className={{
                      [styles.summaryLabel]: true,
                      [styles.summaryLabelIsNotRefining]:
                        isAnyFieldActive && activeFieldId !== field.id,
                    }}
                    boxShadow="borderNeutral"
                    marginRight={
                      index < fields.length - 1 ? 'xxsmall' : undefined
                    }
                    htmlFor={`RefineBar--${field.id}`}
                    onClick={(event: MouseEvent<HTMLInputElement>) => {
                      updateActiveField(
                        field.id,
                        event.currentTarget.getBoundingClientRect(),
                      );
                    }}
                    data-automation={`toggle${field.id}Panel`}
                  >
                    <Box
                      component="span"
                      position="absolute"
                      borderRadius="full"
                      inset={0}
                      opacity={isActiveOrSelected ? undefined : 0}
                      transition="fast"
                      className={styles.summarySelectedBg}
                    />
                    <HiddenVisually>
                      {t('Show {field} refinements.', {
                        field: field.ariaLabel,
                      })}
                    </HiddenVisually>
                    <Box display="flex" position="relative">
                      <Text size="small">
                        <span
                          className={
                            isActiveOrSelected
                              ? styles.selectedSummary
                              : undefined
                          }
                        >
                          {field.summarySentence ? (
                            <span
                              aria-hidden
                            >{`${field.summarySentence} `}</span>
                          ) : (
                            ''
                          )}
                          {field.label}{' '}
                          <IconChevron
                            direction={
                              activeFieldId === field.id ? 'up' : 'down'
                            }
                          />
                        </span>
                      </Text>
                    </Box>
                    {field.status && (
                      <Box className={styles.status}>
                        <Status
                          label={field.status.label}
                          isActive={activeFieldId === field.id}
                          isSelected={field.isSelected}
                        />
                      </Box>
                    )}
                  </Box>
                </Box>
              );
            })}

            {/* Must follow individual refinement toggles to layer on top */}
            <Box
              component="label"
              display={isAnyFieldActive ? 'block' : 'none'}
              position="absolute"
              inset={0}
              zIndex={1}
              htmlFor="RefineBar--Done"
              onClick={() => setActiveFieldId(null)}
              data-automation="refineBarToggleClose"
            >
              <HiddenVisually>{t('Hide refinements')}</HiddenVisually>
            </Box>
          </Box>
        </Box>

        {/* Must follow the refine summary to layer on top */}
        <Box
          component="input"
          id="RefineBar--Done"
          opacity={0}
          position="absolute"
          cursor="pointer"
          type="radio"
          name={fieldName}
          onChange={() => setActiveFieldId(null)}
        />
        <Box
          component="label"
          position="fixed"
          inset={0}
          display={isAnyFieldActive ? 'block' : 'none'}
          htmlFor="RefineBar--Done"
          data-automation="refineBarClickOutClose"
          onClick={() => setActiveFieldId(null)}
        >
          <HiddenVisually>{t('Hide refinements')}</HiddenVisually>
        </Box>

        {/* Must follow the click outside done label to layer on top */}
        {fields.map((field) => (
          <Box
            key={field.id}
            background="surface"
            boxShadow="medium"
            display={activeFieldId === field.id ? 'block' : 'none'}
            className={styles.refinementPanel}
          >
            <Box
              position="absolute"
              inset={0}
              pointerEvents="none"
              borderRadius="xlarge"
              boxShadow="borderNeutralLight"
            />
            <field.filterComponent />
          </Box>
        ))}
      </Box>
    </Box>
  );
}
