import type { UrlLocation } from '@seek/chalice-types';
import type { Country as MelwaysCountry } from '@seek/melways-sites';
import { canonicaliseUrl, generateAlternateLinks } from '@seek/seek-jobs-seo';

import type {
  ChaliceStore,
  TypedAction,
  TypedThunkAction,
} from 'src/store/reducer';
import type { Country } from 'src/types/globals';

export const SET_CANONICAL_URL = 'SET_CANONICAL_URL';
export const SET_ALTERNATE_LINKS = 'SET_ALTERNATE_LINKS';

type AlternateLinks = ReturnType<typeof generateAlternateLinks>;
export interface SeoState {
  alternateLinks?: AlternateLinks;
  canonicalUrl: string | null;
  partners: {
    canCrossLink: boolean;
  };
}

export const initialState: SeoState = {
  canonicalUrl: null,
  partners: {
    canCrossLink: false,
  },
};

export type Action =
  | {
      type: typeof SET_CANONICAL_URL;
      payload: { canonical: string };
    }
  | {
      type: typeof SET_ALTERNATE_LINKS;
      payload: { alternateLinks: SeoState['alternateLinks'] };
    };

const reducer = (
  state: SeoState = initialState,
  action: TypedAction,
): SeoState => {
  switch (action.type) {
    case SET_CANONICAL_URL: {
      const { canonical: canonicalUrl } = action.payload;

      return { ...state, canonicalUrl };
    }

    case SET_ALTERNATE_LINKS: {
      const { alternateLinks } = action.payload;

      return { ...state, alternateLinks };
    }

    default:
      return state;
  }
};

export const setAlternateLinks =
  ({
    country,
    path,
    query,
  }: {
    country: Country;
    path: string;
    query: UrlLocation['query'];
  }): TypedThunkAction =>
  (dispatch, getState) => {
    const {
      results: resultsStore,
      appConfig: { site },
    } = getState();
    const { location, canonicalCompany, companySuggestion } = resultsStore;

    const alternateLinks = generateAlternateLinks({
      country: country.toLocaleLowerCase() as MelwaysCountry,
      path,
      query: query || {},
      location,
      searchMeta: { canonicalCompany, companySuggestion },
      site,
    });

    dispatch({
      type: SET_ALTERNATE_LINKS,
      payload: {
        alternateLinks,
      },
    });
  };

export const setCanonicalUrl =
  ({
    country,
    path,
    query,
  }: {
    country: Country;
    path: string;
    query: UrlLocation['query'];
  }): TypedThunkAction =>
  (dispatch, getState) => {
    const { results: resultsStore } = getState();
    const { location, canonicalCompany, companySuggestion } = resultsStore;

    const canonicalisation = canonicaliseUrl({
      country: country.toLocaleLowerCase() as MelwaysCountry,
      path,
      query: query || {},
      location,
      searchMeta: { canonicalCompany, companySuggestion },
    });

    dispatch({
      type: SET_CANONICAL_URL,
      payload: canonicalisation,
    });
  };

export const selectCanonicalUrl = (state: ChaliceStore) =>
  state.seo.canonicalUrl;

export const selectAlternateLinks = (state: ChaliceStore) =>
  state.seo?.alternateLinks;

export default reducer;
