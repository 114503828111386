import type { SearchParams } from '@seek/chalice-types';
// @ts-expect-error non-ts file
import shallowCopy from 'shallow-copy';
// @ts-expect-error non-ts file
import shallowDiff from 'shallow-diff';

import type { Counts } from 'src/store/search/search';

import onlyClassificationsChanged from './classification-counts-need-updating/only-classifications-changed';
import type { Diff } from './types';

const onlyWorkTypeChanged = (diff: Diff) =>
  diff.updated.length === 1 && diff.updated[0] === 'worktype';

const filterCounts = (
  newParams: SearchParams,
  newCounts?: Counts,
  lastParams?: SearchParams,
  lastCounts?: Counts,
) => {
  if (!newCounts && !lastCounts) {
    return undefined;
  }

  if (newCounts) {
    return newCounts;
  }

  const calculatedCounts: Counts = {};
  const diff = shallowDiff(lastParams, newParams);

  if (onlyWorkTypeChanged(diff)) {
    calculatedCounts.worktype = shallowCopy(lastCounts!.worktype);
  } else if (onlyClassificationsChanged(diff)) {
    calculatedCounts.classification = shallowCopy(lastCounts!.classification);
  }

  return calculatedCounts;
};

export default filterCounts;
