import type { SearchParams } from '@seek/chalice-types';
// @ts-expect-error: non-ts file
import { decode as decodeRecentSearchesCookie } from '@seek/last-search-cookie';
import omit from 'lodash/omit';

import type { Country } from 'src/types/globals';

import type { ChaliceStore, TypedAction, TypedThunkAction } from '../reducer';

export const RECENT_SEARCHES_UPDATED = 'RECENT_SEARCHES_UPDATED';

export interface RecentSearchesState {
  searches?: SearchQuerySummary[];
}

export const initialState: RecentSearchesState = {
  searches: [],
};

export interface SearchQuerySummary {
  query: SearchParams;
  summary: string;
  date: Date;
  where: string;
}

export interface RecentSearchesUpdatedAction {
  type: typeof RECENT_SEARCHES_UPDATED;
  payload?: SearchQuerySummary[];
}

export type Action = RecentSearchesUpdatedAction;

export default function reducer(
  state: RecentSearchesState = initialState,
  action: TypedAction,
) {
  switch (action.type) {
    case RECENT_SEARCHES_UPDATED: {
      const searches = action.payload;
      return {
        ...state,
        searches,
      };
    }

    default: {
      return state;
    }
  }
}

interface DecodedRecentSearchesCookie {
  query: SearchParams;
  summary: string;
  date: Date;
  searches: SearchQuerySummary[];
}

interface GetRecentSearchesArgs {
  cookies: Record<string, string>;
  country: Country;
}

const searchParamsToOmit = ['page', 'whereid', 'nation', 'location', 'area'];

export const getRecentSearches =
  ({ cookies, country }: GetRecentSearchesArgs): TypedThunkAction =>
  (dispatch) => {
    if (!cookies.main) {
      return null;
    }

    const decodedCookie: DecodedRecentSearchesCookie | undefined =
      decodeRecentSearchesCookie(cookies.main, country);
    if (!decodedCookie) {
      return null;
    }

    const { searches = [] } = decodedCookie;
    const cleanedSearches = searches.map((search) => {
      const cleanedQuery = omit(search.query, searchParamsToOmit);
      return {
        ...search,
        query: cleanedQuery,
      } as SearchQuerySummary;
    });

    dispatch({
      type: RECENT_SEARCHES_UPDATED,
      payload: cleanedSearches,
    });
  };

export const selectRecentSearches = (state: ChaliceStore) =>
  state.recentSearches.searches;
