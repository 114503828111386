import type { SearchResultJob } from '@seek/chalice-types';
import { useMemo } from 'react';

import { useAppConfig } from 'src/config/appConfig';
import { useSelector } from 'src/store/react';
import { selectResults } from 'src/store/selectors';

export const hasExternalJobs = (
  jobs: SearchResultJob[] = [],
  currentCountryCode: string,
): boolean => {
  if (!Array.isArray(jobs)) {
    return false;
  }

  return (
    jobs.findIndex(
      (job) =>
        /**
         * only check jobs that have `jobLocation` prop,
         * make it backward compatible for jobs without `jobLocation` prop
         */
        job.jobLocation &&
        job.jobLocation.countryCode.toLowerCase() !==
          currentCountryCode.toLowerCase(),
    ) > -1
  );
};

/**
 * `useSERPHasExternalJobs` checks if the Search Results page has any external
 * jobs listed.
 *
 * @returns boolean
 */
export const useSERPHasExternalJobs = () => {
  const jobs = useSelector(selectResults)?.jobs;
  const { country } = useAppConfig();
  return useMemo(() => hasExternalJobs(jobs ?? [], country), [country, jobs]);
};
