import { createContext, useContext, useEffect, useState } from 'react';

import { useAppConfig } from 'src/config/appConfig';
import { useSelector } from 'src/store/react';
import { selectPathname, selectSearchQuery } from 'src/store/selectors';

import { getRefineSearchQuery } from '../../utils/utils';

const SecondaryFilterContext = createContext<
  ReturnType<typeof useSecondaryFilter>
>({} as ReturnType<typeof useSecondaryFilter>);

export const SecondaryFilterContextProvider = ({
  children,
}: {
  children: JSX.Element;
}) => {
  const secondaryFilterContextValue = useSecondaryFilter();

  return (
    <SecondaryFilterContext.Provider value={secondaryFilterContextValue}>
      {children}
    </SecondaryFilterContext.Provider>
  );
};

export const useSecondaryFilterContext = () =>
  useContext(SecondaryFilterContext);

const useSecondaryFilter = () => {
  const { country, locale, zone, language } = useAppConfig();
  const searchQuery = getRefineSearchQuery(useSelector(selectSearchQuery));
  const [filterSelection, setFilterSelection] = useState(searchQuery);
  const [selectedFilterCount, setSelectedFilterCount] = useState(0);
  const [activeDropdownField, setActiveDropdownField] = useState<string | null>(
    null,
  );
  const pathName = useSelector(selectPathname);
  const [currentPathName, setCurrentPathName] = useState(pathName);

  const isOpenSearch = Object.keys(filterSelection).length === 0;

  const [isOpen, setIsOpen] = useState(false);

  // Early experiment stage will opt out from SEO for secondary filters and dynamic pills.
  const ROBOTS_TAG = `nofollow`;

  useEffect(() => {
    // When candidate were to switch between homepage and serp and vice versa,
    // filterSelection should be updated against the searchQuery to reflect
    // the current search params.
    if (pathName !== currentPathName) {
      setFilterSelection(searchQuery);
      setCurrentPathName(pathName);
    }
  }, [currentPathName, pathName, searchQuery]);

  return {
    country,
    locale,
    zone,
    language,
    isOpenSearch,
    searchQuery,
    selectedFilterCount,
    setSelectedFilterCount,
    activeDropdownField,
    setActiveDropdownField,
    filterSelection,
    setFilterSelection,
    ROBOTS_TAG,
    isOpen,
    setIsOpen,
  };
};
