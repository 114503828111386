import type {
  SearchParams,
  SearchResultJob,
  SearchResultLocation,
} from '@seek/chalice-types';
import assign from 'lodash/assign';
import omit from 'lodash/omit';
// @ts-expect-error non-ts file
import { toWords } from 'spelled-number';

import type {
  JobViewModel,
  JobViewModelClassification,
  JobViewModelItemPartial,
  JobViewModelLocation,
  JobViewModelLocationPartial,
} from 'src/types/JobViewModel';

const MINUTE = 60000; // 60 * 1000
const MINUTES = MINUTE;
const HOUR = 60 * MINUTES;
const HOURS = HOUR;
const DAY = 24 * HOURS;
const DAYS = DAY;

const minutes = (amount: number) => amount * MINUTES;

const hours = (amount: number) => amount * HOURS;

const days = (amount: number) => amount * DAYS;

// Picks the right mix of params from the current page (query)
// and the current job.
const pickSearchParams = (job: SearchResultJob, query: SearchParams) => {
  const result = omit(query, 'page'); // Always refine the to first page

  // Use the job's company name and id over the query, because
  // our follow / nofollow logic depends on these.
  if (
    query.companyname &&
    job.companyName &&
    job.companyProfileStructuredDataId
  ) {
    result.companyname = job.companyName;
    result.companyid = `${job.companyProfileStructuredDataId}`;
  }

  return result;
};

const createLocationObject = (
  job: SearchResultJob,
  query: SearchParams,
  locationField: keyof JobViewModelLocation,
) => {
  const name = job[locationField] || '';
  const result: JobViewModelLocationPartial = {
    name,
    title: '',
    params: {
      where: '',
    },
  };
  const locationWhereValueField = locationField.concat(
    'WhereValue',
  ) as keyof Pick<
    SearchResultJob,
    'areaWhereValue' | 'suburbWhereValue' | 'locationWhereValue'
  >;

  if (job[locationWhereValueField]) {
    const parentLocation =
      locationField === 'area' ? ` in ${job.location}` : '';
    const whereid = job[`${locationField}Id`];
    result.title = `Limit results to ${name}${parentLocation}`;
    result.params = assign(
      pickSearchParams(job, query),
      {
        where: job[locationWhereValueField] || '',
      },
      whereid && { whereid },
    );
  }

  return result;
};

const getLocation = (
  job: SearchResultJob,
  query: SearchParams,
): JobViewModelLocation | null => {
  if (job.suburb && job.suburb !== 'none') {
    return {
      suburb: createLocationObject(job, query, 'suburb'),
    };
  }

  if (!job.location) {
    return null;
  }

  const locationObj = createLocationObject(job, query, 'location');

  if (!job.area) {
    return {
      location: locationObj,
    };
  }

  return {
    location: locationObj,
    area: createLocationObject(job, query, 'area'),
  };
};

const getUnifiedLocation = (job: SearchResultJob, query: SearchParams) => {
  const { jobLocation } = job;
  if (!jobLocation || !jobLocation.seoHierarchy) {
    return null;
  }

  // temp use before the seoHierarchy.label exist
  const getNameFromConcatenatedLabel = (index: number) => {
    const locations = jobLocation.label.split(', ');
    const seoLength = jobLocation.seoHierarchy.length;
    const isLengthMatch = locations.length === seoLength;

    if (!isLengthMatch && index === seoLength - 1) {
      return locations.filter((part, i) => i >= seoLength - 1).join(', ');
    }
    return locations[index];
  };

  const unifiedLocationObj = jobLocation.seoHierarchy
    .map((item, index) => {
      const name = item.label ?? getNameFromConcatenatedLabel(index);
      return name
        ? {
            name,
            title: `Limit results to ${name}`,
            params: assign(pickSearchParams(job, query), {
              where: item.contextualName,
            }),
          }
        : null;
    })
    .filter((item) => item !== null);

  return unifiedLocationObj as JobViewModelItemPartial[];
};

const getUnifiedDateDescription = (job: SearchResultJob) => {
  const listingDateDisplay = job.listingDateDisplay;

  if (!listingDateDisplay) {
    return null;
  }

  if (listingDateDisplay === 'just now') {
    return 'Listed just now';
  }

  const timeUnit = listingDateDisplay.split(' ')[0].replace(/[0-9]/g, '');
  const number = Number(listingDateDisplay.replace(/[^0-9]/g, ''));

  if (timeUnit === 'm') {
    return `Listed ${convertToWords(number, 'minute')} ago`;
  }
  if (timeUnit === 'h') {
    return `Listed ${convertToWords(number, 'hour')} ago`;
  }
  if (timeUnit === 'd') {
    return `Listed ${convertToWords(number, 'day')} ago`;
  }
  return `Listed more than ${convertToWords(number, 'day')} ago`;
};

const getClassification = (
  job: SearchResultJob,
  query: SearchParams,
  location?: SearchResultLocation,
): JobViewModelClassification => {
  const classification = job.classification;
  const subClassification = job.subClassification;
  const matchedWhere =
    !location ||
    location.description === 'All Australia' ||
    location.description === 'All New Zealand'
      ? ''
      : location.description;
  const result: Partial<JobViewModelClassification> = {};

  if (classification) {
    // should always exist, but it's safer to guard anyway
    result.classification = {
      name: classification.description || '',
      title: `Limit results to ${classification.description}`,
      params: assign(omit(pickSearchParams(job, query), 'subclassification'), {
        where: matchedWhere,
        classification: classification.id,
      }),
    };
  }

  if (subClassification && subClassification.id) {
    // it is null for graduate jobs (don't ask me why)
    result.subClassification = {
      name: subClassification.description || '',
      title: `Limit results to ${subClassification.description} in ${classification.description}`,
      params: assign(pickSearchParams(job, query), {
        where: matchedWhere,
        classification: classification.id,
        subclassification: subClassification.id,
      }),
    };
  }

  return result as JobViewModelClassification;
};

const diffFromNow = (dateStr: string, nowStr?: string) => {
  const date = new Date(dateStr);
  const now = nowStr ? new Date(nowStr) : new Date();

  return now.getTime() - date.getTime();
};

// Converts:
//     5, 'minute'
// to:
//     'five minutes'
const convertToWords = (number: number, word: string) => {
  const numberAsWords = toWords(number);

  return `${numberAsWords} ${word}${number === 1 ? '' : 's'}`;
};

const getListingDate = (job: SearchResultJob, now?: string) => {
  const diff = diffFromNow(job.listingDate, now);
  let number;

  if (diff < minutes(5.5)) {
    number = 5;

    return {
      shortDescription: `${number}m ago`,
      longDescription: `Listed ${convertToWords(number, 'minute')} ago`,
    };
  }

  if (diff < minutes(59.5)) {
    number = Math.round(diff / MINUTE);

    return {
      shortDescription: `${number}m ago`,
      longDescription: `Listed ${convertToWords(number, 'minute')} ago`,
    };
  }

  if (diff < hours(23.5)) {
    number = Math.round(diff / HOUR);

    return {
      shortDescription: `${number}h ago`,
      longDescription: `Listed ${convertToWords(number, 'hour')} ago`,
    };
  }

  if (diff < days(30.5)) {
    number = Math.round(diff / DAY);

    return {
      shortDescription: `${number}d ago`,
      longDescription: `Listed ${convertToWords(number, 'day')} ago`,
    };
  }

  number = 30;

  return {
    shortDescription: `${number}d+ ago`,
    longDescription: `Listed more than ${convertToWords(number, 'day')} ago`,
  };
};

const getIsFresh = (job: SearchResultJob, now?: string) =>
  diffFromNow(job.listingDate, now) < hours(23.5);

const getBulletPoints = (job: SearchResultJob) => {
  if (job.bulletPoints && job.bulletPoints.length > 0) {
    return job.bulletPoints;
  }
};

export interface JobViewModelParams {
  job: SearchResultJob;
  query: SearchParams;
  location?: SearchResultLocation;
  now?: string;
}
const jobViewModel = (data: JobViewModelParams) => {
  const job = data.job || {};
  const query = data.query || {};
  const location = data.location;
  const now = data.now; // used in tests to mock the current time
  const solMetadataString = job.solMetadata
    ? JSON.stringify(job.solMetadata)
    : job.solMetadata;

  const viewModel: JobViewModel = {
    advertiser: job.advertiser,
    branding: job.branding,
    bulletPoints: getBulletPoints(job),
    classification: getClassification(job, query, location),
    currencyLabel: job.currencyLabel,
    id: job.id,
    isPremium: job.isPremium,
    isStandOut: !job.isPremium && job.isStandOut,
    isFresh: getIsFresh(job, now),
    title: job.title,
    logo: job.logo,
    location: getLocation(job, query),
    locationMatch: job.locationMatch,
    teaser: job.teaser,
    salary: job.salary,
    srcLogo: job.branding?.assets?.logo?.strategies?.serpLogo,
    solMetadataString,
    joraClickTrackingUrl: job.joraClickTrackingUrl,
    joraImpressionTrackingUrl: job.joraImpressionTrackingUrl,
    unifiedLocation: getUnifiedLocation(job, query),
    unifiedListingDate: job.listingDateDisplay,
    unifiedListingDateDescription: getUnifiedDateDescription(job),
    workType: job.workType,
  };

  if (!job.isPremium) {
    const listingDate = getListingDate(job, now);

    viewModel.listingDate = listingDate.shortDescription;
    viewModel.listingDateDescription = listingDate.longDescription;
  }

  return viewModel;
};

export default jobViewModel;
