const addCommas = require('add-commas');
const assign = require('lodash/assign');
const shallowCopy = require('shallow-copy');

function areJobsTiered(jobs) {
  return (
    jobs.filter(function (job) {
      return typeof job.locationMatch !== 'undefined';
    }).length > 0
  );
}

function isPremiumJob(job) {
  return job.isPremium;
}

function isTier1Job(job) {
  return !isPremiumJob(job) && job.locationMatch === 'Exact';
}

function isTier2Job(job) {
  return !isPremiumJob(job) && job.locationMatch === 'Nearby';
}

function isTier3Job(job) {
  return !isPremiumJob(job) && job.locationMatch === 'Area';
}

function getHeader(
  isZeroResults,
  tiersExist,
  tier1Jobs,
  tier2Jobs,
  location,
  suggestionsExist,
) {
  if (!location || !location.description || !location.suburbType) {
    return null;
  }

  if (tiersExist) {
    if (tier1Jobs.length > 0) {
      if (suggestionsExist) {
        return {
          parts: [
            {
              text: 'Showing jobs in ',
            },
            {
              highlighted: true,
              text: location.description,
            },
          ],
        };
      }

      return null;
    }

    if (tier2Jobs.length === 0) {
      return {
        parts: [
          {
            text: 'No results in ',
          },
          {
            highlighted: true,
            text: location.description,
          },
          {
            text: ' or in ',
          },
          {
            highlighted: true,
            text:
              location.suburbType === 'metro'
                ? 'surrounding suburbs'
                : 'surrounding localities',
          },
        ],
      };
    }

    return {
      parts: [
        {
          text: 'No results in ',
        },
        {
          highlighted: true,
          text: location.description,
        },
      ],
    };
  }

  if (!isZeroResults && suggestionsExist) {
    return {
      parts: [
        {
          text: 'Showing jobs in ',
        },
        {
          highlighted: true,
          text: location.description,
        },
      ],
    };
  }

  return null;
}

function getTiers(results, tier1Jobs, tier2Jobs, tier3Jobs, location) {
  const tiers = [
    {
      jobs: tier1Jobs,
    },
    {
      jobs: tier2Jobs,
    },
    {
      jobs: tier3Jobs,
    },
  ];

  if (tiers[1].jobs.length > 0) {
    tiers[1].header = {
      isDivider: tier1Jobs.length > 0,
      parts: [
        {
          text: 'Showing jobs in ',
        },
        {
          highlighted: true,
          text:
            location.suburbType === 'metro'
              ? 'surrounding suburbs'
              : 'surrounding localities',
        },
      ],
    };
  }

  if (tiers[2].jobs.length > 0) {
    tiers[2].header = {
      isDivider: tier1Jobs.length > 0 || tier2Jobs.length > 0,
      parts: [
        {
          text:
            isPremiumJob(results[0]) || isTier3Job(results[0])
              ? 'Showing jobs in '
              : 'We also found the following jobs in ',
        },
        {
          highlighted: true,
          text: location.suburbParentDescription,
        },
      ],
    };
  }

  // Prepend premium jobs to first existing tier
  const premiumJobs = results.filter(isPremiumJob);

  if (tiers[0].jobs.length === 0) {
    if (tiers[1].jobs.length === 0) {
      tiers[2].jobs = premiumJobs.concat(tiers[2].jobs);
    } else {
      tiers[1].jobs = premiumJobs.concat(tiers[1].jobs);
    }
  } else {
    tiers[0].jobs = premiumJobs.concat(tiers[0].jobs);
  }

  return tiers;
}

module.exports = function (params) {
  const page = params.page;
  const results = params.results;
  const location = params.location;
  const totalCount = params.totalCount;
  const suggestions = params.suggestions;
  const searchParams = params.searchParams;

  const isLoading = results === null;
  const isZeroResults = !isLoading && results.length === 0;
  const summary = isLoading
    ? null
    : {
        displayTotalCount: addCommas(totalCount),
        text: `${totalCount === 1 ? 'job' : 'jobs'} found`,
      };
  const tiersExist = !isLoading && !isZeroResults && areJobsTiered(results);
  const jobs = tiersExist || isZeroResults ? null : results;
  const tier1Jobs = tiersExist ? results.filter(isTier1Job) : null;
  const tier2Jobs = tiersExist ? results.filter(isTier2Job) : null;
  const tier3Jobs = tiersExist ? results.filter(isTier3Job) : null;
  const suggestionsExist = suggestions && suggestions.length > 0;
  const header =
    page === '1'
      ? getHeader(
          isZeroResults,
          tiersExist,
          tier1Jobs,
          tier2Jobs,
          location,
          suggestionsExist,
        )
      : null;
  const tiers = tiersExist
    ? getTiers(results, tier1Jobs, tier2Jobs, tier3Jobs, location)
    : null;
  const suggestionsResult =
    suggestionsExist && page === '1'
      ? suggestions.map(function (suggestion) {
          return {
            label: suggestion.description,
            refineParams: assign(shallowCopy(searchParams), {
              where: suggestion.description,
            }),
          };
        })
      : null;
  const hasCompany = Boolean(
    searchParams && (searchParams.companyid || searchParams.companyname),
  );
  const hasHeaderBeforeJobs =
    header ||
    hasCompany ||
    (tiersExist &&
      ((tiers[1].header && !tiers[1].header.isDivider) ||
        (tiers[2].header && !tiers[2].header.isDivider)));

  return {
    isLoading,
    isZeroResults,
    summary,
    jobs,
    header,
    tiers,
    suggestions: suggestionsResult,
    hasHeaderBeforeJobs,
    searchParams,
  };
};
