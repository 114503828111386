import type { SearchParams } from '@seek/chalice-types';
import { getSlugById } from '@seek/seek-jobs-seo';
import size from 'lodash/size';

import type { SearchResultsPageParams } from './types';

const getParamKey = (
  key: keyof SearchResultsPageParams,
  isCompanySearch: boolean,
  worktype: string,
) => {
  if (isCompanySearch && key === 'keywords') {
    return 'company';
  } else if (key === 'where') {
    return 'location';
  } else if (key === 'worktype' && worktype.split(',').length > 1) {
    return 'multiworktype';
  }
  return key;
};

export const appendSpacePrefix = (str: string) => (size(str) ? ` ${str}` : '');
export const toProperCase = (str?: string) => {
  if (!str?.length) {
    return '';
  }
  return str
    .trim()
    .replace('-', ' ')
    .replace(/\b\w/g, (char) => char.toUpperCase());
};

// generate reference key for translation lookup
export const generateTranslationTypeKey = ({
  isCompanySearch,
  searchParams,
  classDescription,
  fallbackKey,
}: {
  isCompanySearch: boolean;
  searchParams?: SearchParams | null;
  classDescription: string;
  fallbackKey: string;
}) => {
  // return default key if empty params
  if (!searchParams || (searchParams?.classification && !classDescription)) {
    return fallbackKey;
  }

  const cleanParams = filterObject(searchParams);
  const sortedKeys = Object.keys(cleanParams)
    .map((key) =>
      getParamKey(
        key as keyof SearchResultsPageParams,
        isCompanySearch,
        searchParams.worktype || '',
      ),
    )
    .sort()
    .filter((item, index, array) => array.indexOf(item) === index)
    .filter(
      (key) =>
        key !== 'companysearch' &&
        key !== 'page' &&
        key !== 'subclassification' &&
        key !== 'sortmode' &&
        key !== 'salaryrange' &&
        key !== 'salarytype' &&
        key !== 'daterange',
    );

  return `${sortedKeys.join('-')}`;
};

export function filterObject<T extends Record<string, any>>(
  obj: T,
): Partial<T> {
  return Object.keys(obj)
    .filter((key) => {
      const value = obj[key];
      if (Array.isArray(value)) {
        return value.length > 0;
      }
      if (typeof value === 'object' && value !== null) {
        return Object.keys(value as object).length > 0;
      }
      return Boolean(value);
    })
    .reduce(
      (accum, key) => ({
        ...accum,
        [key]: obj[key],
      }),
      {},
    );
}

const hasSingleWorkType = (
  worktype?: string | null,
  ignoredWorkType: string | null | undefined = '',
) =>
  worktype &&
  worktype !== ignoredWorkType &&
  worktype.indexOf(',') < 0 &&
  getSlugById('worktype', worktype!);

export const formatWorkType = (
  worktype?: string | null,
  ignoredWorkType: string | null | undefined = '',
): string =>
  hasSingleWorkType(worktype, ignoredWorkType)
    ? getSlugById('worktype', worktype!)
    : '';
