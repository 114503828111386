import {
  Box,
  Heading,
  HiddenVisually,
  Stack,
  Tiles,
} from 'braid-design-system';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { getWorkArrangements } from 'src/modules/refine-job-search';
import type { Option } from 'src/types/globals';

import { useSecondaryFilterContext } from '../../context/SecondaryFilterContext';
import { CheckboxFilter } from '../CheckboxFilter/CheckboxFilter';

export const RemoteOption = () => {
  const { locale, isOpenSearch, setFilterSelection, searchQuery, isOpen } =
    useSecondaryFilterContext();
  const workArrangementList = getWorkArrangements(locale);
  const workArrangements = searchQuery?.workarrangement || '';
  const workArrangementsArray = useMemo(
    () => (workArrangements ? workArrangements.split(',') : []),
    [workArrangements],
  );

  const [activeId, setActiveId] = useState<string[]>(workArrangementsArray);

  const getOptionItem = useCallback(
    (label: string, value: string) => {
      const isActive = activeId.includes(value);
      const workArrangementRefineParam = isActive
        ? activeId.filter((v) => v !== value)
        : [...activeId, value];
      return {
        label,
        value,
        isActive,
        refineParams: {
          ...searchQuery,
          workarrangement: workArrangementRefineParam.join(','),
        },
      };
    },
    [activeId, searchQuery],
  );

  const workArrangementOptions: Option[] = useMemo(
    () =>
      workArrangementList
        ? workArrangementList.map(({ id, label }) => getOptionItem(label, id))
        : [],
    [workArrangementList, getOptionItem],
  );

  const handleWorkArrangementSelection = (value: string) => {
    setActiveId(() => {
      if (activeId.includes(value)) {
        return activeId.filter((v) => v !== value);
      }
      return [...activeId, value];
    });

    setFilterSelection((currentFilterSelection) => ({
      ...currentFilterSelection,
      workarrangement: (activeId.includes(value)
        ? activeId.filter((v) => v !== value)
        : [...activeId, value]
      ).join(','),
    }));
  };

  useEffect(() => {
    if (isOpenSearch) {
      setActiveId([]);
    }

    if (!isOpen) {
      setActiveId(workArrangementsArray);
    }
  }, [isOpenSearch, isOpen, workArrangementsArray]);

  return (
    <Stack space="medium" data={{ automation: 'remoteOption' }}>
      <Heading level="4">{'Remote options'}</Heading>
      <Box
        component="nav"
        role="navigation"
        aria-labelledby="RefineWorkArrangement"
      >
        <HiddenVisually>
          <h1 id="RefineWorkArrangement">{'Choose a remote option'}</h1>
        </HiddenVisually>
        <Tiles columns={[1, 2]} space="medium">
          {workArrangementOptions.map((workArrangement) => (
            <CheckboxFilter
              key={workArrangement.label}
              path="/jobs"
              option={workArrangement}
              handleSelection={handleWorkArrangementSelection}
            />
          ))}
        </Tiles>
      </Box>
    </Stack>
  );
};
