import { selectJobDetailsResult } from '../jobdetails/jobdetails';
import type { ChaliceStore, TypedAction } from '../reducer';
import { selectIsSrp } from '../selectors';

export const GET_LMIS_SUCCESS = 'GET_LMIS_SUCCESS';
export const GET_LMIS_ERROR = 'GET_LMIS_ERROR';
export const RESET_JDP_LMIS = 'RESET_JDP_LMIS';

export enum pageKeys {
  SRP = 'SRP',
  JDP = 'JDP',
}

// Fix me
export type LmisType = 'contextual' | 'generic' | 'fallback';
export interface Lmis {
  content?: string;
  lmisResultType?: LmisType;
  lmisEntity?: string;
  lmisSnippet?: Record<string, string>;
}

export interface LmisState {
  SRP: Lmis;
  JDP: Lmis;
}

interface LmisSuccessAction {
  type: typeof GET_LMIS_SUCCESS;
  payload: {
    content?: string;
    lmisSnippet?: Record<string, string> | null;
    key: string;
  };
}

export type Action =
  | LmisSuccessAction
  | {
      type: typeof RESET_JDP_LMIS;
    }
  | {
      type: typeof GET_LMIS_ERROR;
      payload: { key: string };
    };

const initialItemState = {
  content: '',
  lmisSnippet: undefined,
};

export const initialState = {
  [pageKeys.SRP]: initialItemState,
  [pageKeys.JDP]: initialItemState,
};

export default function reducer(
  state: LmisState = initialState,
  action: TypedAction,
): LmisState {
  switch (action.type) {
    case RESET_JDP_LMIS: {
      return {
        ...state,
        [pageKeys.JDP]: initialItemState,
      };
    }
    case GET_LMIS_SUCCESS: {
      const { key } = action.payload;
      return {
        ...state,
        [key]: action.payload,
      };
    }

    case GET_LMIS_ERROR: {
      const { key } = action.payload;
      return {
        ...state,
        [key]: initialItemState,
      };
    }

    default:
      return state;
  }
}

export const selectLmis = (state: ChaliceStore) => {
  const lmisKey =
    selectIsSrp(state) && !selectJobDetailsResult(state)
      ? pageKeys.SRP
      : pageKeys.JDP;

  return state.lmis[lmisKey];
};
