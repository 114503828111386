import { Box, Tiles, Stack, Heading } from 'braid-design-system';
import { useCallback, useEffect } from 'react';

import { useSecondaryFilterContext } from '../../context/SecondaryFilterContext';

import { SalaryField } from './SalaryField/SalaryField';
import { SalaryFieldDropdown } from './SalaryField/SalaryFieldDropdown/SalaryFieldDropdown';
import { SalaryType } from './SalaryType/SalaryType';
import { useSalaryRange } from './useSalaryRange';

export const SalaryRange = () => {
  const {
    searchQuery,
    isOpen,
    isOpenSearch,
    activeDropdownField,
    setActiveDropdownField,
  } = useSecondaryFilterContext();
  const {
    defaultCurrency,
    defaultSalaryRangeFrom,
    defaultSalaryRangeTo,
    salaryRanges,
    salarySelected,
    setSalarySelected,
    frequency,
    defaultFrequency,
    searchQueryWithoutSalary,
    data,
  } = useSalaryRange();

  const salaryRange = searchQuery?.salaryrange;
  const [salaryFrom, salaryTo] = salaryRange?.split('-') || [
    undefined,
    undefined,
  ];

  const updateDefaultSalaryActiveField = useCallback(() => {
    setSalarySelected({
      salaryFromValue: defaultSalaryRangeFrom?.value,
      salaryToValue: defaultSalaryRangeTo?.value,
    });
  }, [
    setSalarySelected,
    defaultSalaryRangeFrom?.value,
    defaultSalaryRangeTo?.value,
  ]);

  useEffect(() => {
    if (isOpenSearch) {
      updateDefaultSalaryActiveField();
    }

    if (!isOpen) {
      setSalarySelected({
        salaryFromValue: salaryFrom,
        salaryToValue: salaryTo,
      });
    }
  }, [
    defaultSalaryRangeFrom?.value,
    defaultSalaryRangeTo?.value,
    isOpen,
    isOpenSearch,
    salaryFrom,
    salaryTo,
    setSalarySelected,
    updateDefaultSalaryActiveField,
  ]);

  return (
    <>
      <Box
        component="label"
        position="fixed"
        inset={0}
        onClick={() => setActiveDropdownField(null)}
        display={activeDropdownField ? 'block' : 'none'}
      />
      <Stack space="large" data={{ automation: 'salaryRange' }}>
        <Heading level="4">{`Paying (${defaultCurrency.code})`}</Heading>
        <SalaryType
          updateDefaultSalaryActiveField={updateDefaultSalaryActiveField}
          salaryType={frequency}
          defaultFrequency={defaultFrequency}
          searchQueryWithoutSalary={searchQueryWithoutSalary}
          salaryData={data}
        />
        <Tiles columns={2} space="medium">
          {salaryRanges.map((salary, index) => (
            <Stack key={index} space="xxsmall">
              <SalaryField
                salary={salary}
                selectedSalaryLabel={salary.selectedSalaryLabel}
              />
              <SalaryFieldDropdown
                activeDropdownField={activeDropdownField}
                salary={salary}
                salarySelected={salarySelected}
                setSalarySelected={setSalarySelected}
                salaryType={frequency}
              />
            </Stack>
          ))}
        </Tiles>
      </Stack>
    </>
  );
};
