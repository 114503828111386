// @ts-expect-error non-ts file
import addCommas from 'add-commas';

import type { Counts } from 'src/store/search/search';
import type { RefinementType } from 'src/types/globals';

import type { RefinementClassification } from './types';

export default (
  item: RefinementClassification | RefinementType,
  counts?: Counts['classification'],
) => {
  if (counts !== null && typeof counts !== 'undefined') {
    item.count = counts[item.value] || 0;
    item.displayCount = addCommas(item.count);
  }

  return item;
};
