import { isSearchResultsPath } from '@seek/seek-jobs-seo';

import type { GetSavedSearches_viewer_apacSavedSearches } from 'src/modules/graphql/queries/types/GetSavedSearches';
import { canSaveSearch } from 'src/modules/refine-job-search';
import { LOCATION_CHANGED } from 'src/store/location/location';
import { UPDATE_CRITERIA } from 'src/store/search/search';

import type { ChaliceStore, TypedAction } from '../reducer';

const SAVE_SEARCH_SUCCESS = 'SAVE_SEARCH_SUCCESS';

export const SAVE_SEARCH_BEGIN = 'SAVE_SEARCH_BEGIN';
export const SAVE_SEARCH_ERROR = 'SAVE_SEARCH_ERROR';
export const SAVE_SEARCH_EXCEEDED = 'SAVE_SEARCH_EXCEEDED';
export const SAVE_SEARCH_INVALID_LOCATION = 'SAVE_SEARCH_INVALID_LOCATION';
export const SAVE_SEARCH_DUPLICATE = 'SAVE_SEARCH_DUPLICATE';
export const RESET_SAVE_SEARCH = 'RESET_SAVE_SEARCH';

export const JOB_MAIL_BEGIN = 'JOB_MAIL_BEGIN';
export const JOB_MAIL_SUCCESS = 'JOB_MAIL_SUCCESS';
export const JOB_MAIL_ERROR = 'JOB_MAIL_ERROR';

export enum SavedSearchStatus {
  SAVING = 'SAVING',
  SAVED = 'SAVED',
  UNSAVED = 'UNSAVED',
  ERROR = 'ERROR',
  EXCEEDED = 'EXCEEDED',
  INVALID_LOCATION = 'INVALID_LOCATION',
  DUPLICATE = 'DUPLICATE',
  INVALID_EMAIL = 'INVALID_EMAIL',
}

export type SavedSearch = Pick<
  GetSavedSearches_viewer_apacSavedSearches,
  'name' | 'countLabel' | 'query' | 'id'
>;
export interface SaveSearchState {
  emailAddress: string;
  errorMessage: string;
  saveable: boolean;
  status: SavedSearchStatus;
}
export const initialState: SaveSearchState = {
  emailAddress: '',
  errorMessage: '',
  saveable: true,
  status: SavedSearchStatus.UNSAVED,
};

export type JobMailPosition = 'top' | 'bottom';
export type JobMailPositionAnalytics = 'above-results' | 'below-results';
export type JobMailType =
  | 'job-mail-after-page-1'
  | 'job-mail'
  | 'job-mail-top-page-1';

type SaveSearchErrorActionType =
  | typeof SAVE_SEARCH_EXCEEDED
  | typeof SAVE_SEARCH_INVALID_LOCATION
  | typeof SAVE_SEARCH_DUPLICATE
  | typeof SAVE_SEARCH_ERROR;

interface SaveSearchErrorAction {
  type: SaveSearchErrorActionType;
  error: boolean;
  payload: {
    errorMessage: string;
  };
}

interface SaveSearchSuccessAction {
  type: typeof SAVE_SEARCH_SUCCESS;
}

interface ResetSaveSearchAction {
  type: typeof RESET_SAVE_SEARCH;
}

type JobMailErrorStatus =
  | SavedSearchStatus.DUPLICATE
  | SavedSearchStatus.ERROR
  | SavedSearchStatus.EXCEEDED
  | SavedSearchStatus.INVALID_EMAIL
  | SavedSearchStatus.INVALID_LOCATION;

interface JobMailErrorAction {
  type: typeof JOB_MAIL_ERROR;
  status: JobMailErrorStatus;
  errorMessage: string;
  meta: {
    hotjar: string;
  };
}

interface JobMailSuccessAction {
  type: typeof JOB_MAIL_SUCCESS;
  payload: {
    emailAddress: string;
    jobMailPositionName: JobMailPositionAnalytics;
  };
  meta: {
    hotjar: string;
    jobMailType: JobMailType;
  };
}

export type Action =
  | SaveSearchErrorAction
  | SaveSearchSuccessAction
  | JobMailSuccessAction
  | ResetSaveSearchAction
  | JobMailErrorAction
  | {
      type:
        | typeof SAVE_SEARCH_BEGIN
        | typeof SAVE_SEARCH_ERROR
        | typeof JOB_MAIL_BEGIN;
      payload?: any;
      meta?: Record<string, any>;
    };

export default function reducer(
  state = initialState,
  action: TypedAction,
): SaveSearchState {
  switch (action.type) {
    case UPDATE_CRITERIA: {
      const { lastQuery, query } = action.payload;
      const { status } = state;

      const hasSavedSearch = status === SavedSearchStatus.SAVED;
      const saveable = canSaveSearch(lastQuery, query, hasSavedSearch);

      return {
        ...state,
        status: saveable ? SavedSearchStatus.UNSAVED : status,
        saveable,
        errorMessage: '',
      };
    }

    case SAVE_SEARCH_BEGIN: {
      return {
        ...state,
        status: SavedSearchStatus.SAVING,
      };
    }

    case SAVE_SEARCH_SUCCESS: {
      return {
        ...state,
        status: SavedSearchStatus.SAVED,
      };
    }

    case SAVE_SEARCH_ERROR: {
      const { errorMessage } = action.payload || {};

      return {
        ...state,
        errorMessage,
        status: SavedSearchStatus.ERROR,
      };
    }

    case SAVE_SEARCH_EXCEEDED: {
      const { errorMessage } = action.payload || {};

      return {
        ...state,
        errorMessage,
        status: SavedSearchStatus.EXCEEDED,
      };
    }

    case SAVE_SEARCH_INVALID_LOCATION: {
      const { errorMessage } = action.payload || {};

      return {
        ...state,
        errorMessage,
        status: SavedSearchStatus.INVALID_LOCATION,
      };
    }

    case SAVE_SEARCH_DUPLICATE: {
      const { errorMessage } = action.payload || {};

      return {
        ...state,
        errorMessage,
        status: SavedSearchStatus.DUPLICATE,
      };
    }

    case JOB_MAIL_BEGIN: {
      return {
        ...state,
        status: SavedSearchStatus.SAVING,
      };
    }

    case JOB_MAIL_SUCCESS: {
      const { emailAddress } = action.payload;
      return {
        ...state,
        status: SavedSearchStatus.SAVED,
        emailAddress,
      };
    }

    case JOB_MAIL_ERROR: {
      const { status, errorMessage } = action || {};
      return {
        ...state,
        errorMessage,
        status,
      };
    }

    // Resetting status when location changes to non SRP path to ensure ..
    // .. toasts will not keep showing when navigating between pages
    case LOCATION_CHANGED: {
      const {
        location: { pathname },
      } = action.payload;
      const isSRPPath = pathname && isSearchResultsPath(pathname);

      if (!isSRPPath) {
        return {
          ...state,
          status: SavedSearchStatus.UNSAVED,
        };
      }
    }

    case RESET_SAVE_SEARCH: {
      return {
        ...state,
        status: SavedSearchStatus.UNSAVED,
        errorMessage: '',
      };
    }

    default: {
      return state;
    }
  }
}

export const createJobMailErrorAction = (
  status: JobMailErrorStatus,
  errorMessage: string,
): JobMailErrorAction => ({
  type: JOB_MAIL_ERROR,
  status,
  errorMessage,
  meta: {
    hotjar: 'Job Mail Error',
  },
});

export const createSavedSearchErrorAction = (
  type: SaveSearchErrorActionType,
  errorMessage: string,
): SaveSearchErrorAction => ({
  type,
  error: type === SAVE_SEARCH_ERROR,
  payload: { errorMessage },
});

export const saveSearchSuccess = (): SaveSearchSuccessAction => ({
  type: SAVE_SEARCH_SUCCESS,
});

export const resetSaveSearch = (): ResetSaveSearchAction => ({
  type: RESET_SAVE_SEARCH,
});

export const selectSaveable = (state: ChaliceStore) =>
  state.saveSearch.saveable;
export const selectSaveSearchStatus = (state: ChaliceStore) =>
  state.saveSearch.status;
export const selectSaveSearchErrorMessage = (state: ChaliceStore) =>
  state.saveSearch.errorMessage;
export const selectSaveSearchEmailAddress = (state: ChaliceStore) =>
  state.saveSearch.emailAddress;
