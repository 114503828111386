import { ApolloProvider } from '@apollo/client';
import { Hubble, HubbleProvider } from '@seek/hubble';
import { MelwaysProvider } from '@seek/melways-react';
import { metrics } from '@seek/metrics-js';
import { createAnalytics } from '@seek/seek-jobs-analytics';
import { parse } from 'cookie';
import { hydrateRoot } from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';
import { Provider } from 'react-redux';
import { renderRoutes, type RouteConfig } from 'react-router-config';
import { Router } from 'react-router-dom';

import { createLocationChangeHandler } from 'src/client/createLocationChangeHandler';
import {
  appName,
  environment,
  isProduction,
  siteSection,
  solTrackingEndpoint,
  version,
} from 'src/config';
import { AppConfigProviderWrapper } from 'src/config/AppConfigProviderWrapper';
import {
  AnalyticsContext,
  BrowserAnalyticsFacade,
  UniversalAnalyticsFacade,
} from 'src/modules/AnalyticsFacade';
import { setUserAgent } from 'src/modules/chalice-user-agent/isomorphic-useragent';
import { getClient } from 'src/modules/graphql';
import { setHubbleLoginId } from 'src/modules/hubble';
import createStore from 'src/store/createStore';
import { history } from 'src/store/location/location';
import { getSearchParams } from 'src/utils/urlParams';

import { SecondaryFilterContextProvider } from './components/Search/SecondaryFilter/context/SecondaryFilterContext';
import getCountry from './config/utils/getCountryFromZone';
import appRoutesConfig from './routes';
import createTestHeaderApolloLink from './utils/createTestHeaderApolloLink';
import {
  getTestScope,
  isTestAgent,
} from './utils/productionTesting/productionTesting';

const browserHistory = history;

const appConfig = window.SEEK_APP_CONFIG;

const { brand, zone, site, language } = appConfig;

setUserAgent(window.navigator.userAgent);

export default (element: Element | null): void => {
  const dataLayer = window.SK_DL || {};
  const store = createStore(window.SEEK_REDUX_DATA);
  const state = store.getState();
  const country = getCountry(zone);

  const testHeaderApolloLink = createTestHeaderApolloLink(store);
  const apolloClient = getClient({
    brand,
    country,
    links: testHeaderApolloLink ? [testHeaderApolloLink] : [],
    cacheData: window.SEEK_APOLLO_DATA,
    cookies: parse(document.cookie),
    queryParams: getSearchParams(),
  });

  const testHeaders = state.user.testHeaders;
  const isTestUser = isTestAgent(testHeaders);

  const hubble = new Hubble({
    appName,
    appVersion: version,
    brand,
    country,
    isProduction,
    endpoint: `${solTrackingEndpoint}/v1/events`,
    ...(isTestUser
      ? {
          testRecord: 'true',
          testScope: getTestScope(testHeaders),
        }
      : {}),
  });

  const visitorId = hubble.visitorId();

  const analytics = createAnalytics(
    {
      environment,
      useMock: isTestUser,
    },
    {
      ...dataLayer,
      brand,
      siteCountry: country.toLowerCase() ?? '',
      siteLanguage: language,
      siteSection,
      solVisitorId: visitorId,
      zone,
    },
  );

  const analyticsFacade = new BrowserAnalyticsFacade({
    hubble,
    universalFacade: new UniversalAnalyticsFacade({
      seekJobsAnalytics: analytics,
    }),
  });

  hubble.on('queue', (event) => {
    metrics.count(`hubble.event`, [`name:${event.name}`, 'stage:queue']);
  });

  hubble.on('send', (events) => {
    events.forEach((event) => {
      metrics.count(`hubble.event`, [`name:${event.name}`, 'stage:send']);
    });
  });

  hubble.on('success', (events) => {
    events.forEach((event) => {
      metrics.count(`hubble.event`, [`name:${event.name}`, 'stage:success']);
    });
  });

  setHubbleLoginId(hubble, store);

  if (window.SK_DL) {
    delete window.SK_DL;
  }

  metrics.addGlobalTags([
    `logged-in:${state.user.authenticated ? 'true' : 'false'}`,
  ]);

  const onLocationChanged = createLocationChangeHandler({
    analyticsFacade,
    apolloClient,
    store,
    visitorId,
    hubble,
  });

  browserHistory.listen(onLocationChanged);

  function renderApp(appRoutes: RouteConfig[]): void {
    hydrateRoot(
      element!,
      <Provider store={store} serverState={window.SEEK_REDUX_DATA}>
        <AppConfigProviderWrapper config={appConfig}>
          <MelwaysProvider.Client
            site={site}
            alternative={appConfig.consolidated ? 'subDomainRegion' : undefined}
          >
            <AnalyticsContext.Provider value={analyticsFacade}>
              <ApolloProvider client={apolloClient}>
                <HubbleProvider hubble={hubble}>
                  <SecondaryFilterContextProvider>
                    <Router history={browserHistory}>
                      <HelmetProvider>{renderRoutes(appRoutes)}</HelmetProvider>
                    </Router>
                  </SecondaryFilterContextProvider>
                </HubbleProvider>
              </ApolloProvider>
            </AnalyticsContext.Provider>
          </MelwaysProvider.Client>
        </AppConfigProviderWrapper>
      </Provider>,
    );
  }

  onLocationChanged(browserHistory.location);

  renderApp(appRoutesConfig(site));
};
