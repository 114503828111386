import type {
  ApolloQueryResult,
  ApolloClient,
  NormalizedCacheObject,
} from '@apollo/client';

import { GET_USER_COOKIE } from '../graphql/queries/persist';
import type { GetUserCookie } from '../graphql/queries/types/GetUserCookie';

const userCookieName = /last-known-sol-user-id/i;
const cookieNameValue = 'last-known-sol-user-id';
const userCookieTTL = 90;

export const userCookieExists = () => userCookieName.test(document.cookie);

export const persistUserCookie = (
  client: ApolloClient<NormalizedCacheObject>,
) =>
  client
    .query<GetUserCookie>({ query: GET_USER_COOKIE })
    .then((response: ApolloQueryResult<GetUserCookie>) => {
      if (response === null) {
        return;
      }

      const { value } = response.data?.viewer?.userCookie || {
        value: undefined,
      };

      if (!value) {
        return;
      }

      const now = new Date();
      const expires = new Date(
        new Date(now).setDate(now.getDate() + userCookieTTL),
      );
      document.cookie = `${cookieNameValue}=${value}; expires=${expires.toUTCString()}; `;
    });

export const removeUserCookie = () => {
  document.cookie = `${cookieNameValue}=; expires=${new Date().toUTCString()};`;
};
