import { isProduction } from '..';
import type { AppConfig } from '../types';

export default {
  zone: 'asia-4',
  defaultLocale: 'id-ID',
  availableLocales: ['en-ID', 'id-ID'],
  timedBanners: {
    launchBanner: {
      displayDates: {
        startDate: '2024-08-02T12:00:00.000Z', // 02 August 2024, 19:00:00 Local Time
        endDate: '2024-08-16T17:00:00.000Z', // 17 August 2024, 00:00:00 Local Time
      },
      bannerUrl: {
        en: 'https://help.id.jobstreet.com/s/article/Why-has-the-website-address-changed-ID?language=en_US',
        id: 'https://help.id.jobstreet.com/id/s/article/Why-has-the-website-address-changed-ID?language=in',
      },
      oldUrl: 'jobstreet.co.id',
      newUrl: 'id.jobstreet.com',
    },
  },
  zoneFeatures: {
    NORTHSTAR_SEO_TAGS: true,
    NUDGE_ENABLED: false,
    HOMEPAGE_BANNER_TYPE: 'GENERIC_ASIA_BANNER',
    SEARCH_PAGE_SIZE: 30,
    SHOW_FLOATING_SAVE_SEARCH: false,
    AUTO_SELECT_SPLIT_VIEW_FIRST_JOB: false,
    SHOW_MARKETING_AD_SPLIT_VIEW: false,
    LMIS_ENABLED: true,
    BEHAVIOURAL_CUES_ENABLED: false,
    LOGGED_OUT_RECS: false,
    REMOTE_SEARCH_FILTER: true,
    REMOTE_SEARCH_FILTER_NEW_LABEL: true,
    DYNAMIC_PILLS: false,
    SECONDARY_FILTERS: false,
    ENABLE_V5_JOB_SEARCH: false,
    KEYWORD_AUTOSUGGEST_V2: true,
    NEW_JOB_CARD_DENSITY: false,
    ENABLE_VERIFIED_HIRER_BADGE: false,
    SERP_JOBCARD_INFO_DENSITY_1: false,
  },
  BRANCH_IO_KEY: isProduction
    ? 'key_live_keVW4JKlS0jBJLPfSRNUJnhhtElmK541'
    : 'key_test_igQ77GMmQ1itGVIiMGXzojmavAcg9pio',
} as AppConfig;
