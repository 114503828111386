import type { Zone } from '@seek/audience-zones';

import type {
  TExperimentKeys,
  TSeekExperiments,
} from 'src/store/experiments/experimentHelpers';
import type { TypedAction } from 'src/store/reducer';

const EXPERIMENTS_INITIALISED = '__EXPERIMENTS_INITIALISED';

/**
 * See https://myseek.atlassian.net/wiki/spaces/TDA/pages/2056519973/Experimentation
 * for experiment specs.
 */

export type TExperimentNames =
  | 'split_view_anz_6'
  | 'remote_search_filter'
  | 'logged_out_recs'
  | 'dynamic_pills'
  | 'serp_job_card_info_density_1'
  | 'secondary_filters';

export interface TExperiment {
  name: TExperimentNames;
  percentage: number;
  num: number;
  targetZones: Set<Zone>;
  groupSalt?: string;
  enableTrackingForZeroPercent?: boolean;
}

export type TFeatureExperiments = Partial<Record<TExperimentKeys, TExperiment>>;
const REMOTE_SEARCH_FILTER_GROUP_SALT = 'REMOTE_SEARCH_FILTER';
const LOGGED_OUT_RECS_GROUP_SALT = 'LOGGED_OUT_RECS';
const SERP_JOB_CARD_INFO_DENSITY_1_GROUP_SALT = 'SERP_JOB_CARD_INFO_DENSITY_1';
const DYNAMIC_PILLS_AND_SECONDARY_FILTERS_GROUP_SALT =
  'DYNAMIC_PILLS_AND_SECONDARY_FILTERS_GROUP_SALT';

export const FEATURE_EXPERIMENTS: TFeatureExperiments = {
  remoteSearchFilter: {
    name: 'remote_search_filter',
    percentage: 0, // 0:0:0 => control/variant/outsider
    enableTrackingForZeroPercent: true,
    num: 4,
    targetZones: new Set<Zone>([
      'anz-1',
      'anz-2',
      'asia-1',
      'asia-3',
      'asia-4',
      'asia-5',
      'asia-6',
      'asia-7',
    ]),
    groupSalt: REMOTE_SEARCH_FILTER_GROUP_SALT,
  },
  loggedOutRecs: {
    name: 'logged_out_recs',
    percentage: 100, // 50:50:0 => control/variant/outsider
    enableTrackingForZeroPercent: true,
    num: 4,
    targetZones: new Set<Zone>([
      'anz-1',
      'anz-2',
      'asia-1',
      'asia-3',
      'asia-4',
      'asia-5',
      'asia-6',
      'asia-7',
    ]),
    groupSalt: LOGGED_OUT_RECS_GROUP_SALT,
  },
  secondaryFilters: {
    name: 'secondary_filters',
    /*
      Note: The control split must be strictly lower than dynamic pills experiment to ensure dynamic pills is only
      enabled when secondary filters is enabled.
      See more information same group splitting here: https://myseek.atlassian.net/wiki/spaces/AJDT/pages/2669056503/AB+Test+-+Group+Splitting
      Also note that to ensure group splits are consistent across both experiment, the same salt must be used (DYNAMIC_PILLS_AND_SECONDARY_FILTERS_GROUP_SALT).
    */
    percentage: 0, // 0:0:0 => control/variant/outsider.
    enableTrackingForZeroPercent: true,
    num: 5,
    targetZones: new Set<Zone>([
      'anz-1',
      'anz-2',
      'asia-5',
      'asia-6',
      'asia-7',
    ]),
    groupSalt: DYNAMIC_PILLS_AND_SECONDARY_FILTERS_GROUP_SALT,
  },
  dynamicPills: {
    name: 'dynamic_pills',
    /*
      Note: The control split must be strictly higher than dynamic pills experiment to ensure dynamic pills is only
      enabled when secondary filters is enabled.
      See more information same group splitting here: https://myseek.atlassian.net/wiki/spaces/AJDT/pages/2669056503/AB+Test+-+Group+Splitting
      Also note that to ensure group splits are consistent across both experiment, the same salt must be used (DYNAMIC_PILLS_AND_SECONDARY_FILTERS_GROUP_SALT).
    */
    percentage: 0, // 0:0:0 => control/variant/outsider
    enableTrackingForZeroPercent: true,
    num: 6,
    targetZones: new Set<Zone>([
      'anz-1',
      'anz-2',
      'asia-1',
      'asia-3',
      'asia-4',
      'asia-5',
      'asia-6',
      'asia-7',
    ]),
    groupSalt: DYNAMIC_PILLS_AND_SECONDARY_FILTERS_GROUP_SALT,
  },
  // This hide job card summary(teaser)
  serpJobCardInfoDensity1: {
    name: 'serp_job_card_info_density_1',
    percentage: 0, // 0:0:0 => control/variant/outsider
    enableTrackingForZeroPercent: true,
    num: 60,
    targetZones: new Set<Zone>([
      'anz-1',
      'anz-2',
      'asia-1',
      'asia-3',
      'asia-4',
      'asia-5',
      'asia-6',
      'asia-7',
    ]),
    groupSalt: SERP_JOB_CARD_INFO_DENSITY_1_GROUP_SALT,
  },
};

export interface UpdateExperimentsAction {
  type: typeof EXPERIMENTS_INITIALISED;
  payload: TSeekExperiments;
}

export type ExperimentsState = TSeekExperiments;

export const initialState: ExperimentsState = {};

export const experimentsIntialised = (
  experiments: TSeekExperiments,
): UpdateExperimentsAction => ({
  type: EXPERIMENTS_INITIALISED,
  payload: experiments,
});

export const reducer = (state = {}, action: TypedAction) => {
  switch (action.type) {
    case EXPERIMENTS_INITIALISED: {
      return {
        ...state,
        ...action.payload,
      };
    }

    default:
      return state;
  }
};
