import type { GetSavedJobs_viewer_savedJobs } from '../queries/types/GetSavedJobs';

export const mockSavedJobs: GetSavedJobs_viewer_savedJobs = {
  __typename: 'SavedJobConnection',
  edges: [
    {
      __typename: 'SavedJobEdge',
      node: {
        __typename: 'SavedJob',
        id: '55779737',
        isActive: true,
        job: {
          __typename: 'Job',
          id: '55779737',
          title: 'UI Designer',
          advertiser: {
            __typename: 'Advertiser',
            name: 'Talent – SEEK Recruitment Agency of the Year | 7 Time Winner',
          },
        },
      },
    },
    {
      __typename: 'SavedJobEdge',
      node: {
        __typename: 'SavedJob',
        id: '55748783',
        isActive: false,
        job: {
          __typename: 'Job',
          id: '55748783',
          title: 'Administration / Operations',
          advertiser: {
            __typename: 'Advertiser',
            name: 'Simons Boiler Co',
          },
        },
      },
    },
    {
      __typename: 'SavedJobEdge',
      node: {
        __typename: 'SavedJob',
        id: '55570316',
        isActive: true,
        job: {
          __typename: 'Job',
          id: '55570316',
          title: 'Distribution Centre Manager - Truganina',
          advertiser: {
            __typename: 'Advertiser',
            name: 'Inghams Enterprises Pty Ltd',
          },
        },
      },
    },
  ],
  total: 33,
};
