// NOTE: This file should be exported to seek-jobs-seo
import type { SearchUrl, UrlLocation } from '@seek/chalice-types';
import type { CountrySiteName } from '@seek/melways-sites';
import {
  isQualifiedUrlNofollow,
  qualifyUrl,
  isLocalHoustonLocation as shouldQualifyLocation,
} from '@seek/seek-jobs-seo';

import type { LocationState } from 'src/store/location/location';

function toSeoLocation(location: UrlLocation): SearchUrl {
  return {
    path: location.pathname || '',
    query: location.query || {},
  };
}

function fromSeoLocation({ path, query }: SearchUrl) {
  return {
    pathname: path,
    query,
  };
}

export function qualifyLocation(
  location: UrlLocation,
  currentLocation: LocationState,
  site: CountrySiteName,
) {
  const { path, query } = toSeoLocation(location);
  return shouldQualifyLocation({
    location,
    localLocation: currentLocation,
    site,
  })
    ? {
        ...location,
        ...fromSeoLocation(qualifyUrl({ path, query })),
      }
    : location;
}

export function isQualifiedLocationNoFollow(
  location: UrlLocation,
  currentLocation: LocationState,
  site: CountrySiteName,
) {
  return (
    shouldQualifyLocation({ location, localLocation: currentLocation, site }) &&
    isQualifiedUrlNofollow(toSeoLocation(location))
  );
}
