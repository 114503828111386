import gql from 'graphql-tag';

export const GET_USER_COOKIE = gql`
  query GetUserCookie {
    viewer {
      id
      userCookie {
        key
        value
      }
    }
  }
`;
