import type { SearchParams } from '@seek/chalice-types';
// @ts-expect-error non-ts file
import copy from 'shallow-copy';

import type { Classifications } from 'src/config/types';
import type { Counts } from 'src/store/search/search';

import addCounts from '../lib/add-counts';
import { includes, toggle, remove } from '../refinement-types/checklist';

const refineClassifications = (
  classifications: Classifications,
  params: SearchParams,
  allCounts?: Counts,
) => {
  const counts =
    allCounts && allCounts.classification
      ? allCounts.classification
      : undefined;

  return {
    classification: classifications.map((classification) => {
      const newClassification = addCounts(
        {
          label: classification.description,
          value: String(classification.id),
        },
        counts,
      );

      const subClassValues = classification.subClassifications.map(
        (subClassification) => String(subClassification.id),
      );

      newClassification.isActive = includes(
        params,
        'classification',
        newClassification.value,
      );

      newClassification.refineParams = copy(params);
      if (newClassification.isActive) {
        subClassValues.forEach((value) => {
          newClassification.refineParams = remove(
            newClassification.refineParams!,
            'subclassification',
            value,
          );
        });
      }
      newClassification.refineParams = toggle(
        newClassification.refineParams!,
        'classification',
        newClassification.value,
      );

      if (newClassification.isActive) {
        const subClassificationValues = classification.subClassifications.map(
          (subClass) => String(subClass.id),
        );

        const newSubClassifications = classification.subClassifications.map(
          (subClass) => {
            const newSubClassification = addCounts(
              {
                type: 'subclassification',
                label: subClass.description,
                value: String(subClass.id),
              },
              counts,
            );

            newSubClassification.isActive = includes(
              params,
              'subclassification',
              newSubClassification.value,
            );

            newSubClassification.refineParams = copy(params);
            newSubClassification.refineParams = toggle(
              newSubClassification.refineParams!,
              'subclassification',
              newSubClassification.value,
            );

            return newSubClassification;
          },
        );

        const hasSelectedSubClass = newSubClassifications.some(
          (subClass) => subClass.isActive,
        );

        const allClassificationRefineParams = remove(
          params,
          'subclassification',
          subClassificationValues,
        );

        const allClassification = addCounts(
          {
            type: 'all',
            value: newClassification.value,
            label: newClassification.label,
            isActive: !hasSelectedSubClass,
            refineParams: hasSelectedSubClass
              ? allClassificationRefineParams
              : remove(
                  allClassificationRefineParams,
                  'classification',
                  newClassification.value,
                ),
          },
          counts,
        );

        newClassification.children = [allClassification].concat(
          newSubClassifications,
        );

        newClassification.isCheckboxActive =
          newClassification.isActive || hasSelectedSubClass;
      }

      // Don't show counts for expanded parent classifications
      if (newClassification.isActive) {
        delete newClassification.displayCount;
      }

      return newClassification;
    }),
  };
};

export default refineClassifications;
