import {
  Stack,
  HiddenVisually,
  Heading,
  Tiles,
  RadioGroup,
  RadioItem,
  useResponsiveValue,
} from 'braid-design-system';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { Link } from 'src/components/NavLink/NavLink';
import { getDateRanges } from 'src/modules/refine-job-search';
import type { Option } from 'src/types/globals';

import { useSecondaryFilterContext } from '../../context/SecondaryFilterContext';

export const ListingTime = () => {
  const {
    zone,
    language,
    isOpenSearch,
    searchQuery,
    setFilterSelection,
    ROBOTS_TAG,
    isOpen,
  } = useSecondaryFilterContext();
  const dates = getDateRanges(zone, language);
  const dateListed = searchQuery?.daterange || '';
  const [activeValue, setActiveValue] = useState<string>(dateListed);
  const isMobile = useResponsiveValue()({
    mobile: true,
    tablet: false,
  });

  // As date listing, "Any time" label has a value of an empty string, this serves
  // as a placeholder value due to Braid's RadioItem's value property unable to
  // take an empty string or undefined value.
  const DEFAULT_LISTING_TIME = '0';

  const getOptionItem = useCallback(
    (label: string, value: string) => {
      const isActive = activeValue === value;
      return {
        label,
        value,
        isActive,
        ...(!isActive && {
          refineParams: {
            ...searchQuery,
            daterange: value,
          },
        }),
      };
    },
    [activeValue, searchQuery],
  );

  const dateListedOptions: Option[] = useMemo(
    () =>
      dates ? dates.map(({ label, value }) => getOptionItem(label, value)) : [],
    [dates, getOptionItem],
  );

  const handleListingTimeSelection = (value: string) => {
    setActiveValue(value);
    setFilterSelection((currentFilterSelection) => ({
      ...currentFilterSelection,
      daterange: value === DEFAULT_LISTING_TIME ? '' : value,
    }));
  };

  useEffect(() => {
    if (!isOpen || isOpenSearch) {
      setActiveValue(DEFAULT_LISTING_TIME);
    }
  }, [isOpen, isOpenSearch]);

  return (
    <Stack space="medium" data={{ automation: 'listingTime' }}>
      <Heading level="4">{'Listing time'}</Heading>
      <nav role="navigation" aria-labelledby="RefineDateListed">
        <HiddenVisually>
          <h1 id="RefineDateListed">{'Date Listed'}</h1>
        </HiddenVisually>
        <Tiles columns={{ mobile: 1, tablet: 2 }} space="medium">
          <RadioGroup
            id="listingTime"
            onChange={({ currentTarget: { value } }) =>
              handleListingTimeSelection(value)
            }
            value={activeValue || dateListedOptions[0].value}
            label=""
            size={isMobile ? 'small' : 'standard'}
          >
            {dateListedOptions
              .filter((_, index) => (isMobile ? true : index % 2 === 0))
              .map((option) => (
                <RadioItem
                  key={option.value}
                  label={
                    <Link
                      location={{
                        pathname: '/jobs',
                        query: option.refineParams,
                      }}
                      rel={ROBOTS_TAG}
                      disabledLinkNavigate
                    >
                      {option.label}
                    </Link>
                  }
                  value={option.value || DEFAULT_LISTING_TIME}
                />
              ))}
          </RadioGroup>

          {!isMobile ? (
            <RadioGroup
              id="listingTime2"
              onChange={({ currentTarget: { value } }) =>
                handleListingTimeSelection(value)
              }
              value={activeValue || dateListedOptions[0].value}
              label=""
              size={isMobile ? 'small' : 'standard'}
            >
              {dateListedOptions
                .filter((_, index) => (isMobile ? true : index % 2 !== 0))
                .map((option) => (
                  <RadioItem
                    key={option.value}
                    label={
                      <Link
                        location={{
                          pathname: '/jobs',
                          query: option.refineParams,
                        }}
                        rel={ROBOTS_TAG}
                        disabledLinkNavigate
                      >
                        {option.label}
                      </Link>
                    }
                    value={option.value || DEFAULT_LISTING_TIME}
                  />
                ))}
            </RadioGroup>
          ) : null}
        </Tiles>
      </nav>
    </Stack>
  );
};
