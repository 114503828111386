import { createAuthenticatedHttpClient } from 'src/modules/seek-api-request';
import type createStore from 'src/store/createStore';
import {
  updateEventCaptureTestTags,
  updateTestHeaders,
} from 'src/store/user/user';

import { getTestTags } from '../../../utils/eventCapture/eventCaptureUtils';

export const isAuthenticated = async () => {
  try {
    return (await createAuthenticatedHttpClient().getToken()).authenticated;
  } catch (_: unknown) {
    return false;
  }
};

export const updateUserTestData = async (
  store: ReturnType<typeof createStore>,
  authenticated: boolean,
  hostname: string | undefined,
) => {
  const { dispatch } = store;

  try {
    if (authenticated && hostname) {
      // Authenticated test traffic

      const userTestData =
        await createAuthenticatedHttpClient().getTestUserContext(hostname);

      if (userTestData && userTestData.testRecord) {
        const testHeaders = {
          'user-test-record': String(userTestData.testRecord),
          ...(userTestData.testScope
            ? { 'user-test-scope': userTestData.testScope }
            : {}),
        };

        const testTags = getTestTags(testHeaders);

        return Promise.all([
          dispatch(updateTestHeaders(testHeaders)),
          dispatch(updateEventCaptureTestTags(testTags)),
        ]);
      }
    } else if (window?.SEEK_TEST) {
      // Unauthenticated test traffic

      const testHeaders = {
        'seek-test': 'true',
        'seek-test-scope': '',
      };

      return Promise.all([
        dispatch(updateTestHeaders(testHeaders)),
        dispatch(updateEventCaptureTestTags(getTestTags(testHeaders))),
      ]);
    }

    return Promise.all([
      dispatch(updateTestHeaders({})),
      dispatch(updateEventCaptureTestTags()),
    ]);
  } catch (e) {
    return Promise.all([
      dispatch(updateTestHeaders({})),
      dispatch(updateEventCaptureTestTags()),
    ]);
  }
};
