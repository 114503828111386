import {
  Box,
  Stack,
  PageBlock,
  Columns,
  Column,
  Button,
  IconFilter,
  useResponsiveValue,
} from 'braid-design-system';
import { renderRoutes } from 'react-router-config';
// @ts-expect-error: non-ts file
import { provideHooks } from 'redial';

import type { TSeekExperiments } from 'src/store/experiments/experimentHelpers';
import { useDispatch, useSelector } from 'src/store/react';
import {
  expandSearchFilters,
  getCounts,
  updateCriteria,
} from 'src/store/search/search';
import {
  selectFeatureFlag,
  selectIsFiltersExpanded,
  selectIsHomePage,
} from 'src/store/selectors';
import type { RedialLocals } from 'src/types/RedialLocals';

import { DynamicPill } from './DynamicPill/DynamicPill.tsx';
import RefineBar from './RefineBar/RefineBar';
import SearchBar from './SearchBar/SearchBar';
import { SecondaryFilter } from './SecondaryFilter/SecondaryFilter';
import { useSecondaryFilterContext } from './SecondaryFilter/context/SecondaryFilterContext';

import * as styles from './Search.css';

const hooks = {
  first({ dispatch, path = '', query, zone }: RedialLocals) {
    return dispatch(
      updateCriteria({
        path,
        query,
        zone,
      }),
    );
  },
  defer({ dispatch, country, path = '', query, cookies, zone }: RedialLocals) {
    return dispatch(
      getCounts({
        zone,
        country,
        path,
        query,
        cookies,
      }),
    );
  },
};

export interface SearchProps {
  route: Record<string, any>;
  experiments?: TSeekExperiments;
}

const Search = ({ route }: SearchProps) => {
  const filtersExpanded = useSelector(selectIsFiltersExpanded);
  const dispatch = useDispatch();
  const showSecondaryFilters = useSelector(
    selectFeatureFlag('secondaryFilters'),
  );
  const showDynamicPills = useSelector(selectFeatureFlag('dynamicPills'));

  const isHomePage = useSelector(selectIsHomePage);
  const isMobile =
    useResponsiveValue()({
      mobile: true,
      tablet: false,
    }) || false;

  const { setIsOpen } = useSecondaryFilterContext();

  return (
    <Box height="full">
      <Box
        position="relative"
        // This is to ensure this will not cover Header's language switcher
        zIndex={2}
        background="brand"
        paddingBottom={{
          mobile: 'medium',
          tablet: 'small',
        }}
        className={{
          [styles.branding]: !showSecondaryFilters,
          [styles.searchBarPadding]: !showSecondaryFilters,
          [styles.secondaryFilterPadding]: showSecondaryFilters,
        }}
      >
        <PageBlock width={showSecondaryFilters ? 'large' : 'medium'}>
          <Stack
            space={{
              mobile:
                !showSecondaryFilters && filtersExpanded ? 'xsmall' : 'none',
              tablet: showSecondaryFilters ? 'medium' : 'xsmall',
            }}
          >
            {/* Mobile web*/}
            {showSecondaryFilters && isMobile && (
              <>
                <Columns space={isHomePage ? 'none' : 'xsmall'} reverse>
                  <Column width="content">
                    <Box display={!isHomePage ? 'block' : 'none'}>
                      <Box
                        boxShadow="borderField"
                        borderRadius="standard"
                        padding="xsmall"
                        onClick={() => setIsOpen(true)}
                      >
                        <IconFilter />
                      </Box>
                    </Box>
                  </Column>
                  <Column>
                    <SearchBar collapsed={!isHomePage && filtersExpanded} />
                  </Column>
                </Columns>
                {showDynamicPills && (
                  <Box
                    paddingTop="small"
                    display={!isHomePage ? 'block' : 'none'}
                  >
                    <DynamicPill />
                  </Box>
                )}
              </>
            )}

            {/* desktop and tablet*/}
            {showSecondaryFilters && !isMobile && (
              <>
                <SearchBar collapsed={filtersExpanded} />
                <Box display="block">
                  <Columns space="small" reverse alignY="center">
                    <Column width="content">
                      <Button
                        variant="ghost"
                        tone="neutral"
                        onClick={() => {
                          setIsOpen(true);
                          dispatch(expandSearchFilters(true));
                        }}
                        data={{ automation: 'secondaryFilterButton' }}
                        icon={<IconFilter alignY="lowercase" />}
                      >
                        {'Filters'}
                      </Button>
                    </Column>
                    <Column>{showDynamicPills && <DynamicPill />}</Column>
                  </Columns>
                </Box>
              </>
            )}

            {/* SearchBar and RefineBar without Secondary Filters and Dynamic Pills*/}
            {!showSecondaryFilters && <SearchBar collapsed={filtersExpanded} />}
            {!showSecondaryFilters && <RefineBar expanded={filtersExpanded} />}
          </Stack>
        </PageBlock>
        {showSecondaryFilters && <SecondaryFilter />}
      </Box>
      {renderRoutes(route.routes)}
    </Box>
  );
};

export default provideHooks(hooks)(Search);
