import type { Zone } from '@seek/audience-zones';
import type { SearchParams } from '@seek/chalice-types';
import type { Brand, Locale } from '@seek/melways-sites';
import checksum from '@seek/request-checksum';
import axios from 'axios';
import get from 'lodash/get';
import has from 'lodash/has';

import {
  searchUrlV4 as jobSearchUrlV4,
  personalisedSearchUrlV5 as personalisedJobSearchUrlV5,
  searchUrlV5 as jobSearchUrlV5,
  v4CountUrl,
  v4CountsUrl,
  v4RelatedSearchesUrl,
  environment,
} from 'src/config';
import type { SmarterSearchCluster } from 'src/config/types';
import getCountry from 'src/config/utils/getCountryFromZone';
import clean from 'src/modules/clean-object';
import {
  withLastKnownSolUserId,
  withDevAuth,
  withRequestId,
  withUserAgent,
  withXRealIp,
  createAuthenticatedHttpClient,
  createUnauthenticatedHttpClient,
} from 'src/modules/seek-api-request';
import { convertToApiQuery } from 'src/modules/seek-jobs-search-query';
import type { Country } from 'src/types/globals';
import type { TestHeaders } from 'src/utils/productionTesting/productionTesting';

// Matches the jobsearch api timeout. Speak to the #jobsearch
// team when changing this value.
export const SEARCH_API_TIMEOUT = 3000;

interface BaseAPIProps {
  searchParams: SearchParams;
  brand?: Brand;
  country: Country;
  zone: Zone;
  cookies: Record<string, any>;
  requestId?: string;
  timeout?: number;
  testHeaders: TestHeaders;
  locale: Locale;
}

let onlySearchPromise: any,
  onlyCountsPromise: Promise<any> | undefined,
  onlyRelatedSearchPromise: Promise<any> | undefined,
  // Use AxiosType from ca-http-client once moved to ts
  onlyRelatedSearchPromiseSource: any,
  onlySearchPromiseSource: any,
  onlyCountsPromiseSource: any;

function buildApiQuery({
  searchQuery,
  country,
  zone,
  cookies,
  userQueryId,
  seekerId,
  solId,
  locale,
}: {
  searchQuery: SearchParams;
  country: Country;
  zone: Zone;
  cookies?: Record<string, any>;
  userQueryId?: string;
  seekerId?: number;
  solId?: string;
  locale: Locale;
}) {
  const analyticsParams = !cookies
    ? {}
    : {
        userqueryid: userQueryId || null,
        userid: cookies.JobseekerVisitorId || null,
        usersessionid: cookies.JobseekerVisitorId || null,
        eventCaptureSessionId: cookies.JobseekerSessionId || null,
      };

  // TODO-ZONES: See if we can update the library so we can pass in Zone
  // instead of country value
  const searchParams = convertToApiQuery({
    country,
    searchQuery,
  });

  const clusterSearchQuery = cookies?.SMARTER_SEARCH_LAUNCH_CLUSTER
    ? { ...searchParams, cluster: cookies.SMARTER_SEARCH_LAUNCH_CLUSTER }
    : searchParams;

  const isLocationOnlySerp = Boolean(
    !searchQuery.keywords && !searchQuery.classification && searchQuery.where,
  );

  const isLocationAndWorkTypeSerp = Boolean(
    isLocationOnlySerp && searchQuery.worktype,
  );

  const requestFacets = isLocationOnlySerp || isLocationAndWorkTypeSerp;

  return clean({
    siteKey: `${getCountry(zone)}-Main`,
    sourcesystem: 'houston',
    facets: requestFacets ? 'title' : '',
    ...analyticsParams,
    ...clusterSearchQuery,
    locale,
    seekerId,
    solId,
  });
}

const buildApiHeaders = (requestId?: string) => ({
  ...withRequestId(requestId),
});

function getUrl({
  isV5Search,
  isAuthenticated,
}: {
  isV5Search?: boolean;
  isAuthenticated?: boolean;
}) {
  if (isV5Search) {
    if (isAuthenticated) {
      return personalisedJobSearchUrlV5;
    }
    return jobSearchUrlV5;
  }

  return jobSearchUrlV4;
}
function search({
  searchParams,
  brand,
  country,
  zone,
  cookies,
  userQueryId,
  requestId,
  timeout = SEARCH_API_TIMEOUT,
  userAgent,
  seekerId,
  solId,
  testHeaders,
  locale,
  xRealIp,
  isAuthenticated,
  isV5Search,
}: BaseAPIProps & {
  userQueryId: string;
  userAgent: string;
  seekerId?: number;
  solId?: string;
  xRealIp?: string;
  isAuthenticated: boolean;
  isV5Search?: boolean;
}) {
  if (onlySearchPromise) {
    onlySearchPromiseSource.cancel();
  }
  const apiQuery = buildApiQuery({
    searchQuery: searchParams,
    country,
    zone,
    cookies,
    userQueryId,
    seekerId,
    solId,
    locale,
  });

  // For dev environments, we use an unauthenticated request and set the Authorization in the header manually (see withDevAuth)
  const useDevAuth = environment === 'development' || environment === 'dev';
  const useAuthenticatedClient =
    isV5Search && isAuthenticated && ENV.CLIENT && !useDevAuth;

  const config = {
    retryPolicy: { retries: 0 },
    defaultRequestConfig: {
      headers: {
        ...testHeaders,
        ...(isV5Search
          ? withLastKnownSolUserId(cookies['last-known-sol-user-id'])
          : {}),
        ...(isV5Search && useDevAuth ? withDevAuth(cookies.AUTH_TOKEN) : {}),
      },
    },
  };

  const httpClient = useAuthenticatedClient
    ? createAuthenticatedHttpClient(config)
    : createUnauthenticatedHttpClient(config);

  const searchPromise = httpClient
    .send({
      url: getUrl({ isV5Search, isAuthenticated }),
      timeout,
      params: apiQuery,
      label: 'search-experience-api',
      cancelToken: get(onlySearchPromiseSource, 'token'),
      headers: {
        ...buildApiHeaders(requestId),
        ...withUserAgent(userAgent),
        ...withXRealIp(xRealIp),
        'x-seek-checksum': checksum(apiQuery),
        'seek-request-brand': brand,
        'seek-request-country': country,
      },
    })
    .then((response: Record<string, any>) => {
      const result = get(response, 'data');

      if (has(result, 'data')) {
        return result;
      }

      throw new Error(`invalid api response: ${JSON.stringify(result)}`);
    });

  if (ENV.CLIENT) {
    onlySearchPromise = searchPromise;
    onlySearchPromiseSource = axios.CancelToken.source();
  }

  return searchPromise;
}

function count({
  searchParams,
  country,
  zone,
  cookies,
  requestId,
  timeout = SEARCH_API_TIMEOUT,
  testHeaders,
  locale,
}: BaseAPIProps) {
  if (onlyCountsPromise) {
    onlyCountsPromiseSource.cancel();
  }

  const countsPromise = Promise.resolve(
    createUnauthenticatedHttpClient({
      omitXSeekSiteHeader: true,
      defaultRequestConfig: {
        headers: testHeaders,
      },
    }).send({
      url: v4CountUrl,
      timeout,
      cancelToken: get(onlySearchPromiseSource, 'token'),
      label: 'search-counts',
      params: buildApiQuery({
        searchQuery: searchParams,
        zone,
        cookies,
        country,
        locale,
      }),
      headers: buildApiHeaders(requestId),
    }),
  ).then((response) => response.data);

  if (ENV.CLIENT) {
    onlyCountsPromise = countsPromise;
    onlyCountsPromiseSource = axios.CancelToken.source();
  }

  return countsPromise;
}

function counts({
  searchParams,
  country,
  zone,
  cookies,
  requestId,
  timeout = SEARCH_API_TIMEOUT,
  testHeaders,
  locale,
}: BaseAPIProps) {
  if (onlyCountsPromise) {
    onlyCountsPromiseSource.cancel();
  }

  const countsPromise = Promise.resolve(
    createUnauthenticatedHttpClient({
      omitXSeekSiteHeader: true,
      defaultRequestConfig: {
        headers: testHeaders,
      },
    }).send({
      url: v4CountsUrl,
      timeout,
      cancelToken: get(onlySearchPromiseSource, 'token'),
      label: 'search-counts',
      params: buildApiQuery({
        searchQuery: searchParams,
        zone,
        cookies,
        country,
        locale,
      }),
      headers: buildApiHeaders(requestId),
    }),
  ).then((response) => response.data);

  if (ENV.CLIENT) {
    onlyCountsPromise = countsPromise;
    onlyCountsPromiseSource = axios.CancelToken.source();
  }

  return countsPromise;
}

function relatedSearches({
  zone,
  keywords,
  where,
  requestId,
  timeout,
  testHeaders,
  countryCode,
  cluster,
  visitorId,
}: {
  countryCode: string;
  zone: string;
  keywords: string;
  where?: string;
  requestId?: string;
  timeout: number;
  testHeaders: TestHeaders;
  cluster: SmarterSearchCluster;
  visitorId: string;
}): Promise<any> {
  if (onlyRelatedSearchPromise) {
    onlyRelatedSearchPromiseSource.cancel();
  }

  const relatedSearchPromise = Promise.resolve(
    createUnauthenticatedHttpClient({
      omitXSeekSiteHeader: true,
      defaultRequestConfig: {
        headers: testHeaders,
      },
    }).send({
      url: v4RelatedSearchesUrl,
      timeout,
      cancelToken: get(onlySearchPromiseSource, 'token'),
      label: 'related-search',
      params: {
        zone,
        keywords,
        siteKey: countryCode,
        cluster,
        where,
        solId: visitorId,
      },
      headers: withRequestId(requestId),
    }),
  ).then(({ data }) => data);

  if (ENV.CLIENT) {
    onlyRelatedSearchPromise = relatedSearchPromise;
    onlyRelatedSearchPromiseSource = axios.CancelToken.source();
  }

  return relatedSearchPromise;
}

export default {
  search,
  counts,
  count,
  relatedSearches,
};
