import { useTranslations } from '@vocab/react';
import { Button, IconFilter } from 'braid-design-system';
import { useCallback } from 'react';

import { useAnalyticsFacade } from 'src/modules/AnalyticsFacade';
import { useDispatch } from 'src/store/react';
import { expandSearchFilters } from 'src/store/search/search';

import translations from './.vocab';

const MoreOptions = () => {
  const { t } = useTranslations(translations);
  const dispatch = useDispatch();
  const analyticsFacade = useAnalyticsFacade();

  const handleClick = useCallback(() => {
    dispatch(expandSearchFilters(true));
    analyticsFacade.moreOptionsClicked();
  }, [analyticsFacade, dispatch]);

  return (
    <Button
      variant="transparent"
      tone="neutral"
      size="small"
      bleed
      onClick={handleClick}
      data={{ automation: 'moreOptionsButton' }}
      icon={<IconFilter alignY="lowercase" />}
      iconPosition="trailing"
    >
      {t('More options')}
    </Button>
  );
};

export default MoreOptions;
