import { parseUserAgent, detectOS } from 'detect-browser';
import includes from 'lodash/includes';

import { isFacebookApp } from './facebook';
import { isGooglebot } from './googlebot';

const supportedBrowsers = [
  'android',
  'chrome',
  'edge',
  'firefox',
  'ie',
  'ios',
  'safari',
];

export const browserName = (ua: string) => {
  if (isGooglebot(ua)) {
    return 'googlebot';
  }

  if (isFacebookApp(ua)) {
    return 'facebook-app';
  }

  const browser = parseUserAgent(ua);
  if (browser && includes(supportedBrowsers, browser.name)) {
    return browser.name;
  }
  const browserOs = detectOS(navigator.userAgent);
  return browserOs ? browserOs : 'other';
};
