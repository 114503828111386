/* eslint-disable no-console */
import type { RaygunV2 } from 'raygun4js';

import { environment, version, raygunApiKey } from 'src/config';

import type { RayGunError } from '.';

const platform = 'client';
const tags = [`environment:${environment}`, `platform:${platform}`];

const { rg4js } = window;

const logErrorToRaygunClient: RaygunV2 = rg4js;

let logErrorToRaygun = console.error;

if (logErrorToRaygunClient) {
  logErrorToRaygunClient('apiKey', raygunApiKey);
  logErrorToRaygunClient('enableCrashReporting', true);
  logErrorToRaygunClient('options', {
    ignoreAjaxAbort: true,
    ignore3rdPartyErrors: true,
  });
  logErrorToRaygunClient('whitelistCrossOriginDomains', ['seekcdn.com']);
  logErrorToRaygunClient('setVersion', version);
  logErrorToRaygunClient('withTags', tags);

  logErrorToRaygun = ({ error, customData }: RayGunError) =>
    logErrorToRaygunClient('send', { error, customData });
} else {
  console.error('Raygun not loaded');
}

export default logErrorToRaygun;
