import type {
  ApolloQueryResult,
  ApolloClient,
  NormalizedCacheObject,
} from '@apollo/client';
import { produce } from 'immer';

import type { AnalyticsFacade } from 'src/modules/AnalyticsFacade';
import { GET_CANDIDATE_DETAILS } from 'src/modules/graphql/queries/candidate';
import type {
  GetCandidateDetails,
  GetCandidateDetails_viewer_personalDetails,
  GetCandidateDetails_viewer_identity,
} from 'src/modules/graphql/queries/types/GetCandidateDetails';
import { parseCookies } from 'src/modules/safe-cookies';
import { isAuthenticated } from 'src/modules/seek-jobs-api-client/apis/candidate';
import type { EventCaptureTestTags } from 'src/utils/eventCapture/eventCaptureUtils';
import type { TestHeaders } from 'src/utils/productionTesting/productionTesting';

import type createStore from '../createStore';
import type { ChaliceStore, TypedAction, TypedThunkAction } from '../reducer';
import type { Meta } from '../types';

const UPDATE_AUTHENTICATED = 'UPDATE_AUTHENTICATED';
export const GET_ACCOUNT_SUCCESS = 'GET_ACCOUNT_SUCCESS';
export const UPDATE_SESSION = 'UPDATE_SESSION';
export const UPDATE_TEST_HEADERS = 'UPDATE_TEST_HEADERS';
export const UPDATE_TEST_TAGS = 'UPDATE_TEST_TAGS';

const GET_ACCOUNT_ERROR = 'GET_ACCOUNT_ERROR';

export const initialState = {
  authenticated: undefined,
  firstName: '',
  emailAddress: '',
  userClientId: '',
  testHeaders: {},
};

interface UserAccount {
  emailAddress: string;
  personalDetails: GetCandidateDetails_viewer_personalDetails | undefined;
  trackingId: string | undefined;
  id: number | undefined;
  identity: GetCandidateDetails_viewer_identity | undefined;
}

export interface UserState {
  authenticated?: boolean;
  firstName: string;
  emailAddress: string;
  userClientId: string;
  seekerId?: number;
  sessionId?: string;
  trackingId?: string;
  actorId?: string;
  testHeaders: TestHeaders;
  testTags?: EventCaptureTestTags;
}

interface GetAccountSuccessAction {
  type: typeof GET_ACCOUNT_SUCCESS;
  payload: UserAccount;
  meta: Meta;
}

interface UpdateAuthenticatedAction {
  type: typeof UPDATE_AUTHENTICATED;
  payload: {
    authenticated: boolean;
  };
  meta?: Meta;
}

interface UpdateTestHeadersAction {
  type: typeof UPDATE_TEST_HEADERS;
  payload: {
    testHeaders: TestHeaders;
  };
}

interface UpdateTestTagsAction {
  type: typeof UPDATE_TEST_TAGS;
  payload: {
    testTags?: EventCaptureTestTags;
  };
}

export type Action =
  | UpdateAuthenticatedAction
  | {
      type: typeof UPDATE_SESSION;
      payload: { userClientId: string; sessionId: string };
    }
  | GetAccountSuccessAction
  | {
      type: typeof GET_ACCOUNT_ERROR;
      error: true;
      payload: Error;
    }
  | UpdateTestHeadersAction
  | UpdateTestTagsAction;

export default function reducer(
  state: UserState = initialState,
  action: TypedAction,
): UserState {
  switch (action.type) {
    case UPDATE_AUTHENTICATED:
      const { authenticated } = action.payload;

      return {
        ...state,
        authenticated,
      };

    case GET_ACCOUNT_SUCCESS:
      const {
        emailAddress,
        personalDetails,
        trackingId,
        id: seekerId,
        identity,
      } = action.payload;
      const firstName = personalDetails?.firstName ?? '';
      const { actor } = identity || {};
      const { id: actorId } = actor || {};
      return {
        ...state,
        firstName,
        emailAddress,
        trackingId,
        seekerId,
        actorId,
      };

    case GET_ACCOUNT_ERROR:
      return {
        ...state,
        firstName: '',
        emailAddress: '',
        trackingId: '',
        seekerId: undefined, // eslint-disable-line no-undefined
        actorId: undefined, // eslint-disable-line no-undefined
      };

    case UPDATE_SESSION:
      const { userClientId, sessionId } = action.payload;

      return {
        ...state,
        userClientId,
        sessionId,
      };

    case UPDATE_TEST_HEADERS:
      const { testHeaders } = action.payload;

      return produce(state, (draft) => {
        draft.testHeaders = testHeaders;
      });

    case UPDATE_TEST_TAGS:
      const { testTags } = action.payload;

      return produce(state, (draft) => {
        draft.testTags = testTags;
      });

    default:
      return state;
  }
}

export const updateAuthenticated = ({
  authenticated,
}: {
  authenticated: boolean;
}): TypedAction => ({
  type: UPDATE_AUTHENTICATED,
  payload: { authenticated },
});

export const updateSession = ({
  userClientId,
  sessionId,
}: {
  userClientId: string;
  sessionId: string;
}): TypedAction => ({
  type: UPDATE_SESSION,
  payload: {
    userClientId,
    sessionId,
  },
});

export const updateTestHeaders = (testHeaders: TestHeaders): TypedAction => ({
  type: UPDATE_TEST_HEADERS,
  payload: {
    testHeaders,
  },
});

export const updateEventCaptureTestTags = (
  testTags?: EventCaptureTestTags,
): TypedAction => ({
  type: UPDATE_TEST_TAGS,
  payload: {
    testTags,
  },
});

export const getAuthenticatedStatus = async () => isAuthenticated();

export const getCandidateAccount =
  (
    apolloClient: ApolloClient<NormalizedCacheObject>,
    analyticsFacade: AnalyticsFacade,
  ): TypedThunkAction =>
  (dispatch) =>
    apolloClient
      .query<GetCandidateDetails>({
        query: GET_CANDIDATE_DETAILS,
      })
      .then((response: ApolloQueryResult<GetCandidateDetails>) => {
        if (response === null) {
          throw new Error('null graphql response');
        }
        const { viewer } = response.data;
        const account: UserAccount = {
          emailAddress: viewer?.emailAddress,
          personalDetails: viewer?.personalDetails,
          trackingId: viewer?.trackingId,
          id: viewer?.id,
          identity: viewer?.identity,
        };

        const action: TypedAction = {
          type: GET_ACCOUNT_SUCCESS,
          payload: account,
          meta: {},
        };

        dispatch(action);
      })
      .catch((error: Error) => {
        const payload = { authenticated: false };
        const action: TypedAction = {
          type: GET_ACCOUNT_ERROR,
          error: true,
          payload: error,
        };
        dispatch(action);
        dispatch(updateAuthenticated(payload));
        analyticsFacade.userDetailsUpdated(payload);

        throw error;
      });

/**
 * Resolves either the login id or undefined if not logged in
 * For sync checking, use selectLoginId(state)
 * @param store Redux store
 */
export function getLoginIdFromStore(
  store: ReturnType<typeof createStore>,
): Promise<string | undefined> {
  const state = store.getState();
  // optimistic logging for pending state is set to true
  return state.user.authenticated === true
    ? new Promise((resolve) => {
        // User is authenticated but no id exists. Subscribe for changes and wait for either not authenticated or id exists
        const unsubscribe = store.subscribe(() => {
          const newState = store.getState();
          if (newState.user.authenticated === false) {
            // User is not authenticated
            resolve(undefined);
            unsubscribe();
          } else if (newState.user.seekerId) {
            // Id found
            resolve(newState.user.seekerId.toString());
            unsubscribe();
          }
        });
      })
    : Promise.resolve(undefined);
}

export const selectAuthenticated = (state: ChaliceStore) =>
  state.user.authenticated === true;
export const selectIsAuthenticationResolved = (state: ChaliceStore) =>
  state.user.authenticated !== undefined;
export const selectEmailAddress = (state: ChaliceStore) =>
  state.user.emailAddress;
export const selectLoginId = (state: ChaliceStore) =>
  state.user.seekerId?.toString();
export const selectFirstName = (state: ChaliceStore) => state.user.firstName;
export const selectSeekerId = (state: ChaliceStore) => state.user.seekerId;
export const selectSessionId = (state: ChaliceStore) =>
  state.user?.sessionId || parseCookies()?.JobseekerSessionId;
export const selectUserClientId = (state: ChaliceStore) =>
  state.user.userClientId;
export const selectUserTestHeaders = (state: ChaliceStore) =>
  state.user.testHeaders;
export const selectEventCaptureTestTags = (state: ChaliceStore) =>
  state.user.testTags;
