import {
  Box,
  Heading,
  Stack,
  Text,
  Tiles,
  useResponsiveValue,
} from 'braid-design-system';
import { useMemo, useState } from 'react';

import { TextLink } from 'src/components/NavLink/NavLink';
import { getClassifications } from 'src/modules/refine-job-search';
import refineClassifications from 'src/modules/refine-job-search/refinements/classificationGraph';
import { useSelector } from 'src/store/react';
import { selectIsFiltersExpanded } from 'src/store/selectors';

import { useSecondaryFilterContext } from '../../context/SecondaryFilterContext';

import { ChecklistItem } from './RefineChecklist/RefineChecklist';

export const Classification = () => {
  const {
    filterSelection: filterSelectionSearchParams,
    activeDropdownField,
    isOpen,
    setActiveDropdownField,
    zone,
    language,
  } = useSecondaryFilterContext();
  const currentCounts = useSelector((state) => state.search.refinements.counts);
  const cachedClassifications = getClassifications(zone, language);

  const { classification: classifications } = useMemo(
    () =>
      refineClassifications(
        cachedClassifications,
        filterSelectionSearchParams,
        currentCounts,
      ),
    [cachedClassifications, filterSelectionSearchParams, currentCounts],
  );
  const options = classifications.filter(() => true);
  const [onSeeAllClick, setOnSeeAllClick] = useState(false);
  const filtersExpanded = useSelector(selectIsFiltersExpanded);
  const isMobile =
    useResponsiveValue()({
      mobile: true,
      tablet: false,
    }) || false;

  const initialClassificationLengthList = isMobile ? 4 : 6;

  return (
    <>
      <Box
        component="label"
        position="fixed"
        inset={0}
        onClick={() => setActiveDropdownField(null)}
        display={activeDropdownField ? 'block' : 'none'}
      />
      <Stack space="medium">
        <Heading level="4">{'Classification'}</Heading>
        <Tiles columns={[1, 2]} space="medium">
          {options
            .slice(
              0,
              !isOpen || onSeeAllClick
                ? options.length
                : initialClassificationLengthList,
            )
            .map((option, i) => (
              <ChecklistItem
                key={i}
                option={option}
                path={filtersExpanded ? '/jobs' : '/'}
              />
            ))}
        </Tiles>
        <Text>
          <TextLink
            location=""
            onClick={() => setOnSeeAllClick(!onSeeAllClick)}
            disabledLinkNavigate
          >
            {onSeeAllClick ? `See less` : `See all`}
          </TextLink>
        </Text>
      </Stack>
    </>
  );
};
