import type { Country } from '@seek/melways-sites';
import { Heading, HiddenVisually } from 'braid-design-system';
import { useSelector } from 'react-redux';

import { useAppConfig } from 'src/config/appConfig';
import { useSERPHasExternalJobs } from 'src/modules/hooks/useSERPHasExternalJobs';
import { useSharedHead } from 'src/modules/seo-header/shared/head';
import { selectJobDetailsIsExpired } from 'src/store/jobdetails/jobdetails';
import {
  selectCanonicalUrl,
  selectIsZeroResult,
  selectPageTitle,
  selectPathname,
  selectQuery,
} from 'src/store/selectors';

import { SafetyHelmet } from '../SafetyHelmet/SafetyHelmet';

const SharedHead = () => {
  const canonicalUrl = useSelector(selectCanonicalUrl);
  const pageTitle = useSelector(selectPageTitle);
  const pathname = useSelector(selectPathname);
  const { country } = useAppConfig();

  const query = useSelector(selectQuery);
  const isZeroResults = useSelector(selectIsZeroResult);
  const isExpired = useSelector(selectJobDetailsIsExpired);

  const hasExternalJobs = useSERPHasExternalJobs();

  const { meta, link, script, title } = useSharedHead({
    pageTitle: pageTitle!,
    canonicalUrl,
    query,
    isZeroResults,
    pathname,
    hasExternalJobs,
    country: country.toLowerCase() as Country,
    isExpired,
  });

  return (
    <>
      <HiddenVisually>
        <Heading level="1">{title}</Heading>
      </HiddenVisually>
      <SafetyHelmet meta={meta} link={link} script={script} title={title} />
    </>
  );
};
export default SharedHead;
