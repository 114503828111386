import querystring from 'query-string';

import type {
  GetRecommendations_viewer_recommendations as Recommendations,
  GetRecommendations_viewer_recommendations_recommendedGlobalJobs_globalJobs as RecommendedGlobalJobs,
  GetRecommendations_viewer_recommendations_recommendedGlobalJobs_globalJobs_job as RecommendedGlobalJob,
} from 'src/modules/graphql/queries/types/GetRecommendations';

import { jobDetailsMock } from './mock/jobDetails/jobDetails';

const dummyJobs = new Array(10).fill(1);

export const SOL_TOKEN = '1~5c5623c0-3996-4dc8-82a0-fe8b1b450963';

const jobIds = ['29663948', '29752529', '29677777', '29800266'];

export const baseGlobalJob: RecommendedGlobalJob = {
  __typename: 'Job',
  id: '123456',
  title: 'Senior UX Designer',
  advertiser: {
    __typename: 'Advertiser',
    name: 'State Revenue Office',
  },
};

const mapToGlobalRecJobs = (): RecommendedGlobalJobs[] =>
  dummyJobs.map((_, index) => {
    const id = jobIds[index % 4];
    const { title } = jobDetailsMock[id];
    return {
      __typename: 'RecommendedGlobalJob',
      isNew: index === 0,
      solMetadata: {
        jobId: id,
        tags: {
          'recs:type': 'recommendedGlobalJobs',

          'recs:recSysChannel': '',
          testRecord: 'false',
          'recs:experimentGroup': '183',
          'recs:flight': '183',
          'recs:product': 'home',
        },
        'recs:recSysChannel': '',
        testRecord: 'false',
        token: SOL_TOKEN,
      } as unknown as JSON,
      job: {
        ...baseGlobalJob,
        id,
        title,
      },
    };
  });

export const recommendations: Recommendations = {
  __typename: 'Recommendations',
  recommendedGlobalJobs: {
    __typename: 'RecommendedGlobalJobs',
    globalJobs: mapToGlobalRecJobs(),
    solMetadata: {
      token: SOL_TOKEN,
      tags: {
        'recs:type': 'recommendedJobs',
      },
    } as unknown as JSON,
    totalCount: dummyJobs.length,
  },
};

const defaultCandidateResolver = {
  __typename: 'Candidate',
  personalDetails: {
    firstName: 'Joe',
  },
  emailAddress: 'joe@gmail.com',
  id: 39675318,
  trackingId: '2cef3f488f07f1e946cd6a6bdcc62bf9',
  recommendations,
  userCookie: {
    key: 'last-known-sol-user-id',
    value: '3364edb5-9fa8-40bc-9068-2cb3c032529c',
  },
};

const defaultMockResolvers = {
  Candidate: () => defaultCandidateResolver,
};

export default () => {
  const { testData = '' } =
    typeof window !== 'undefined'
      ? querystring.parseUrl(window.location.href).query
      : {};

  if (testData === 'apacSavedSearchesError') {
    return {
      ...defaultMockResolvers,
      Candidate: () => ({
        ...defaultCandidateResolver,
        apacSavedSearches: new Error('ERROR_WITH_APAC_SAVED_SEARCHES_RESOLVER'),
      }),
    };
  }

  if (testData === 'newCandidateNoProfileSetup') {
    return {
      ...defaultMockResolvers,
      Candidate: () => ({
        ...defaultCandidateResolver,
        apacSavedSearches: [],
      }),
    };
  }

  return defaultMockResolvers;
};
