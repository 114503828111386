import type { SearchParams, SortMode } from '@seek/chalice-types';

import type { ResultsState } from 'src/store/results/results';
import type { Option } from 'src/types/globals';

const removeNulls = (array: Array<Option | null>) =>
  array.filter((x) => x !== null) as Option[];

const anythingIsUndefined = (...values: Array<string | boolean>) =>
  values.some((val) => typeof val === 'undefined');

export interface SortModeParams {
  searchQuery: SearchParams;
  sortMode: ResultsState['sortMode'];
}
export default ({ searchQuery, sortMode }: SortModeParams): Option[] => {
  if (!searchQuery || !Array.isArray(sortMode)) {
    return [];
  }

  const viewModel = removeNulls(
    sortMode.map(({ name, value, isActive }) => {
      if (anythingIsUndefined(name, value, isActive)) {
        return null;
      }

      const option: Option = {
        label: name,
        value,
        isActive,
        ...(isActive
          ? {}
          : {
              refineParams: {
                ...searchQuery,
                sortmode: value as SortMode,
              },
            }),
      };
      return option;
    }),
  );

  return viewModel;
};
