// Redux's combineReducers function does not allow us to combine at the top level
// of the state tree. It also complains when the state tree does not match the given
// structure from the outset.
// This function allows reducers to manage components of a
// state tree based on a merge of the given input structure which includes the
// possibility of managing state at the top level.
// ie. composeReducers(reducer1, { results: reducer2, locations: reducer3 });
//   will return a reducer that manages a state that looks like this:
//   { results: ..., locations: ... }
// reducer1() will be given and return the store state at the top level
// reducer2() will only work the sub node of { results: }
//
// One thing still todo here is that specialCombineReducers should be ammended to accept
// arbitrarily deep structures and still manage to combine the reducers.
function specialCombineReducers(reducersObject) {
  const keys = Object.keys(reducersObject);
  return (state = {}, action) =>
    keys.reduce((currState, key) => {
      const reducer = reducersObject[key];
      return {
        ...currState,
        [key]: reducer(currState[key], action),
      };
    }, state);
}

export const composeReducers = (...reducers) => {
  const flatReducers = reducers.map((currReducer) => {
    if (typeof currReducer !== 'function') {
      return specialCombineReducers(currReducer);
    }
    return currReducer;
  });

  return (state, action) => {
    return flatReducers.reduce((currState, currReducer) => {
      return currReducer(currState, action);
    }, state);
  };
};
