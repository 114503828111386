import { type Country, type Language, getHostName } from '@seek/melways-sites';
import { generateMetaRobots } from '@seek/seek-jobs-seo';
import { useTranslations } from '@vocab/react';
import includes from 'lodash/includes';
import isEmpty from 'lodash/isEmpty';

import type { SafetyHelmetProps } from 'src/components/SafetyHelmet/SafetyHelmet';
import { useAppConfig } from 'src/config/appConfig';
import { useSelector } from 'src/store/react';
import { selectAlternateLinks, selectHostname } from 'src/store/selectors';
import {
  isConsolidatedDomain,
  useLocalisedLinks,
} from 'src/utils/melwaysHelper';

import { generateMetaDescription, generateMetaTitle } from '../utils';

import translations from './.vocab';
import { useDescriptionContent } from './description/content';

const useSchema = () => {
  const { site } = useAppConfig();
  // Temporarily relying on store hostname to determine if we should use subDomainRegion
  // Should be removed once all domains are consolidated
  const alternative = isConsolidatedDomain(useSelector(selectHostname))
    ? 'subDomainRegion'
    : undefined;

  const hostname = getHostName({
    site,
    staging: false,
    alternative,
  });

  const url = `https://${hostname}`;

  return {
    '@context': 'http://schema.org',
    '@graph': [
      {
        '@type': 'WebSite',
        url,
        potentialAction: {
          '@type': 'SearchAction',
          target: `${url}/{search_term_string}-jobs`,
          'query-input': 'required name=search_term_string',
        },
      },
    ],
  };
};

interface SharedHeadInput {
  canonicalUrl: string | null;
  query: any;
  pageTitle: string;
  isZeroResults: boolean;
  pathname: string;
  hasExternalJobs: boolean;
  country: Country;
  isExpired: boolean;
}

/* Provides meta, script & link tags to be used as a base for pages on seek.
 * Note that these tags are over-written by `useHomePageHead`, `useJobDetailsHead` & `useSearchResultsHead`
 * @param {SharedHeadInput}
 */
export const useSharedHead = (
  sharedMetaInput: SharedHeadInput,
): SafetyHelmetProps => {
  const {
    canonicalUrl,
    query = {},
    pageTitle,
    isZeroResults,
    pathname = '/',
    hasExternalJobs = false,
    country,
    isExpired,
  } = sharedMetaInput || {};

  const metaRobotsContent = generateMetaRobots({
    path: pathname,
    query,
    noResults: isZeroResults,
    hasExternalJobs,
    country,
    isExpired,
  });

  const descriptionContent = useDescriptionContent();
  const { t } = useTranslations(translations);

  const title = t('shared-title');
  const schema = useSchema();

  const tags: SafetyHelmetProps = {
    title: pageTitle || title,
    meta: [],
    link: [],
    script: [],
  };

  const localisedCanonicalUrl = useLocalisedLinks({
    path: canonicalUrl || '',
    absolute: true,
  });

  tags.meta = [
    { name: 'robots', content: metaRobotsContent },
    ...generateMetaTitle(title),
    ...(canonicalUrl
      ? [{ property: 'og:url', content: localisedCanonicalUrl }]
      : []),
    ...generateMetaDescription(descriptionContent),
  ].filter((item) => !isEmpty(item));

  tags.link =
    canonicalUrl && !includes(metaRobotsContent, 'noindex')
      ? [{ rel: 'canonical', href: localisedCanonicalUrl }]
      : [];

  const alternateLinks = useLocalisedHrefLangLinks();

  if (alternateLinks.length > 0) {
    tags.link = tags.link.concat(alternateLinks);
  }

  tags.script = [
    {
      type: 'application/ld+json',
      innerHTML: JSON.stringify(schema),
    },
  ];

  return tags;
};

interface HreflangLink {
  rel: 'alternate';
  href: string;
  hreflang: Language | 'x-default';
}
const useLocalisedHrefLangLinks = () => {
  const alternateLinks = useSelector(selectAlternateLinks);

  const links = Object.entries(alternateLinks ?? []);

  const options = links.map(([language, link]) => ({
    path: link,
    language: (language as Language) ?? 'en',
    absolute: true,
  }));
  const qualifiedLinks = useLocalisedLinks(options);

  return links.reduce<HreflangLink[]>((finalLinks, [lang], index) => {
    const language = lang as Language;
    const qualifiedLink = qualifiedLinks[index];

    const newAlternateLink: HreflangLink = {
      href: qualifiedLink,
      rel: 'alternate',
      hreflang: language,
    };
    const newAlternateLinks = [newAlternateLink];

    if (language === 'en') {
      newAlternateLinks.push({
        ...newAlternateLink,
        hreflang: 'x-default',
      });
    }

    return finalLinks.concat(newAlternateLinks);
  }, []);
};
