import { combineReducers, type Dispatch } from 'redux';
// @ts-expect-error: non-ts file
import { reducer as createHotjarReducers } from 'redux-hotjar';
import type { ThunkAction } from 'redux-thunk';

import {
  reducer as experiments,
  type ExperimentsState,
  type UpdateExperimentsAction,
} from 'src/store/experiments/experiments';
import type { NudgesState } from 'src/types/globals';

import appConfig, {
  type AppConfigAction,
  type AppConfigState,
} from './appConfig/appConfig';
import banner, { type BannerAction, type BannerState } from './banner/banner';
import featureFlags, {
  type Action as FeatureFlagsAction,
  type FeatureFlagsState,
} from './featureFlags/featureFlags';
import jobdetails, {
  type Action as JobDetailsAction,
  type JobDetailsState,
} from './jobdetails/jobdetails';
import lmis, { type Action as LmisAction, type LmisState } from './lmis/lmis';
import location, {
  type Action as LocationAction,
  type LocationState,
} from './location/location';
import nudges, { type Action as NudgesAction } from './nudges/nudges';
import recentSearches, {
  type Action as RecentSearchesAction,
  type RecentSearchesState,
} from './recentSearches/recentSearches';
import results, {
  type Action as ResultsAction,
  type ResultsState,
} from './results/results';
import saveSearch, {
  type Action as SaveSearchAction,
  type SaveSearchState,
} from './saveSearch/saveSearch';
import search, {
  type Action as SearchAction,
  type reducer as searchReducer,
  type SearchState,
} from './search/search';
import seo, { type Action as SeoAction, type SeoState } from './seo/seo';
import ui, {
  type PageLoadedAction,
  type Action as UiAction,
  type UiState,
} from './ui/ui';
import user, { type Action as UserAction, type UserState } from './user/user';

interface HotJarState {}
type HotJarReducer = (
  state: HotJarState,
  action: Record<string, unknown>,
) => HotJarState;
const hotjarReducers: Record<string, HotJarReducer> = createHotjarReducers();

const searchWithTypeInfo = search as () => ReturnType<typeof searchReducer>;

export interface ChaliceStore {
  appConfig: AppConfigState;
  featureFlags: FeatureFlagsState;
  jobdetails: JobDetailsState;
  recentSearches: RecentSearchesState;
  lmis: LmisState;
  location: LocationState;
  nudges: NudgesState;
  results: ResultsState;
  saveSearch: SaveSearchState;
  search: SearchState;
  seo: SeoState;
  ui: UiState;
  user: UserState;
  experiments: ExperimentsState;
  banner: BannerState;
}

export default combineReducers({
  appConfig,
  experiments,
  featureFlags,
  jobdetails,
  recentSearches,
  lmis,
  location,
  nudges,
  results,
  saveSearch,
  search: searchWithTypeInfo,
  seo,
  user,
  ui,
  banner,
  ...hotjarReducers,
});

export type TypedGetState = () => ChaliceStore;
export type TypedAction =
  | AppConfigAction
  | FeatureFlagsAction
  | JobDetailsAction
  | RecentSearchesAction
  | LmisAction
  | LocationAction
  | NudgesAction
  | ResultsAction
  | SaveSearchAction
  | SearchAction
  | SeoAction
  | UiAction
  | UserAction
  | UpdateExperimentsAction
  | PageLoadedAction
  | BannerAction;

export type TypedDispatch = Dispatch<TypedAction>;
export type TypedThunkAction<T = void> = ThunkAction<
  T,
  ChaliceStore,
  null,
  TypedAction
>;
