import type { UrlLocation } from '@seek/chalice-types';
import { metrics } from '@seek/metrics-js';
import {
  enrichLocation,
  isJobDetailsLocation,
  isSearchResultsPath,
} from '@seek/seek-jobs-seo';
import {
  createBrowserHistory,
  createMemoryHistory,
  type History,
} from 'history';
import { stringify as buildQueryString } from 'query-string';
import type { MouseEvent } from 'react';

import { logger } from 'src/modules/logger';
import { shouldOpenLinkExternally } from 'src/modules/navlink-rules';
import { timeoutPromise, tryPromise } from 'src/modules/promise';
import { getQualifiedLocation } from 'src/modules/qualified-location';
import runHooks from 'src/modules/redux-hooks';

import type { ChaliceStore, TypedAction, TypedThunkAction } from '../reducer';
import type { Meta } from '../types';

export const LOCATION_CHANGED = 'LOCATION_CHANGED';
export const CAMPAIGN_LINK_CLICKED = 'CAMPAIGN_LINK_CLICKED';
export const SET_PAGE_TITLE = 'SET_PAGE_TITLE';
export const SET_REQUEST_ID = 'SET_REQUEST_ID';

export interface LocationState {
  url: string | null;
  pathname: string;
  prevPathname: string | null;
  query: any;
  pageNumber?: number;
  isHomepage: boolean;
  pageTitle?: string;
  hostname?: string;
  href?: string;
  hash?: string;
  port?: string;
  protocol?: string;
  requestId?: string;
}

export const initialState: LocationState = {
  url: null,
  prevPathname: null,
  query: null,
  pageNumber: undefined,
  isHomepage: true,
  pageTitle: '',
  pathname: '',
  hostname: '',
  href: '',
  port: '',
  protocol: '',
  requestId: undefined,
};

export const history: History<UrlLocation['state']> = ENV.CLIENT
  ? createBrowserHistory()
  : createMemoryHistory();

interface LocationChangedAction {
  type: typeof LOCATION_CHANGED;
  payload: {
    url: string;
    location: UrlLocation;
  };
  meta: Meta;
}

export interface SetRequestIdAction {
  type: typeof SET_REQUEST_ID;
  payload: {
    requestId?: string;
  };
}

export type Action =
  | LocationChangedAction
  | SetRequestIdAction
  | {
      type: typeof SET_PAGE_TITLE;
      payload: {
        pageTitle: string;
      };
    }
  | {
      type: typeof CAMPAIGN_LINK_CLICKED;
      payload: {
        linkName: string;
        trackingCode: string;
      };
      meta: Meta;
    };
export default function reducer(
  state: LocationState = initialState,
  action: TypedAction,
): LocationState {
  switch (action.type) {
    case LOCATION_CHANGED: {
      const { url, location } = action.payload;
      const { pathname, query, hash } = location;
      const { hostname, href, port, protocol, pathname: prevPathname } = state;

      const enrichedLocation = enrichLocation(
        {
          hostname,
          href,
          port,
          protocol,
          pathname,
          query,
          hash,
        },
        url,
      );

      return {
        ...state,
        url,
        prevPathname,
        pageNumber: parseInt(query?.page || '1', 10),
        isHomepage: pathname === '/',
        ...enrichedLocation,
      };
    }

    case SET_PAGE_TITLE: {
      const { pageTitle } = action.payload;
      return {
        ...state,
        pageTitle,
      };
    }

    case SET_REQUEST_ID: {
      const { requestId } = action.payload;
      return {
        ...state,
        requestId,
      };
    }

    default:
      return state;
  }
}

export function setRequestId(requestId?: string): SetRequestIdAction {
  return {
    type: SET_REQUEST_ID,
    payload: {
      requestId,
    },
  };
}

export function setPageTitle(pageTitle: string): TypedAction {
  return {
    type: SET_PAGE_TITLE,
    payload: {
      pageTitle,
    },
  };
}

export function locationChanged(
  url: string,
  location: UrlLocation,
): TypedAction {
  return {
    type: LOCATION_CHANGED,
    payload: {
      url,
      location,
    },
    meta: {
      metrics: {
        name: LOCATION_CHANGED,
      },
    },
  };
}

export function goBack() {
  return history.goBack();
}

export function campaignLinkClicked({
  linkName,
  trackingCode,
}: {
  linkName: string;
  trackingCode: string;
}): TypedAction {
  return {
    type: CAMPAIGN_LINK_CLICKED,
    payload: {
      linkName,
      trackingCode,
    },
    meta: {
      hotjar: 'Campaign Link Clicked',
      metrics: {
        name: CAMPAIGN_LINK_CLICKED,
      },
    },
  };
}

const NavType = {
  NEW_WINDOW: 'NEW_WINDOW', // link opened in new window
  OUTSIDE_APP: 'OUTSIDE_APP', // link external to SPA (same window)
  INSIDE_APP: 'INSIDE_APP', // link internal to SPA
};

export const pushNavigate = ({ location }: { location: any }) => {
  try {
    /**
     * Double encodes the dangling percent characters in the pathname.
     *
     * We want to preserve the literal '%25' in the URL as displayed
     * in the browser's address bar, we need to counteract this automatic decoding. We do this by
     * double-encoding the '%25' sequence itself. Here's how it works:
     * - First, we split the pathname on '%25'. This separates the string into parts around each occurrence of '%25'.
     * - We then join these parts with '%2525'. The '%25' within '%2525' is the encoded version of '%',
     *   so '%2525' is effectively the double-encoded version of '%25'.
     * - As a result, when the browser processes this double-encoded sequence ('%2525'), it decodes it once to '%25',
     *   thus preserving the appearance of the original '%25' sequence in the URL.
     *
     * This technique is crucial for URLs containing literal '%25' sequences that we need to display as-is in the URL bar,
     * considering the browser's automatic decoding behavior.
     */
    const doubleEncodePathname = (pathname: string) =>
      pathname.split('%25').join('%2525');

    /* Fix me: This is a temporary fix to prevent the history.push from being called before the UI updates 
    as this negatively impacts the INP score of the page. */
    const PERFORMANCE_IMPROVEMENT_DELAY = 10;

    const pushToHistory = () => {
      const updatedLocation = {
        ...location,
        pathname: doubleEncodePathname(location.pathname),
        search: location.search || buildQueryString(location.query),
      };

      history.push(updatedLocation);
    };

    setTimeout(pushToHistory, PERFORMANCE_IMPROVEMENT_DELAY);
  } catch (error) {
    logger.error({ err: error }, 'Error calling history.push');
  }
};

const doNavigate = (
  location: UrlLocation,
  target: string | undefined,
  navType: string,
) => {
  if (ENV.CLIENT) {
    if (navType === NavType.INSIDE_APP) {
      pushNavigate({ location });
    } else {
      window.open(location.href, target);
    }
  }
  return Promise.resolve();
};

const MAX_DELAY = 1000;
const ANALYTICS_DELAY = 300;

export function linkNavigate({
  event,
  target,
  location: nextLocation,
  preNavigationHooks,
}: {
  event: MouseEvent;
  target?: string;
  location: any;
  preNavigationHooks?: any[];
}): TypedThunkAction {
  return (dispatch, getState) => {
    const {
      location: currentLocation,
      appConfig: { site },
    } = getState();
    const location = getQualifiedLocation(currentLocation, nextLocation, site);
    const isExternalLink = shouldOpenLinkExternally(
      location,
      currentLocation,
      site,
    );

    // upon rejection, hold errors for throwing later
    let firstError: any = null;
    const holdFirstError = (err: any) => {
      logger.error({ err });
      firstError = firstError || (err instanceof Error ? err : null);
    };

    const navType =
      (target === '_blank' && NavType.NEW_WINDOW) ||
      (isExternalLink && NavType.OUTSIDE_APP) ||
      NavType.INSIDE_APP;

    const navTasks = [];

    // if new window navigate as early as possible & without delay to prevent
    // browser/ad-blockers preventing navigate (particularly firefox)
    if (navType === NavType.NEW_WINDOW) {
      doNavigate(location, target, navType);
    }

    // pre-navigation hooks
    if (preNavigationHooks) {
      navTasks.push(() =>
        runHooks(dispatch, preNavigationHooks).catch(holdFirstError),
      );
    }

    // internal (SPA) navigation
    if (navType === NavType.INSIDE_APP) {
      event.preventDefault();
      navTasks.push(() => doNavigate(location, target, navType));
    }

    // external (outside SPA) navigation - delay navigation to ensure analytics time to send
    if (navType === NavType.OUTSIDE_APP) {
      navTasks.push(() =>
        timeoutPromise(
          tryPromise(() => Promise.resolve()),
          MAX_DELAY,
        )
          .then(
            (result) =>
              new Promise((resolve) => {
                setTimeout(() => resolve(result), ANALYTICS_DELAY);
              }),
          )

          .catch((e) => {
            if (e.isTimedOut) {
              // max delay time has passed, continue to navigation
              metrics.count('navigate-outside-app-analytics-delay.timeout', [
                'timeout:true',
              ]);
            }
            return e;
          })
          .catch(holdFirstError)
          .then(() => doNavigate(location, target, navType)),
      );
    }

    const allTasks = navTasks.reduce(
      (promiseChain, task): Promise<any> => promiseChain.then(task),
      Promise.resolve(),
    );

    // run nav tasks in sequence
    return allTasks.catch(holdFirstError).then(() => {
      // this `then` ensures we do not return the last reduced value which makes testing more predictable
      if (firstError !== null) {
        logger.error({ err: firstError }, 'firstError');
      }
    });
  };
}

export const selectLocationPageNumber = (state: ChaliceStore) =>
  state.location.pageNumber || 0;
export const selectIsSrp = (state: ChaliceStore) =>
  isSearchResultsPath(state.location.pathname);
export const selectIsJDP = (state: ChaliceStore) =>
  isJobDetailsLocation(state.location);
export const selectIsJDPSplitView = (state: ChaliceStore) =>
  isSearchResultsPath(state.location.pathname) &&
  Boolean(state.jobdetails.result);
export const selectPageTitle = (state: ChaliceStore) =>
  state.location.pageTitle;
export const selectPathname = (state: ChaliceStore) => state.location.pathname;
export const selectPrevPathname = (state: ChaliceStore) =>
  state.location.prevPathname;
export const selectProtocol = (state: ChaliceStore) => state.location.protocol;
export const selectHostname = (state: ChaliceStore) => state.location.hostname;
export const selectQuery = (state: ChaliceStore) => state.location.query;
export const selectHash = (state: ChaliceStore) => state.location.hash;
export const selectUrl = (state: ChaliceStore) => state.location.url;
export const selectLocation = (state: ChaliceStore) => state.location;
export const selectPageNumber = (state: ChaliceStore) =>
  state.location.pageNumber;
export const selectIsHomePage = (state: ChaliceStore) =>
  state.location.isHomepage;
