import { initialState as appConfig } from './appConfig/appConfig';
import { initialState as featureFlags } from './featureFlags/featureFlags';
import { initialState as jobdetails } from './jobdetails/jobdetails';
import { initialState as lmis } from './lmis/lmis';
import { initialState as location } from './location/location';
import { initialState as nudges } from './nudges/nudges';
import { initialState as recentSearches } from './recentSearches/recentSearches';
import { initialState as saveSearch } from './saveSearch/saveSearch';
import { initialState as search } from './search/search';
import { initialState as seo } from './seo/seo';
import { initialState as user } from './user/user';

export default Object.freeze({
  appConfig,
  featureFlags,
  jobdetails,
  recentSearches,
  lmis,
  location,
  nudges,
  saveSearch,
  search,
  seo,
  user,
});
