import type { SearchParams } from '@seek/chalice-types';
import omit from 'lodash/omit';
import omitBy from 'lodash/omitBy';

import type { Country } from 'src/types/globals';

const isFalsy = (x: any) => !x;
const clean = (object: Record<PropertyKey, any>) => omitBy(object, isFalsy);

// Asia markets shouldn't have a default where value.
const defaultWhereValue: Partial<Record<Country, string>> = {
  AU: 'All Australia',
  NZ: 'All New Zealand',
};

const shouldUseDefaultWhereValue = (country: Country) =>
  Object.keys(defaultWhereValue).includes(country);

const getDefaultWhereValue = (country: Country) =>
  shouldUseDefaultWhereValue(country) ? defaultWhereValue[country] : '';

const hasLegacyLocations = ({
  nation,
  state,
  location,
  area,
  areaid,
}: Record<PropertyKey, any>) =>
  typeof nation !== 'undefined' ||
  typeof state !== 'undefined' ||
  typeof location !== 'undefined' ||
  typeof area !== 'undefined' ||
  typeof areaid !== 'undefined';

const alwaysIncludeSeoData = (searchParams: SearchParams): SearchParams => {
  const include = searchParams.include
    ? `${searchParams.include}seodata`
    : 'seodata';

  return { ...searchParams, include };
};

const atCompany = (searchParams: SearchParams): SearchParams => {
  if (!searchParams.companysearch) {
    return searchParams;
  }
  const { keywords } = searchParams;

  return {
    ...omit(searchParams, ['keywords', 'companysearch']),
    companyname: keywords,
  };
};

export default ({
  country,
  searchQuery,
}: {
  country: Country;
  searchQuery: SearchParams;
}): SearchParams => {
  /*
  Setting seekSelectAllPages to true means always return promoted jobs for every page.
  Historically it's been added to the search API on purpose not to show
  promoted adds for subsequent "pages" on mobile apps because they have an infinite scroll.
  It's been defaulted to false to support old apps so they dont start showing them.
  Means for chalice it should be explicitly set to true, because we don't have any
  logic around that or infinite scroll.
  If we do infinite scroll one day, we might want to remove it
  */
  const defaultParams = {
    where: !hasLegacyLocations(searchQuery)
      ? getDefaultWhereValue(country)
      : '',
    page: '1',
    seekSelectAllPages: true,
  };

  const expandedQuery = { ...defaultParams, ...searchQuery };

  const { advertiserid, advertisergroup, companyid, ...standardParams } =
    expandedQuery;

  const mappedParams = {
    hirerid: advertiserid,
    hirergroup: advertisergroup,
    companyprofilestructureddataid: companyid,
  };

  return clean(
    alwaysIncludeSeoData(
      atCompany({
        ...standardParams,
        ...mappedParams,
      }),
    ),
  );
};
