type AnyArg = (...arg: any[]) => void;

interface Logger {
  trace: AnyArg;
  debug: AnyArg;
  info: AnyArg;
  warn: AnyArg;
  error: AnyArg;
}

const noop: AnyArg = () => {};

export let logger: Logger = {
  trace: noop,
  debug: noop,
  info: noop,
  warn: noop,
  error: noop,
};

export const setupLogger = (logProvider: Logger) => {
  logger = logProvider;
};
