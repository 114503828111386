import type { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import type { Zone } from '@seek/audience-zones';
import { metrics } from '@seek/metrics-js';
import get from 'lodash/get';

import type { ChaliceStore, TypedThunkAction } from 'src/store/reducer';
import type { NudgesState } from 'src/types/globals';

import type {
  CandidateSalaryNudge,
  GetSalaryNudgeQuery,
  GetSalaryNudgeQueryVariables,
} from '../../graphql/graphql.ts';
import { GET_SALARY_NUDGE } from '../../modules/graphql/queries/salaryNudge.ts';
import { logger } from '../../modules/logger';

export const nudgeMetricsPrefix = 'nudges';

export const GET_NUDGES = 'GET_NUDGES';
export const DISMISS_NUDGE = 'DISMISS_NUDGE';
export const ADD_NUDGE_FEEDBACK_SUCCESS = 'ADD_NUDGE_FEEDBACK_SUCCESS';
export const ADD_NUDGE_FEEDBACK_ERROR = 'POST_NUDGE_ANSWER_ERROR';
export const CLEAR_NUDGE_ERROR = 'CLEAR_NUDGE_ERROR';

interface NoPayloadAction {
  type:
    | typeof DISMISS_NUDGE
    | typeof ADD_NUDGE_FEEDBACK_SUCCESS
    | typeof ADD_NUDGE_FEEDBACK_ERROR
    | typeof CLEAR_NUDGE_ERROR;
}

export const initialState = {
  nudges: [],
  currentNudgeIndex: 0,
  nudgeFeedbackError: false,
  nudgesCompleted: false,
};

export type Action =
  | {
      type: typeof GET_NUDGES;
      payload: {
        nudgeId?: string;
        position?: number;
        seekerId?: number;
        nudges: CandidateSalaryNudge[];
      };
    }
  | NoPayloadAction;

export default function reducer(
  state: NudgesState = initialState,
  action: Action,
): NudgesState {
  switch (action.type) {
    case GET_NUDGES: {
      const nudges = get(action, 'payload.nudges');

      return {
        ...state,
        nudges,
      };
    }

    case ADD_NUDGE_FEEDBACK_SUCCESS: {
      const nextNudgeIndex = state.currentNudgeIndex + 1;
      if (nextNudgeIndex < state.nudges.length) {
        return {
          ...state,
          currentNudgeIndex: nextNudgeIndex,
        };
      }

      // Set nudgesCompleted to true if we reach the end of nudges
      return {
        ...state,
        nudgesCompleted: true,
      };
    }

    case ADD_NUDGE_FEEDBACK_ERROR: {
      return {
        ...state,
        nudgeFeedbackError: true,
      };
    }

    case DISMISS_NUDGE: {
      return {
        ...state,
        nudges: [],
      };
    }

    case CLEAR_NUDGE_ERROR: {
      return {
        ...state,
        nudgeFeedbackError: false,
      };
    }

    default: {
      return state;
    }
  }
}

export const fetchSalaryNudge =
  ({
    apolloClient,
    zone,
  }: {
    apolloClient: ApolloClient<NormalizedCacheObject>;
    zone: Zone;
  }): TypedThunkAction =>
  (dispatch) =>
    apolloClient
      .query<GetSalaryNudgeQuery, GetSalaryNudgeQueryVariables>({
        query: GET_SALARY_NUDGE,
        variables: {
          zone,
        },
        fetchPolicy: 'network-only',
        errorPolicy: 'all',
      })
      .then(({ data, errors }) => {
        const salaryNudge = data?.viewer?.salaryNudge;

        metrics.count(nudgeMetricsPrefix, [
          `morethanzero:${salaryNudge ? 'true' : 'false'}`,
        ]);

        if (salaryNudge) {
          dispatch({
            type: GET_NUDGES,
            payload: {
              nudges: [salaryNudge],
            },
          });
        } else if (!salaryNudge && errors) {
          logger.error({ errors, zone }, 'SalaryNudge GraphQL Error');
        } else if (errors) {
          logger.error({ errors, zone }, 'SalaryNudge GraphQL Partial Failure');
        }
      })
      .catch((error: Error) => {
        metrics.count(nudgeMetricsPrefix, ['fetch:error']);
        logger.error({ error, zone }, 'SalaryNudge GraphQL Error');
      });

export const dismissNudge = (): NoPayloadAction => ({ type: DISMISS_NUDGE });

export const selectNudges = (state: ChaliceStore) => state.nudges.nudges;

export const canShowNudges = (state: ChaliceStore) =>
  state.nudges.currentNudgeIndex === 0 ||
  state.nudges.currentNudgeIndex <= state.nudges.nudges.length;
