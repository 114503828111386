import type {
  ApolloClient,
  ApolloQueryResult,
  NormalizedCacheObject,
} from '@apollo/client';
import type { Zone } from '@seek/audience-zones';
import type {
  JobDetails as _JobDetails,
  LearningInsights,
  Maybe,
  JobProfileSalaryMatch,
  JobProfileSalaryNoMatch,
  JobProfileMissingSalaryPreference,
} from '@seek/ca-graphql-schema/types';
import type { Language, Locale } from '@seek/melways-sites';

import { getTimezone } from 'src/hooks/useTimezone';
import {
  GET_JOB_DETAILS,
  GET_JOB_DETAILS_PERSONALISED,
} from 'src/modules/graphql/queries/jobDetails';
import type {
  GetJobDetails,
  GetJobDetailsVariables,
} from 'src/modules/graphql/queries/types/GetJobDetails';
import { logger } from 'src/modules/logger';
import { logErrorToRaygun } from 'src/modules/raygun-logger';
import { setPageTitle } from 'src/store/location/location';
import {
  JobDetailsGraphQLError,
  PageNotFoundError,
} from 'src/utils/customErrors';

import type { AnalyticsFacade } from '../../modules/AnalyticsFacade';
import {
  GET_LMIS_ERROR,
  GET_LMIS_SUCCESS,
  pageKeys,
  RESET_JDP_LMIS,
} from '../lmis/lmis';
import type { ChaliceStore, TypedAction, TypedThunkAction } from '../reducer';
import { SUBMIT_SEARCH } from '../search/search';

export const JOB_DETAILS_FETCH_BEGIN = 'JOB_DETAILS_FETCH_BEGIN';
export const JOB_DETAILS_FETCH_SUCCESS = 'JOB_DETAILS_FETCH_SUCCESS';
export const JOB_DETAILS_FETCH_FAILURE = 'JOB_DETAILS_FETCH_FAILURE';

export const JOB_DETAILS_PERSONALISED_FETCH_SUCCESS =
  'JOB_DETAILS_PERSONALISED_FETCH_SUCCESS';
export const RESET_JOB_DETAILS = 'RESET_JOB_DETAILS';
export const JOB_DETAILS_PAGE_LOADED = 'JOB_DETAILS_PAGE_LOADED';

export const JOB_FRAUD_REPORT_SUCCESS = 'JOB_FRAUD_REPORT_SUCCESS';
export const JOB_FRAUD_REPORT_FAILURE = 'JOB_FRAUD_REPORT_FAILURE';

export const CIS_INTERACTION_EVENT = 'CIS_INTERACTION_EVENT';
export const CIS_IMPRESSION = 'CIS_IMPRESSION';
export const CLEAR_JOB_DETAILS = 'CLEAR_JOB_DETAILS';

declare interface Analytics$CISImpression {
  matches: string[];
}

// Todo: Fix JobDetails types
export type JobDetails =
  | _JobDetails & {
      learningInsights:
        | (LearningInsights & { analytics: Record<string, string> })
        | null;
      personalised?: PersonalisedJobDetails;
    };

// Todo: Fix JobDetails types
export type PersonalisedJobDetails = {
  isSaved: boolean | null;
  appliedDateTime: {
    shortAbsoluteLabel: string;
  } | null;
  topApplicantBadge: {
    label: string;
    description: string;
  } | null;
  salaryMatch:
    | (JobProfileSalaryMatch & { __typename: 'JobProfileSalaryMatch' })
    | (JobProfileSalaryNoMatch & { __typename: 'JobProfileSalaryNoMatch' })
    | (JobProfileMissingSalaryPreference & {
        __typename: 'JobProfileMissingSalaryPreference';
      })
    | null;
} | null;

export interface FetchUnifiedJobArgs {
  analyticsFacade: AnalyticsFacade;
  apolloClient: ApolloClient<NormalizedCacheObject>;
  jobId: string;
  jobDetailsViewedCorrelationId: string;
  jobseekerSessionId: string;
  zone: Zone;
  locale: Locale;
  languageCode: Language;
  countryCode: string;
  shouldThrowError?: boolean;
  xRealIp: string | undefined;
}

export type FetchUnifiedJobPersonalisedArgs = Omit<
  FetchUnifiedJobArgs,
  'analyticsFacade' | 'countryCode'
>;
export interface JobDetailsState {
  error?: boolean;
  fraudReport: any;
  jobDetailsViewedCorrelationId?: string;
  jobPending: boolean;
  pageLoadedCount: number;
  personalised?: PersonalisedJobDetails;
  result: JobDetails | null;
  xRealIp: string | undefined;
}

export interface JobDetailsFetchFailureAction {
  type: typeof JOB_DETAILS_FETCH_FAILURE;
  payload: any;
}

export interface JobDetailsPageLoadedAction {
  type: typeof JOB_DETAILS_PAGE_LOADED;
}

export interface ClearJobDetailsAction {
  type: typeof CLEAR_JOB_DETAILS;
}

export interface CisImpressionAction {
  type: typeof CIS_IMPRESSION;
  payload: Analytics$CISImpression;
  meta: Record<string, unknown>;
}

export type Action =
  | JobDetailsFetchFailureAction
  | { type: typeof JOB_DETAILS_FETCH_BEGIN }
  | {
      type: typeof JOB_DETAILS_FETCH_SUCCESS;
      payload: { result: JobDetails; xRealIp: string | undefined };
    }
  | {
      type: typeof JOB_DETAILS_PERSONALISED_FETCH_SUCCESS;
      payload: {
        personalised: PersonalisedJobDetails;
        jobDetailsViewedCorrelationId: string;
        xRealIp: string | undefined;
      };
    }
  | { type: typeof RESET_JOB_DETAILS }
  | JobDetailsPageLoadedAction
  | { type: typeof JOB_FRAUD_REPORT_SUCCESS }
  | { type: typeof JOB_FRAUD_REPORT_FAILURE }
  | ClearJobDetailsAction
  | CisImpressionAction;

export const initialState = {
  fraudReport: {},
  jobPending: false,
  result: null,
  pageLoadedCount: 0,
  personalised: null,
  xRealIp: undefined,
};

export default function reducer(
  state: JobDetailsState = initialState,
  action: TypedAction,
): JobDetailsState {
  switch (action.type) {
    case SUBMIT_SEARCH: {
      return {
        ...state,
        personalised: null,
        result: null,
      };
    }

    case JOB_DETAILS_PAGE_LOADED: {
      const { pageLoadedCount } = state;
      return {
        ...state,
        pageLoadedCount: pageLoadedCount + 1,
      };
    }

    case JOB_DETAILS_FETCH_FAILURE: {
      const { error } = action.payload;
      return {
        ...state,
        jobPending: false,
        error: Boolean(error),
      };
    }

    case JOB_DETAILS_FETCH_BEGIN: {
      return {
        ...state,
        jobPending: true,
      };
    }

    case JOB_DETAILS_FETCH_SUCCESS: {
      const { result, xRealIp } = action.payload;

      return {
        ...state,
        fraudReport: {},
        jobPending: false,
        error: false,
        result,
        xRealIp,
      };
    }

    case JOB_DETAILS_PERSONALISED_FETCH_SUCCESS: {
      const { personalised, jobDetailsViewedCorrelationId, xRealIp } =
        action.payload;
      return {
        ...state,
        personalised,
        jobDetailsViewedCorrelationId,
        xRealIp,
      };
    }

    case RESET_JOB_DETAILS: {
      return {
        ...state,
        jobDetailsViewedCorrelationId: undefined,
        jobPending: false,
        personalised: null,
        result: null,
      };
    }

    case CLEAR_JOB_DETAILS: {
      return {
        ...state,
        result: null,
      };
    }

    default: {
      return state;
    }
  }
}

export const clearJobDetails = (): ClearJobDetailsAction => ({
  type: CLEAR_JOB_DETAILS,
});

export const fetchUnifiedJob =
  ({
    analyticsFacade,
    apolloClient,
    jobId,
    jobDetailsViewedCorrelationId = '',
    jobseekerSessionId = '',
    zone = 'anz-1',
    locale,
    languageCode,
    shouldThrowError = true,
    countryCode,
    xRealIp,
  }: FetchUnifiedJobArgs): TypedThunkAction =>
  (dispatch, getState) => {
    dispatch({ type: RESET_JOB_DETAILS });
    dispatch({ type: RESET_JDP_LMIS });
    dispatch({ type: JOB_DETAILS_FETCH_BEGIN });

    return apolloClient
      .query<GetJobDetails, GetJobDetailsVariables>({
        query: GET_JOB_DETAILS,
        variables: {
          jobId,
          jobDetailsViewedCorrelationId,
          sessionId: jobseekerSessionId,
          zone,
          locale,
          languageCode,
          countryCode,
          timezone: getTimezone(),
        },
        fetchPolicy: 'network-only',
        errorPolicy: 'all',
      })
      .then(({ data, errors }: ApolloQueryResult<GetJobDetails>) => {
        const jobDetails = data?.jobDetails as unknown as Maybe<JobDetails>;

        if (!jobDetails && errors) {
          logger.error(
            { errors, jobId, zone, locale },
            'JobDetails GraphQL Error',
          );
          throw new JobDetailsGraphQLError({
            message: 'Failed to load job details',
            customData: { errors },
          });
        }

        if (errors) {
          logger.error(
            { errors, jobId, zone, locale },
            'JobDetails GraphQL Partial Failure',
          );
          logErrorToRaygun({
            error: new Error('JobDetails GraphQL Partial Failure'),
            customData: { error: errors },
          });
        }

        if (!jobDetails) {
          throw new PageNotFoundError('No JobDetails found');
        }

        if (jobDetails) {
          analyticsFacade.jobDetailsFetched(jobDetails);

          dispatch({
            type: JOB_DETAILS_FETCH_SUCCESS,
            payload: {
              result: jobDetails,
              xRealIp,
              getState,
            },
            meta: {
              metrics: {
                name: JOB_DETAILS_FETCH_SUCCESS,
              },
            },
          });

          dispatch({
            type: GET_LMIS_SUCCESS,
            payload: {
              content: jobDetails?.learningInsights?.content,
              lmisSnippet: jobDetails?.learningInsights?.analytics,
              key: pageKeys.JDP,
            },
          });

          return dispatch(setPageTitle(jobDetails.job.title));
        }

        return dispatch({ type: RESET_JOB_DETAILS });
      })
      .catch((error: Error) => {
        dispatch({
          type: JOB_DETAILS_FETCH_FAILURE,
          payload: { error },
        });
        dispatch({
          type: GET_LMIS_ERROR,
          payload: {
            error,
            key: pageKeys.JDP,
          },
        });
        // Will need to `throw error` for Single Job Detail Page only
        if (shouldThrowError) {
          throw error;
        }
      });
  };

export const fetchUnifiedJobPersonalised =
  ({
    apolloClient,
    jobId,
    jobDetailsViewedCorrelationId = '',
    jobseekerSessionId = '',
    languageCode,
    locale,
    zone,
    xRealIp,
  }: FetchUnifiedJobPersonalisedArgs): TypedThunkAction =>
  (dispatch) =>
    apolloClient
      .query({
        query: GET_JOB_DETAILS_PERSONALISED,
        variables: {
          id: jobId,
          tracking: {
            channel: 'WEB',
            jobDetailsViewedCorrelationId,
            sessionId: jobseekerSessionId,
          },
          languageCode,
          locale,
          timezone: getTimezone(),
          zone,
        },
        fetchPolicy: 'network-only',
      })
      .then(({ data: { jobDetails } }) => {
        const personalised = jobDetails?.personalised;

        if (personalised) {
          dispatch({
            type: JOB_DETAILS_PERSONALISED_FETCH_SUCCESS,
            payload: {
              personalised,
              jobDetailsViewedCorrelationId,
              xRealIp,
            },
          });
        }
      })
      .catch((error: Error) => {
        logger.error(
          {
            error,
            variables: {
              id: jobId,
              tracking: {
                channel: 'WEB',
                jobDetailsViewedCorrelationId,
                sessionId: jobseekerSessionId,
              },
            },
          },
          'ChaliceJobDetailsPersonalised',
        );
      });

export const jobDetailsPageLoaded = (): JobDetailsPageLoadedAction => ({
  type: JOB_DETAILS_PAGE_LOADED,
});

export const cisImpression = (
  impression: Analytics$CISImpression,
): CisImpressionAction => ({
  type: CIS_IMPRESSION,
  payload: impression,
  meta: {
    metrics: {
      name: 'cis_impression',
      tags: { cisMatches: impression.matches },
    },
  },
});

export const selectPageLoadedCount = (state: ChaliceStore) =>
  state.jobdetails.pageLoadedCount;

export const selectJobDetailsResult = (state: ChaliceStore) =>
  state.jobdetails.result;

export const selectJobDetailsPersonalised = (state: ChaliceStore) =>
  state.jobdetails.personalised;

export const selectIsJobDetailsPending = (state: ChaliceStore) =>
  state.jobdetails.jobPending;

export const selectIsJobDetailsFetchFailure = (state: ChaliceStore) =>
  Boolean(state.jobdetails.error);

export const selectJobDetailsViewedCorrelationId = (state: ChaliceStore) =>
  state.jobdetails.jobDetailsViewedCorrelationId;

export const selectJobDetailsIsExpired = (state: ChaliceStore) =>
  Boolean(state.jobdetails.result?.job.isExpired);

export const selectXRealIp = (state: ChaliceStore) => state.jobdetails.xRealIp;
