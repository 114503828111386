import 'braid-design-system/reset';

import { datadogRum } from '@datadog/browser-rum';
import { setup as setupMetrics } from '@seek/metrics-js';
import get from 'lodash/get';
import { loadableReady } from 'sku/@loadable/component';
import smoothscroll from 'smoothscroll-polyfill';
import VError from 'verror';

import {
  appName,
  awsAccount,
  datadogRumApplicationMetadata,
  datadogRumIsEnabled,
  environment,
  metricsHost,
  metricsIsEnabled,
  metricsPrefix,
  version,
} from 'src/config';
// @ts-expect-error: non-ts file
import ariaClick from 'src/modules/aria-click';
import { browserName } from 'src/modules/chalice-user-agent/browser-name';
import { isMobileUserAgent } from 'src/modules/chalice-user-agent/device-detect';
import { setupLogger as setupLoggerJs } from 'src/modules/logger';
import loggerJs from 'src/modules/logger/client';
import { setupLogger as setupRaygunLogger } from 'src/modules/raygun-logger';
import raygunLogger from 'src/modules/raygun-logger/client';

import clientRender from './client-render';
import getCountry from './config/utils/getCountryFromZone';
import { initBrowserLog } from './modules/logger/browser-logs';
import pathnameToPageName from './pathnameToPageName';
import '@formatjs/intl-locale/polyfill';
import '@formatjs/intl-pluralrules/locale-data/en';
import '@formatjs/intl-pluralrules/polyfill';

declare global {
  interface Window {
    SEEK_APOLLO_DATA: any;
    SEEK_TEST: boolean;
  }
}

const zone = window.SEEK_APP_CONFIG.zone;

smoothscroll.polyfill();
setupRaygunLogger(raygunLogger);
setupLoggerJs(loggerJs);
ariaClick(document);
setupMetrics({
  host: metricsHost,
  isEnabled: metricsIsEnabled,
  prefix: metricsPrefix,
  globalTags: [
    `version:${version}`,
    `locale:${getCountry(zone)}`,
    `environment:${environment}`,
    `aws-account:${awsAccount}`,
    `browser-name:${browserName(window.navigator.userAgent)}`,
    'platform:client',
    `device-type:${isMobileUserAgent() ? 'mobile' : 'desktop'}`,
    'service_name:chalice',
    `houston-page:${pathnameToPageName(window.location.pathname)}`,
  ],
});

// catch promise unhandled rejections & errors
window.addEventListener('unhandledrejection', (e) => {
  e.preventDefault();
  const err = get(e, 'detail.reason') || get(e, 'detail') || e;

  if (err instanceof Error) {
    throw new VError(err, 'Unhandled rejection error');
  }
});

if (datadogRumIsEnabled) {
  datadogRum.init({
    applicationId: JSON.parse(datadogRumApplicationMetadata)?.applicationId,
    clientToken: JSON.parse(datadogRumApplicationMetadata)?.clientToken,
    site: 'datadoghq.com',
    service: appName,
    env: environment,
    version,
    sessionSampleRate: 1, // percent
    sessionReplaySampleRate: 0,
    trackResources: true,
    trackLongTasks: true,
    trackUserInteractions: true,
    defaultPrivacyLevel: 'mask-user-input',
  });
}

initBrowserLog();

loadableReady(() => {
  clientRender(document.getElementById('app'));
});
