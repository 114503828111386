import type { SearchParams } from '@seek/chalice-types';
import { normaliseSearchQuery } from '@seek/seek-jobs-seo';
import {
  Box,
  Button,
  Stack,
  Columns,
  Column,
  Inline,
} from 'braid-design-system';
import { useCallback, useEffect, useState } from 'react';

import { ButtonLink } from 'src/components/NavLink/NavLink';
import { SEODrawer } from 'src/components/SEODrawer/SEODrawer';
import { parseCookies } from 'src/modules/safe-cookies';
import createApiClient from 'src/modules/seek-jobs-api-client';
import { useSelector } from 'src/store/react';

import { useSecondaryFilterContext } from './context/SecondaryFilterContext';
import {
  Classification,
  ListingTime,
  RemoteOption,
  SalaryRange,
  WorkType,
} from './filters';

export const SecondaryFilter = () => {
  const {
    country,
    locale,
    zone,
    searchQuery,
    filterSelection,
    setFilterSelection,
    isOpen,
    setIsOpen,
  } = useSecondaryFilterContext();

  const [displayTotalCount, setDisplayTotalCount] = useState(0);
  const state = useSelector((storeState) => storeState);
  const {
    location: { requestId },
    user: { testHeaders },
  } = state;
  const api = createApiClient();

  const getJobSearchCount = useCallback(
    (searchParams: SearchParams) => {
      const cookies = parseCookies();
      const normalisedSearchParams = normaliseSearchQuery({
        path: '/jobs',
        query: searchParams,
      });

      return api.jobs
        .count({
          searchParams: normalisedSearchParams,
          zone,
          cookies,
          requestId,
          country,
          testHeaders,
          locale,
        })
        .then((result: number) => {
          setDisplayTotalCount(result);
        })
        .catch((_: Error) => {
          setDisplayTotalCount(0);
        });
    },
    [api.jobs, country, locale, requestId, testHeaders, zone],
  );

  useEffect(() => {
    if (filterSelection) {
      getJobSearchCount(filterSelection);
    }
  }, [getJobSearchCount, filterSelection]);

  return (
    <SEODrawer
      id="secondaryFilters"
      headingLevel="2"
      title="Filters"
      width="small"
      open={isOpen}
      position="right"
      onClose={() => {
        setFilterSelection(searchQuery);
        setIsOpen(false);
      }}
      footer={<SecondaryFilterFooter displayTotalCount={displayTotalCount} />}
    >
      <Stack space="gutter" dividers>
        <WorkType />
        <RemoteOption />
        <SalaryRange />
        <Classification />
        <ListingTime />
      </Stack>
    </SEODrawer>
  );
};

const SecondaryFilterFooter = ({
  displayTotalCount,
}: {
  displayTotalCount: number;
}) => {
  const { isOpenSearch, filterSelection, setFilterSelection, setIsOpen } =
    useSecondaryFilterContext();

  return (
    <Box background="surface" padding={['small', 'large']}>
      <Columns space="none" alignY="center" reverse>
        <Column width="content">
          <ButtonLink
            tone="brandAccent"
            variant="solid"
            onClick={() => {
              setIsOpen(false);
            }}
            location={{
              pathname: '/jobs',
              query: filterSelection,
            }}
          >
            {isOpenSearch || displayTotalCount === 0
              ? `SEEK`
              : `SEEK ${displayTotalCount.toLocaleString()} ${
                  displayTotalCount < 2 ? 'job' : 'jobs'
                }`}
          </ButtonLink>
        </Column>
        <Column>
          <Inline space="none" align="left">
            <Button
              tone="neutral"
              variant="transparent"
              onClick={() => setFilterSelection({})}
              bleed
            >
              Clear all
            </Button>
          </Inline>
        </Column>
      </Columns>
    </Box>
  );
};
