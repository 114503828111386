/*
  Code implementation is derived from
  https://github.com/seek-oss/braid-design-system/blob/1c5da13ae84ae03ca2b36d03e7ade82c4181fd27/packages/braid-design-system/src/lib/components/private/Modal/ModalContent.tsx.

  For reference:
  - Braid version: v32.21.0
*/

import {
  Bleed,
  Box,
  ButtonIcon,
  Column,
  Columns,
  Divider,
  Heading,
  IconClear,
  Stack,
} from 'braid-design-system';
import {
  type ComponentProps,
  type ReactNode,
  type Ref,
  type KeyboardEvent,
  forwardRef,
  useRef,
} from 'react';
import { RemoveScroll } from 'react-remove-scroll';

import { normalizeKey } from './utils/normalizeKeys';

import * as styles from './Modal.css';

export interface ModelContentProps {
  id: string;
  title: string;
  children: ReactNode;
  open: boolean;
  onClose: () => void;
  closeLabel?: string;
  width: ComponentProps<typeof Box>['maxWidth'] | 'content';
  position: 'center' | 'right' | 'left';
  headingLevel: '2' | '3';
  scrollLock?: boolean;
  headingRef?: Ref<HTMLElement>;
  modalRef?: Ref<HTMLElement>;
  footer?: ReactNode;
}

const modalPadding = { mobile: 'gutter', tablet: 'large' } as const;
const pageBlockGutters = { mobile: 'xsmall', tablet: 'gutter' } as const;

interface ModalContentHeaderProps {
  title: string;
  headingLevel: '2' | '3';
}

/*
  The following properties were not implemented
  the from original ModalContentHeader:
    - description
    - descriptionId
    - center

  Reference:
  - https://github.com/seek-oss/braid-design-system/blob/1c5da13ae84ae03ca2b36d03e7ade82c4181fd27/packages/braid-design-system/src/lib/components/private/Modal/ModalContent.tsx#L46-L81
*/
const ModalContentHeader = forwardRef<HTMLElement, ModalContentHeaderProps>(
  ({ title, headingLevel }, ref) => (
    <Stack
      space={
        headingLevel === '2' ? { mobile: 'small', tablet: 'medium' } : 'small'
      }
    >
      <Heading level={headingLevel}>
        <Box
          ref={ref}
          component="span"
          tabIndex={-1}
          outline="none"
          position="relative"
          className={styles.headingRoot}
        >
          {title}
        </Box>
      </Heading>
    </Stack>
  ),
);

export const ModelContent = ({
  width,
  modalRef: modalRefProp,
  headingLevel,
  headingRef: headingRefProp,
  title,
  children,
  position,
  onClose,
  id,
  closeLabel = 'Close',
  open,
  footer,
}: ModelContentProps) => {
  const defaultModalRef = useRef<HTMLElement>(null);
  const modalRef = modalRefProp || defaultModalRef;
  const defaultHeadingRef = useRef<HTMLElement>(null);
  const headingRef = headingRefProp || defaultHeadingRef;

  const handleEscape = (event: KeyboardEvent) => {
    const targetKey = normalizeKey(event);
    if (targetKey === 'Escape') {
      event.stopPropagation();
      onClose();
    }
  };

  return (
    <Box
      role="dialog"
      aria-label={title}
      aria-modal="true"
      position="relative"
      width="full"
      height="full"
      display="flex"
      alignItems="center"
      className={[styles.maxSize[position]]}
      justifyContent="flexEnd"
      onKeyDown={handleEscape}
    >
      <Box
        position="relative"
        display="flex"
        alignItems="center"
        justifyContent="flexEnd"
        height="full"
        boxShadow="large"
        width={width !== 'content' ? 'full' : undefined}
        maxWidth={width !== 'content' ? width : undefined}
        overflow="hidden"
      >
        <RemoveScroll ref={modalRef} enabled={open} forwardProps>
          <Box
            background="surface"
            position="relative"
            className={styles.pointerEventsAll}
          >
            <Box className={styles.fullHeight}>
              {/*
                  Illustration view removed.
                  
                  Reference:
                  - https://github.com/seek-oss/braid-design-system/blob/1c5da13ae84ae03ca2b36d03e7ade82c4181fd27/packages/braid-design-system/src/lib/components/private/Modal/ModalContent.tsx#L169-L180
                  */}
              <Box paddingX="large" className={styles.modalContentBody}>
                <Box paddingY="large">
                  <Columns space="none">
                    <Column>
                      <ModalContentHeader
                        headingLevel={headingLevel}
                        title={title}
                        ref={headingRef}
                      />
                    </Column>
                    <Column width="content">
                      <Box width="touchable" />
                    </Column>
                  </Columns>
                </Box>
                <Divider />
                <Box className={styles.paddingYLarge}>{children}</Box>
              </Box>
              {/*
                  Footer is an add-on and not part of 
                  the original implementation.
                */}
              {footer ? (
                <Box
                  width="full"
                  className={[styles.footer]}
                  background="surface"
                >
                  {footer}
                </Box>
              ) : null}
            </Box>
          </Box>
        </RemoveScroll>
        <Box
          position="absolute"
          zIndex="sticky"
          top={0}
          pointerEvents="none"
          width="full"
          display="flex"
          justifyContent="flexEnd"
          paddingTop={modalPadding}
          paddingRight={position !== 'center' ? pageBlockGutters : modalPadding}
          className={position === 'center' && styles.maxSize.right}
        >
          <Bleed space="xsmall">
            <Box
              position="relative"
              background="surface"
              borderRadius="full"
              padding="xsmall"
              className={[styles.closeIconOffset, styles.pointerEventsAll]}
            >
              <ButtonIcon
                id={`${id}-close`}
                label={closeLabel}
                icon={<IconClear tone="secondary" />}
                variant="transparent"
                size="large"
                onClick={onClose}
              />
            </Box>
          </Bleed>
        </Box>
      </Box>
    </Box>
  );
};
