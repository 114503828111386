import type { Zone } from '@seek/audience-zones';
import type {
  Brand,
  CountrySiteName,
  Language,
  Locale,
} from '@seek/melways-sites';

import type { Country } from 'src/types/globals';

export const SMARTER_SEARCH_CLUSTERS = [
  'default',
  'ph-launch',
  'end-state-launch',
] as const;

export type SmarterSearchCluster = (typeof SMARTER_SEARCH_CLUSTERS)[number];

export interface DisplayDates {
  startDate?: string;
  endDate?: string;
}

export interface ZoneFeatures {
  LMIS_ENABLED?: boolean;
  NUDGE_ENABLED?: boolean;
  SEARCH_PAGE_SIZE?: number;
  BEHAVIOURAL_CUES_ENABLED?: boolean;
  PDPA_CONSENT_REQUIRED?: boolean;
  HOMEPAGE_BANNER_TYPE?: string;
  SHOW_MARKETING_AD_SPLIT_VIEW?: boolean;
  SHOW_FLOATING_SAVE_SEARCH?: boolean;
  NORTHSTAR_SEO_TAGS?: boolean;
  SHOW_JOBCARD_TEASER_IN_SPLITVIEW?: boolean;
  AUTO_SELECT_SPLIT_VIEW_FIRST_JOB?: boolean;
  LOGGED_OUT_RECS?: boolean;
  REMOTE_SEARCH_FILTER?: boolean;
  REMOTE_SEARCH_FILTER_NEW_LABEL?: boolean;
  DYNAMIC_PILLS?: boolean;
  SECONDARY_FILTERS?: boolean;
  ENABLE_V5_JOB_SEARCH?: boolean;
  KEYWORD_AUTOSUGGEST_V2?: boolean;
  NEW_JOB_CARD_DENSITY?: boolean;
  ENABLE_VERIFIED_HIRER_BADGE?: boolean;
  SERP_JOBCARD_INFO_DENSITY_1?: boolean;
}

interface TimedBanner {
  displayDates: DisplayDates;
}

interface CookieConsentBanner extends TimedBanner {}
interface PrivacyPolicyBanner extends TimedBanner {}

interface LaunchBanner extends TimedBanner {
  oldUrl: string;
  newUrl: string;
  bannerUrl: {
    en: string;
    id?: string;
  };
}

export interface ZoneConfig {
  zone: Zone;
  country: Country;
  brand: Brand;
  availableLocales: Locale[];
  defaultLocale: Locale;
  timedBanners: {
    cookieConsent?: CookieConsentBanner;
    privacyPolicy?: PrivacyPolicyBanner;
    launchBanner?: LaunchBanner;
  };
  zoneFeatures: ZoneFeatures;
  BRANCH_IO_KEY: string;
}

export interface AppConfig extends ZoneConfig {
  locale: Locale;
  language: Language;
  site: CountrySiteName;
  consolidated?: boolean;
}

export interface SalaryRange_Frequency {
  label: string;
  type: string;
  isDefault: boolean;
}
export interface SalaryRange_Range {
  shortLabel: string;
  longLabel: string;
  value: string;
}
export interface SalaryRange_Currency {
  code: string;
}

export interface SalaryRange_SearchSalaryRange {
  frequency: SalaryRange_Frequency;
  range: SalaryRange_Range[];
}
export interface SalaryRange {
  defaultCurrency: SalaryRange_Currency;
  searchSalaryRanges: SalaryRange_SearchSalaryRange[];
}

export type AllSalaryRanges = {
  [key in Language]?: SalaryRange;
};

export interface ChildClassification {
  id: number;
  description: string;
}

export interface ParentClassification {
  id: number;
  description: string;
  subClassifications: ChildClassification[];
}

export type Classifications = ParentClassification[];

export type AllClassifications = {
  [key in Language]?: Classifications;
};

export type WorkTypes = {
  [key in Language]?: Array<{ label: string; value: string }>;
};

export type DateRanges = {
  [key in Language]?: Array<{ label: string; value: string }>;
};

export type Distances = {
  [key in Language]?: Array<{ label: string; value: string }>;
};

export type WorkArrangements = {
  [key in Locale]?: Array<{ id: string; label: string }>;
};
