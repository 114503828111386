import type { Country } from '@seek/melways-sites';
import { getMetaDescription } from '@seek/seek-jobs-seo';

import { useAppConfig } from 'src/config/appConfig';
import { useSelector } from 'src/store/react';
import {
  selectResultsCount,
  selectResultsLocation,
} from 'src/store/results/results';
import { selectPathname, selectQuery } from 'src/store/selectors';

export const useDescriptionContent = () => {
  const { country } = useAppConfig();
  const path = useSelector(selectPathname);
  const query = useSelector(selectQuery);
  const location = useSelector(selectResultsLocation);
  const totalCount = useSelector(selectResultsCount);

  return getMetaDescription({
    country: country.toLowerCase() as Country,
    path,
    query,
    location,
    totalCount: totalCount || 0,
  });
};
