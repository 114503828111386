import * as http from 'http';
import * as https from 'https';

import type { Country } from '@seek/audience-zones';
import HttpClient, {
  isTimeoutError as isHttpClientTimeoutError,
  isConnectionReset,
  isGatewayTimeout,
} from '@seek/ca-http-client';
import { metrics } from '@seek/metrics-js';
import type { AxiosError } from 'axios';
import { parse } from 'cookie';
import isEmpty from 'lodash/isEmpty';
import isError from 'lodash/isError';
import merge from 'lodash/merge';

import { environment } from 'src/config';

import { findRootCause } from './error';

const LAST_KNOWN_SOL_USER_ID_KEY = 'last-known-sol-user-id';

const httpClientMiddleware = ({
  requestConfig,
  response = {},
  duration,
}: {
  requestConfig: Record<string, any>;
  response: Record<string, any>;
  duration: number;
}) => {
  const { label } = requestConfig;
  const { config = requestConfig, status } = response;
  const { method = 'get', url } = config;

  metrics.httpRequestTiming({
    method,
    status,
    duration,
    isUpstream: true,
    endpointName: label || (url.indexOf('token') > -1 ? 'auth-token' : ''),
  });
};

export const isTimeoutError = (error: AxiosError) => {
  if (!isError(error)) {
    return false;
  }

  const rootCause = findRootCause(error);
  return isHttpClientTimeoutError(rootCause);
};

const commonHttpClientConfig = {
  appName: 'Chalice',
  metrics: httpClientMiddleware,
  retryPolicy: {
    isRetriableError: (err: AxiosError) =>
      isTimeoutError(err) || isConnectionReset(err) || isGatewayTimeout(err),
  },
  defaultRequestConfig: {
    httpAgent: new http.Agent({ keepAlive: true }),
    httpsAgent: new https.Agent({ keepAlive: true }),
  },
};

const getMockedOptions = () => {
  const useMockedAuth =
    (environment === 'development' && window.location.protocol === 'http:') ||
    environment === 'dev';

  return {
    useMockedAuth,
    mockOptions:
      useMockedAuth && window
        ? {
            authenticated: parse(document.cookie).AUTH_TOKEN !== undefined,
          }
        : undefined,
  };
};

export type HttpClientConfig = Partial<
  ConstructorParameters<typeof HttpClient>[0]
>;

export const createAuthenticatedHttpClient = (
  config: HttpClientConfig = {},
) => {
  // support mocked and unmocked auth scenarios locally
  const { useMockedAuth, mockOptions } = getMockedOptions();

  return new HttpClient({
    authOptions: {
      useMockedAuth,
      ...(useMockedAuth ? { mockOptions } : {}),
      forceStrategy: 'AUTH0',
    },
    ...merge({}, commonHttpClientConfig, config),
  });
};

export const createUnauthenticatedHttpClient = (
  config: HttpClientConfig = {},
) => new HttpClient(merge({}, commonHttpClientConfig, config));

export const withRequestId = (id?: string) => {
  if (!id) {
    return {};
  }

  return { 'X-Request-Id': id };
};

export const withUserAgent = (userAgent?: string) => {
  if (ENV.CLIENT || isEmpty(userAgent)) {
    return {};
  }

  return { 'X-Seek-Forwarded-User-Agent': userAgent };
};

export const withXRealIp = (xRealIp?: string) => {
  if (ENV.CLIENT || isEmpty(xRealIp)) {
    return {};
  }

  return { 'X-Real-IP': xRealIp };
};

export const withLastKnownSolUserId = (lastKnownSolUserId?: string) => {
  if (!ENV.SERVER || isEmpty(lastKnownSolUserId)) {
    return {};
  }

  return { Cookie: `${LAST_KNOWN_SOL_USER_ID_KEY}=${lastKnownSolUserId}` };
};

export const withDevAuth = (authToken?: string) => {
  const useDevAuth = environment === 'development' || environment === 'dev';
  if (authToken && useDevAuth) {
    return { Authorization: authToken };
  }

  return {};
};

/**
 * TODO: We need to determine what domains all regions will use post-unification.
 */
// TODO-ZONES: auUrl will be the only URL for future usage for all zones
export const localiseUrl = (country: Country = 'AU', auUrl: string) => {
  switch (country) {
    case 'NZ':
      return auUrl.replace('.com.au', '.co.nz');
    case 'SG':
    case 'AU':
    default:
      return auUrl;
  }
};
