import gql from 'graphql-tag';

export const SEARCH_SAVED_AND_APPLIED_JOBS = gql`
  query SearchSavedAndAppliedJobs($jobIds: [String!]!) {
    viewer {
      searchSavedJobs(jobIds: $jobIds) {
        jobId
      }
      searchAppliedJobs(jobIds: $jobIds) {
        jobId
      }
    }
  }
`;

export const GET_SAVED_JOBS = gql`
  query GetSavedJobs($locale: Locale) {
    viewer {
      id
      savedJobs(first: 3) {
        total
        edges {
          node {
            id
            isActive
            job {
              id
              title
              advertiser {
                name(locale: $locale)
              }
            }
          }
        }
      }
    }
  }
`;
