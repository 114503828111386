import { isProduction } from '..';
import type { AppConfig } from '../types';

export default {
  zone: 'asia-1',
  defaultLocale: 'en-HK',
  availableLocales: ['en-HK'],
  timedBanners: {},
  zoneFeatures: {
    NORTHSTAR_SEO_TAGS: true,
    NUDGE_ENABLED: false,
    HOMEPAGE_BANNER_TYPE: 'GENERIC_ASIA_BANNER',
    SEARCH_PAGE_SIZE: 30,
    SHOW_FLOATING_SAVE_SEARCH: false,
    SHOW_MARKETING_AD_SPLIT_VIEW: false,
    AUTO_SELECT_SPLIT_VIEW_FIRST_JOB: false,
    LMIS_ENABLED: true,
    BEHAVIOURAL_CUES_ENABLED: false,
    LOGGED_OUT_RECS: false,
    REMOTE_SEARCH_FILTER: true,
    REMOTE_SEARCH_FILTER_NEW_LABEL: true,
    DYNAMIC_PILLS: false,
    SECONDARY_FILTERS: false,
    ENABLE_V5_JOB_SEARCH: false,
    KEYWORD_AUTOSUGGEST_V2: true,
    NEW_JOB_CARD_DENSITY: false,
    ENABLE_VERIFIED_HIRER_BADGE: false,
    SERP_JOBCARD_INFO_DENSITY_1: false,
  },
  BRANCH_IO_KEY: isProduction
    ? 'key_live_dgOh8Yali0X6D5LPpX679mjkuFnVPBnB'
    : 'key_test_baKe5Wbdl33XD3LQi6260idbvzfPPAfV',
} as AppConfig;
