import { metrics } from '@seek/metrics-js';
import type { Analytics } from '@seek/seek-jobs-analytics';
// @ts-expect-error: non-ts file
import instrumentMethods from 'instrument-methods';

function logAnalytics(methodName: keyof Analytics, args: unknown[]) {
  if (typeof window !== 'undefined' && window.ANALYTICS_DEBUG) {
    // eslint-disable-next-line no-console
    console.log(`analytics.${methodName}()`, ...args);
  }
  metrics.count('analytics', [`analytics-name:${methodName}`]);
}

export function instrumentAnalytics(analytics: Analytics): void {
  instrumentMethods(analytics, { after: logAnalytics });
}
