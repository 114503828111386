import { useSelector } from 'react-redux';

import { useAppConfig } from 'src/config/appConfig';
import { selectPathname } from 'src/store/selectors';

import { getTitleTestConfig } from './config';
import type { ReplaceableKeyValues, TestConfig, TitleType } from './type';

interface TitleTemplateAndPath {
  testTitleTemplate: string;
  paths: string[];
}

export const getTestTitleFullText = (
  replaceableKeyValues: ReplaceableKeyValues,
  testTitleTemplate: string,
) => {
  let testTitleText = testTitleTemplate;

  Object.entries(replaceableKeyValues).map(
    ([replaceableKey, replaceableValue]) => {
      const regex = `\{${replaceableKey}\}`;
      if (testTitleText.match(regex)) {
        testTitleText = testTitleText.replace(regex, replaceableValue);
      }
    },
  );

  return testTitleText;
};

export const getTitleTemplateAndPaths = ({
  testConfig,
  pageLocale,
}: {
  testConfig?: TestConfig;
  pageLocale: string;
}): TitleTemplateAndPath[] | undefined =>
  testConfig?.testVariants
    .filter((variant) => variant.locale === pageLocale)
    .map((testVariantList) => {
      switch (testVariantList.version) {
        case '1': {
          return {
            testTitleTemplate: testVariantList.testTitle,
            paths: testVariantList.paths,
          };
        }
        case '2': {
          const { paths, paths2, pathsActiveDate, pathsActiveDate2 } =
            testVariantList;
          const todayDate = new Date();
          const activeDate = new Date(pathsActiveDate);
          const activeDate2 = new Date(pathsActiveDate2);

          if (todayDate < activeDate2 && todayDate >= activeDate) {
            // Group A active period
            return {
              testTitleTemplate: testVariantList.testTitle,
              paths,
            };
          } else if (todayDate >= activeDate2) {
            // Group B active period
            return {
              testTitleTemplate: testVariantList.testTitle,
              paths: paths2,
            };
          }
        }
        default: {
          // fallback for unknown version and inactive version 2 config test
          return { testTitleTemplate: '', paths: [] };
        }
      }
    });

export const titleOverride = ({
  replaceableKeyValues,
  titleType,
  pageLocale,
  pagePathName,
}: {
  replaceableKeyValues: ReplaceableKeyValues;
  titleType: TitleType;
  pageLocale: string;
  pagePathName: string;
}) => {
  const titleTestConfig = getTitleTestConfig() || [];
  const testConfig = titleTestConfig.find(
    (config) => config.titleType === titleType,
  );

  const titleTemplateAndPathsList = getTitleTemplateAndPaths({
    testConfig,
    pageLocale,
  });

  if (!titleTemplateAndPathsList) {
    return undefined;
  }

  let titleFromTemplate;

  titleTemplateAndPathsList.some(({ testTitleTemplate, paths }) => {
    if (paths && testTitleTemplate) {
      const matched = paths.some(
        (path) =>
          encodeURI(pagePathName).toLocaleLowerCase() ===
          path.toLocaleLowerCase(),
      );

      if (matched) {
        titleFromTemplate = getTestTitleFullText(
          replaceableKeyValues,
          testTitleTemplate,
        );
        return true;
      }
    }
    return false;
  });

  return titleFromTemplate;
};

export const useTitleOverride = ({
  replaceableKeyValues,
  titleType,
}: {
  replaceableKeyValues: ReplaceableKeyValues;
  titleType: TitleType;
}) => {
  const { locale } = useAppConfig();
  const pagePathName = useSelector(selectPathname);

  return titleOverride({
    replaceableKeyValues,
    titleType,
    pageLocale: locale,
    pagePathName,
  });
};
