import type { SearchSavedAndAppliedJobs } from 'src/modules/graphql/queries/types';

interface JobStatusFlags {
  jobId: string;
  savedAndAppliedJobs: SearchSavedAndAppliedJobs;
}

export const getJobStatusFlags = ({
  jobId,
  savedAndAppliedJobs,
}: JobStatusFlags) => ({
  isApplied:
    savedAndAppliedJobs?.viewer?.searchAppliedJobs
      .map((appliedJob) => appliedJob.jobId)
      .includes(jobId) || false,
  isSaved:
    savedAndAppliedJobs?.viewer?.searchSavedJobs
      .map((savedJob) => savedJob.jobId)
      .includes(jobId) || false,
});
