import { metrics } from '@seek/metrics-js';

// @ts-expect-error: non-ts file
import impressionTrackingLogger from 'src/modules/impression-tracking-logger';

import { browserLogError, browserLogWarn } from './browser-logs';

const logger = {
  /* eslint-disable no-console */
  trace() {},
  debug() {},
  info() {},
  warn(...args: any[]) {
    console.warn(...args);
    metrics.count('clientwarnlogged', []);
    impressionTrackingLogger.warn(...args);
    browserLogWarn(new Error(args.join(' ')), { kind: 'clientError' });
  },
  error(...args: any[]) {
    console.error(...args);
    // Metric to get a scale on how many errors would be logged client-side.
    // Intention is if manageable, we call an API to log these server side.
    metrics.count('clienterrorlogged', []);
    impressionTrackingLogger.error(...args);
    browserLogError(new Error(args.join(' ')), { kind: 'clientError' });
  },
  /* eslint-enable no-console*/
};

export default logger;
